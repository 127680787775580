/* eslint-disable no-console */
import Web3 from 'web3';
import store from 'store/store';
import {
  CONTRACT_CAI_TOKEN_ABI,
  CONTRACT_CAI_CONTROLLER_ABI,
  CONTRACT_CAI_VAULT_ABI,
  CONTRACT_VAULT_ABI,
  CONTRACT_CHEE_ABI,
  CONTRACT_ERC20_TOKEN_ABI,
  CONTRACT_CTOKEN_ABI,
  CONTRACT_CBASE_ABI,
  CONTRACT_COMPTROLLER_ABI,
  CONTRACT_PRICE_ORACLE_ABI,
  CONTRACT_INTEREST_MODEL_ABI,
  CONTRACT_CHEE_LENS_ABI,
  CONTRACT_FAUCET_ABI,
  CONTRACT_LEND_ABI,
  VEPOOLS_ADDRESS_LEND,
  CONTRACT_VETOKEN_ABI,
  VEPOOLS_ADDRESS_VETOKEN,
  VEPOOLS_ADDRESS_VENFT,
  CONTRACT_VENFT_ABI,
  VEPOOLS_ADDRESS_CHEETOKEN,
  CONTRACT_CHEEIZI_ABI,
} from 'constants/address';

export const web3Instance = { value: null };
// singleton instance
export const getWeb3 = () => {
  return web3Instance.value;
};

const call = (method, params) => {
  try {
    return method(...params).call();
  } catch (err) {
    console.log(err);
    return Promise.reject();
  }
};

const send = (method, params, from) => {
  return new Promise((resolve, reject) => {
    method(...params)
      .send({ from })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getCaiTokenContract = (CONTRACT_CAI_TOKEN_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_CAI_TOKEN_ABI), CONTRACT_CAI_TOKEN_ADDRESS);
};

export const getCaiControllerContract = (CONTRACT_CAI_UNITROLLER_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_CAI_CONTROLLER_ABI), CONTRACT_CAI_UNITROLLER_ADDRESS);
};

export const getCaiVaultContract = (CONTRACT_CAI_VAULT_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_CAI_VAULT_ABI), CONTRACT_CAI_VAULT_ADDRESS);
};

export const getVaultContract = (CONTRACT_VAULT_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_VAULT_ABI), CONTRACT_VAULT_ADDRESS);
};

export const getTokenContract = (name, CONTRACT_TOKEN_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(
    JSON.parse(name === 'chee' ? CONTRACT_CHEE_ABI : CONTRACT_ERC20_TOKEN_ABI),
    CONTRACT_TOKEN_ADDRESS[name].address
  );
};

export const getCTokenContract = (name, CONTRACT_CTOKEN_ADDRESS) => {
  const instance = getWeb3();
  const contract = new instance.eth.Contract(
    JSON.parse(
      name !== 'celo' || name !== 'bnb' || name !== 'mtr' || name !== 'matic' || name !== 'eth'
        ? CONTRACT_CTOKEN_ABI
        : CONTRACT_CBASE_ABI
    ),
    CONTRACT_CTOKEN_ADDRESS[name].address
  );
  return contract;
};

export const getComptrollerContract = (CONTRACT_COMPTROLLER_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_COMPTROLLER_ABI), CONTRACT_COMPTROLLER_ADDRESS);
};

export const getPriceOracleContract = (CONTRACT_PRICE_ORACLE_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_PRICE_ORACLE_ABI), CONTRACT_PRICE_ORACLE_ADDRESS);
};

export const getInterestModelContract = (address) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_INTEREST_MODEL_ABI), address);
};

export const getCheeLensContract = (CONTRACT_CHEE_LENS_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_CHEE_LENS_ABI), CONTRACT_CHEE_LENS_ADDRESS);
};

export const getFaucetContract = (CONTRACT_FAUCET_ADDRESS) => {
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_FAUCET_ABI), CONTRACT_FAUCET_ADDRESS);
};

// vepools
export const getLendContract = (CONTRACT_LEND_ADDRESS_ARG, networkName) => {
  const CONTRACT_LEND_ADDRESS = CONTRACT_LEND_ADDRESS_ARG || VEPOOLS_ADDRESS_LEND;
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse([CONTRACT_LEND_ABI[networkName]]), CONTRACT_LEND_ADDRESS);
};

export const getVeTokenContract = (CONTRACT_IZI_ADDRESS_ARG, networkName) => {
  const CONTRACT_IZI_ADDRESS = CONTRACT_IZI_ADDRESS_ARG || VEPOOLS_ADDRESS_VETOKEN;
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_VETOKEN_ABI[networkName]), CONTRACT_IZI_ADDRESS);
};

export const getVeNFTContract = (CONTRACT_VEIZI_ADDRESS_ARG, networkName) => {
  const CONTRACT_VEIZI_ADDRESS = CONTRACT_VEIZI_ADDRESS_ARG || VEPOOLS_ADDRESS_VENFT;
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_VENFT_ABI[networkName]), CONTRACT_VEIZI_ADDRESS);
};

export const getCheeTokenContract = (CONTRACT_CHEEIZI_ADDRESS_ARG, networkName) => {
  const CONTRACT_CHEEIZI_ADDRESS = CONTRACT_CHEEIZI_ADDRESS_ARG || VEPOOLS_ADDRESS_CHEETOKEN;
  const instance = getWeb3();
  return new instance.eth.Contract(JSON.parse(CONTRACT_CHEEIZI_ABI[networkName]), CONTRACT_CHEEIZI_ADDRESS);
};

export const methods = {
  call,
  send,
};
