import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { FormattedMessage } from 'react-intl';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';

import CircleProgressBar from 'components/common/circleProgressBar';
import BigNumber from 'bignumber.js';
import AnimatedNumber from 'animated-number-react';
import { Card, Row, Column, Label } from 'components/common/style';
import { formatNumber, getBigNumber } from 'utilities/number';
import { getCaiVaultContract, methods } from 'utilities/contractService';
import { CONTRACT_CAI_VAULT_ADDRESS } from 'constants/address';
import apyImg from 'assets/img/apy_icon.svg';
import apyOpenImg from 'assets/img/apy_icon_open.svg';
import { Divider } from 'antd';
import Toggle from './toggle';
import { CardWrapper, BalancerWrapper, MainContainer } from './style';
import BorrowLimit from '../borrowLimit';

const abortController = new AbortController();

function WalletBalance({ settings, setSetting }) {
  const [netAPY, setNetAPY] = useState(0);
  const [withCHEE, setWithCHEE] = useState(true);

  const [totalSupply, setTotalSupply] = useState(getBigNumber(0));
  const [totalBorrow, setTotalBorrow] = useState(getBigNumber(0));
  const [available, setAvailable] = useState('0');
  const [suppliedCollateral, setSuppliedCollateral] = useState(getBigNumber(0));

  useEffect(() => {
    if (settings.selectedAddress) {
      const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
      const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
      const total_new = BigNumber.maximum(totalBorrowLimit.minus(totalBorrowBalance), 0);
      setAvailable(total_new.dp(2, 1).toString(10));
    }
  }, [settings.totalBorrowBalance, settings.totalBorrowLimit]);

  const addCAIApy = useCallback(
    async (apy) => {
      // const vaultContract = getCaiVaultContract(
      //   CONTRACT_CAI_VAULT_ADDRESS[settings.networkName]
      // );
      // const { 0: staked } = await methods.call(vaultContract.methods.userInfo, [
      //   settings.selectedAddress,
      // ]);
      // const amount = getBigNumber(staked).div(1e18);
      // if (amount.isNaN() || amount.isZero()) {
      setNetAPY(apy.dp(2, 1).toNumber());
      // } else {
      //   setNetAPY(apy.plus(settings.caiAPY).dp(2, 1).toNumber());
      // }
    },
    [settings]
  );

  const updateNetAPY = useCallback(async () => {
    let totalSum = getBigNumber(0);
    let totalSupplied = getBigNumber(0);
    let totalSuppliedCollateral = getBigNumber(0);
    let totalBorrowed = getBigNumber(settings.userCaiMinted);
    const { assetList } = settings;
    assetList.forEach((asset) => {
      if (!asset) return;
      const {
        supplyBalance,
        borrowBalance,
        tokenPrice,
        supplyApy,
        borrowApy,
        cheeSupplyApy,
        cheeBorrowApy,
        collateral,
      } = asset;
      const supplyBalanceUSD = getBigNumber(supplyBalance).times(getBigNumber(tokenPrice));
      const borrowBalanceUSD = getBigNumber(borrowBalance).times(getBigNumber(tokenPrice));
      totalSupplied = totalSupplied.plus(supplyBalanceUSD);
      totalBorrowed = totalBorrowed.plus(borrowBalanceUSD);

      if (collateral === true) {
        totalSuppliedCollateral = totalSuppliedCollateral.plus(supplyBalanceUSD);
      }

      const supplyApyWithCHEE = withCHEE
        ? getBigNumber(supplyApy).plus(getBigNumber(cheeSupplyApy))
        : getBigNumber(supplyApy);
      const borrowApyWithCHEE = withCHEE
        ? getBigNumber(cheeBorrowApy).plus(getBigNumber(borrowApy))
        : getBigNumber(borrowApy);

      // const supplyApyWithCHEE = getBigNumber(supplyApy);
      // const borrowApyWithCHEE = getBigNumber(borrowApy).times(-1);
      totalSum = totalSum.plus(
        supplyBalanceUSD.times(supplyApyWithCHEE.div(100)).plus(borrowBalanceUSD.times(borrowApyWithCHEE.div(100)))
      );
    });

    let apy;

    if (totalSum.isZero() || totalSum.isNaN()) {
      apy = getBigNumber(0);
    } else if (totalSum.isGreaterThan(0)) {
      apy = totalSupplied.isZero() ? 0 : totalSum.div(totalSupplied).times(100);
    } else {
      apy = totalBorrowed.isZero() ? 0 : totalSum.div(totalBorrowed).times(100);
    }
    setTotalSupply(totalSupplied);
    setTotalBorrow(totalBorrowed);
    setSuppliedCollateral(totalSuppliedCollateral);
    addCAIApy(apy);
  }, [settings.assetList, withCHEE]);

  useEffect(() => {
    if (settings.selectedAddress && settings.assetList && settings.assetList.length > 0) {
      updateNetAPY();
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [settings.selectedAddress, updateNetAPY]);

  useEffect(() => {
    setSetting({
      withCHEE,
    });
  }, [withCHEE]);

  const formatValue = (value) => {
    return `${formatNumber(getBigNumber(value))}`;
  };

  return (
    <Card>
      <MainContainer>
        <div className="top">
          <div className="item">
            <div className="title">
              <FormattedMessage id="Total Supplied" />
            </div>
            <div className="flex align-center m-t-10">
              <div className="symbol">$</div>
              <div className="number">
                <AnimatedNumber value={totalSupply.dp(2, 1).toString(10)} formatValue={formatValue} duration={0} />
              </div>
            </div>
          </div>
          <Divider type="vertical" style={{ height: '20px', color: '#273130' }} />
          <div className="item">
            <div className="title">
              <FormattedMessage id="Supplied Collateral" />
            </div>
            <div className="flex align-center m-t-10">
              <div className="symbol">$</div>
              <div className="number">
                <AnimatedNumber
                  value={suppliedCollateral.dp(2, 1).toString(10)}
                  formatValue={formatValue}
                  duration={0}
                />
              </div>
            </div>
          </div>
          <Divider className="xs-hidden" type="vertical" style={{ height: '20px', color: '#273130' }} />
          <div className="item">
            <div className="title">
              <FormattedMessage id="Total Borrowed" />
            </div>
            <div className="flex align-center m-t-10">
              <div className="symbol">$</div>
              <div className="number">
                <AnimatedNumber value={totalBorrow.dp(2, 1).toString(10)} formatValue={formatValue} duration={0} />
              </div>
            </div>
          </div>
          <Divider type="vertical" style={{ height: '20px', color: '#273130' }} />
          <div className="item">
            <div className="title">
              <FormattedMessage id="Net APY" />
            </div>
            <div className="flex align-center m-t-10">
              <div className="number">{netAPY}</div>
              <div className="symbol">%</div>
            </div>
          </div>
          <div className="item md-hidden">
            <div className="title">
              <FormattedMessage id="Available Credit" />
            </div>
            <div className="flex align-center m-t-10">
              <div className="number">${formatNumber(available)}</div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <BorrowLimit />
        </div>
      </MainContainer>
    </Card>
  );
}

WalletBalance.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired,
};

WalletBalance.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(WalletBalance);
