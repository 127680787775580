import styled from 'styled-components';
import { dim } from 'modules/ui/dim';

const CardWrapper = styled.div`
  width: 100%;
  border-radius: 4px;
  background-color: ${(props) => props.theme['--color-bg']};
  padding: 24px 30px;
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: row;
    padding: 20px;
    height: auto;
  }

  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: ${(props) => props.theme['--color-font-new']};
    opacity: 0.6;
  }

  .img-wrap {
    position: relative;
    width: 32px;
    height: 32px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 14px;
      height: 14px;
    }
  }
  .number {
    color: ${(props) => props.theme['--color-font-number']};
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
  }
  .apply {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .earth {
      margin-top: 10px;
    }
    .add-mask {
      max-width: 100%;
      width: 100%;
      height: 36px;
      border: 1px solid ${(props) => props.theme['--color-border-new']};
      box-sizing: border-box;
      border-radius: 4px;
      text-align: left;
      padding: 20px;
      color: ${(props) => props.theme['--color-font-new']};
      @media only screen and (max-width: 1009px) {
        width: 127px;
      }
      @media only screen and (max-width: 992px) {
        width: 185px;
      }
      @media only screen and (max-width: 768px) {
        width: auto;
        white-space: nowrap;
      }
      &:hover {
        opacity: 0.7;
        .font {
          cursor: pointer;
          // color: ${(props) => props.theme['--color-yellow']};
          // font-weight: 900;
          // font-size: 15px
        }
      }
      img {
        width: 12px;
        height: 14px;
        margin-right: 9px;
        color: #ffffff;
        // border: 1px solid red;
        &:hover {
          opacity: 0.1s;
        }
      }
      .font {
        opacity: 0.6;
        // margin: 7px 0;
      }
    }
    @media only screen and (max-width: 768px) {
      flex-direction: row;
      align-items: center;
      button {
        margin: 0;
        height: 55px;
        max-width: 110px;
        word-break: break-all;
        white-space: break-spaces;
        font-size: 10px;
      }
    }
  }
  .add-chee-token {
    font-size: 15px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    color: ${(props) => props.theme['--color-yellow']};
    margin-left: 10px;
    margin-bottom: 0px;
  }

  p {
    font-size: 17.5px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-weight: 900;
    line-height: 1;
    color: ${(props) => props.theme['--color-text-secondary']};
  }

  .copy-btn {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: ${(props) => props.theme['--color-yellow']};
    margin-left: 26px;

    i {
      color: ${(props) => props.theme['--color-text-main']};
      svg {
        transform: rotate(-45deg);
      }
    }
  }
`;
export { CardWrapper };
