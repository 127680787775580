import { loadState } from 'utilities/localStorage';

const auth = {
  user: null,
};

const account = {
  setting: {
    chainId: -1,
    selectedAddress: null,
    marketType: 'supply',
    borrowMarket: [],
    supplyMarket: [],
    latestBlockNumber: '',
    decimals: {},
    assetList: [],
    totalBorrowBalance: '0',
    totalBorrowLimit: '0',
    walletType: 'metamask',
    pendingInfo: {
      type: '',
      status: false,
      amount: 0,
      symbol: '',
    },
    vaultCaiStaked: null,
    withCHEE: true,
    markets: [],
    accountLoading: false,
    wrongNetwork: false,
    networkName: '',
    userCaiBalance: '0',
    caiAPY: 0,
    userCHEEBalance: '',
    userCaiMinted: 0,
    web3: {},
    allNetWorkMarkets: [],
  },
};
const storageUi = loadState();

export const networkStatus = {
  error: 'error',
  success: 'success',
  warning: 'warning',
};

const ui = {
  lang: 'en',
  theme: 'dark',
  ...storageUi,
  subgraphStatus: networkStatus.success, // error, success, warning
};

export const initialState = {
  auth,
  account,
  ui,
};
