import styled, { keyframes } from 'styled-components';
import { dim } from 'modules/ui/dim';

const ModalContent = styled.div`
  // background-color: ${(props) => props.theme['--color-bg-primary']};

  .close-btn {
    color: #ffffff;
    position: absolute;
    top: 23px;
    right: 23px;
    @media only screen and (max-width: 768px) {
      right: 14px;
      top: 14px;
    }
    &:hover {
      filter: invert(100%);
    }
  }
  .radius {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .header-content {
    width: 100%;
    background: #0c1111;
    @media only screen and (max-width: 768px) {
      padding-top: 0;
      height: 64px;
    }

    .logo-image {
      width: 65px;
      height: 61px;
      margin-bottom: 39px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 19px;
      }
    }
    .title {
      font-size: ${dim(18)};
      color: #ffffff;
      height: 88px;
      line-height: 88px;
      font-weight: 700;
    }

    .back-btn {
      width: 100%;
      padding: 10px 30px;
      img {
        transform: rotate(180deg);
        margin-right: 10px;
      }
      span {
        color: ${(props) => props.theme['--color-white']};
        font-size: 20px;
      }
    }
  }
  .mint-content-wrapper {
    width: 100%;
    padding-bottom: 30px;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .mint-content {
    width: 100%;
    padding: 30px 0 0px 55px;
    height: 262px;
    align-items: flex-start;
    @media only screen and (max-width: 768px) {
      padding: 20px;
      height: 100%;
      width: 100%;
    }
    .content-item {
      display: flex;
      align-items: center;
      padding-bottom: 30px;
      @media only screen and (max-width: 768px) {
        align-items: flex-start;
      }
      .coin-type {
        font-size: 36px;
        line-height: 36px;
        font-weight: 600;
        margin-right: 26px;
        @media only screen and (max-width: 768px) {
          position: absolute;
          right: 20px;
          font-size: 23px;
          margin-right: 0;
        }
        img {
          display: inline-block;
          width: 36px;
          height: 36px;
          margin-right: 10px;
          @media only screen and (max-width: 768px) {
            width: 23px;
            height: 23px;
            margin-right: 5px;
          }
        }
      }
      .coin-info {
        color: #939498;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(270deg, #60ffab 0%, #30bd72 100%);
    border-radius: 4px;
    width: calc(100% - 110px);
    height: 50px;
    cursor: default;
    margin: 0 auto;
    font-weight: 600;
    font-size: 16px;

    @media only screen and (max-width: 768px) {
      flex-basis: calc(25% - 12px);
      width: 100%;
    }
  }
  .btn-hover {
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
`;
const progressBarArg = keyframes`
  from {
    width: 0;
  }
  to {
    width: calc(100% - 5px);
  }
`;
const ProgressBar = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  animation-play-state: paused;
  opacity: 1;
  width: 100%;
  height: 5px;
  background: #008a83;
  position: absolute;
  top: -5px;
  border-radius: 2px 2px 0 0;
  z-index: 1;
  &:after {
    border-radius: 2px 2px 2px 0;
    content: '';
    height: 5px;
    position: absolute;
    left: 0;
    width: 0;
    animation: ${progressBarArg} 40s cubic-bezier(0.25, 0.25, 0.25, 1) 1;
    animation-fill-mode: forwards;
    background: #229558;
  }
`;
export { ModalContent, ProgressBar };
