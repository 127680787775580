import styled from 'styled-components';
import { dim } from 'modules/ui/dim';

const ModalContent = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme['--color-bg-primary']};

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    padding: 4px 4px 8px 10px;
    background: #243f3d;
    border-radius: 0 0 0 24px;
    overflow: visible;
    @media only screen and (max-width: 768px) {
      right: 5px;
      top: 5px;
      width: 12px;
      height: 12px;
      background: none;
      padding: 0;
    }
  }

  .logo-text {
    width: 110px;
  }
  .title {
    margin-top: 40px;
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme['--color-collateral-enable']};
  }

  .voting-spinner {
    color: #229558;
    margin: 40px 0 20px 0;
  }

  .confirm-text {
    font-size: 14px;
    line-height: 14px;
    color: #868c8a;
    margin-bottom: ${dim(40)};
  }
`;
export { ModalContent };
