import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LineProgressBar from 'components/common/lineProgressBar';
import BigNumber from 'bignumber.js';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import { Card, Column, Row } from 'components/common/style';
import { formatNumber, getBigNumber } from 'utilities/number';
import iconDollar from 'assets/img/icons/dollar.svg';
import { Progress } from 'antd';
import { BorrowWrap } from './style';

const abortController = new AbortController();

function BorrowLimit({ settings }) {
  const [available, setAvailable] = useState('0');
  const [borrowPercent, setBorrowPercent] = useState(0);

  useEffect(() => {
    if (settings.selectedAddress) {
      const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
      const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
      const total = BigNumber.maximum(totalBorrowLimit, 0);
      const total_new = BigNumber.maximum(totalBorrowLimit.minus(totalBorrowBalance), 0);
      // setAvailable(total.dp(2, 1).toString(10));
      setAvailable(total_new.dp(2, 1).toString(10)); // should display available balance here
      setBorrowPercent(
        total.isZero() || total.isNaN() ? 0 : totalBorrowBalance.div(total).times(100).dp(0, 1).toNumber()
      );
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [settings.totalBorrowBalance, settings.totalBorrowLimit]);
  return (
    <BorrowWrap left={100 - borrowPercent}>
      <div className="flex just-between">
        <div className="available">
          <FormattedMessage id="Available Credit" />
        </div>
        <div>${formatNumber(available)}</div>
      </div>
      <div className="process-wrap">
        <Progress
          percent={100 - borrowPercent}
          strokeColor={{
            from: '#BDF4F1',
            to: '#498885',
          }}
          strokeWidth={13}
          showInfo
        />
      </div>
    </BorrowWrap>
  );
}

BorrowLimit.propTypes = {
  settings: PropTypes.object,
};

BorrowLimit.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(connectAccount(mapStateToProps))(BorrowLimit);
