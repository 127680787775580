import styled from 'styled-components';
import { dim } from 'modules/ui/dim';

const ModalContent = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme['--color-bg-primary']};

  .close-btn {
    color: #ffffff;
    position: absolute;
    top: 23px;
    right: 23px;
    @media only screen and (max-width: 768px) {
      width: 12px;
      height: 12px;
      right: 14px;
      top: 12px;
    }

    &:hover {
      /* filter: invert(100%); */
    }
  }

  .header-content {
    width: 100%;
    background: #0c1111;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .logo-image {
      width: 65px;
      height: 61px;
      margin-bottom: 39px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 19px;
      }
    }
    @media only screen and (max-width: 768px) {
      padding-top: 0;
      height: 64px;
    }
    .title {
      font-size: ${dim(18)};
      color: #ffffff;
      height: 88px;
      line-height: 88px;
      font-weight: 700;
    }

    .back-btn {
      width: 100%;
      padding: 10px 30px;
      img {
        transform: rotate(180deg);
        margin-right: 10px;
      }
      span {
        color: ${(props) => props.theme['--color-white']};
        font-size: 20px;
      }
    }
  }
  .connect-wallet-content {
    width: 100%;
    padding: 40px 55px;
    display: flex;
    flex-wrap: wrap;

    @media only screen and (max-width: 564px) {
      justify-content: center;
      padding: ${dim(36)} 0;
      & > div {
        margin-right: 0 !important;
      }
    }

    .metamask-connect-btn:nth-of-type(odd) {
      margin-right: 10px;
    }
    .metamask-connect-btn {
      width: 48%;
      cursor: pointer;
      /* padding: 0 15px; */
      height: ${dim(60)};
      margin-bottom: ${dim(17)};
      border-radius: 4px;
      padding: 0 ${dim(20)};
      position: relative;
      background: ${(props) => props.theme['--color-connect-background']};

      @media only screen and (max-width: 564px) {
        padding: 0 ${dim(54)};
      }

      @media only screen and (max-width: 543px) {
        height: ${dim(84)};
        font-size: 12px;
        padding: 0 ${dim(48)};
      }

      @media only screen and (max-width: 466px) {
        height: ${dim(72)};
        padding: 0 ${dim(168)};
        justify-content: center;
      }

      &:hover {
        background: ${(props) => props.theme['--color-connect-btn-hover-borderImage']};
        background-repeat: no-repeat;
        &::after {
          position: absolute;
          width: calc(100% - 4px);
          height: calc(100% - 4px);
          border-radius: 6px;
          top: 2px;
          left: 2px;
          content: '';
          z-index: 0;
          background: ${(props) => props.theme['--color-connect-btn-hover-background']};
        }
      }
      & > div {
        position: absolute;
        z-index: 10;
        img {
          width: ${dim(28)};
          margin-right: ${dim(10)};
        }
        span {
          color: ${(props) => props.theme['--color-text-main']};
          font-weight: normal;
          font-size: 14px;
        }
      }

      span {
        color: ${(props) => props.theme['--color-text-secondary']};
        font-weight: normal;
        font-size: 17px;
      }
    }

    .coinbase-connect-btn,
    .ledger-connect-btn {
      border-bottom: 1px solid ${(props) => props.theme['--color-bg-active']};
    }

    .metamask-status {
      margin-top: 20px;
      background-color: rgba(255, 0, 0, 0.03);
      padding: 5px 10px;
      border-radius: 4px;
      color: ${(props) => props.theme['--color-red']};
      a {
        margin-left: 5px;
      }
    }
  }

  .terms-of-use {
    font-size: 13.5px;
    color: ${(props) => props.theme['--color-text-secondary']};
    margin-bottom: 32px;
    a {
      color: ${(props) => props.theme['--color-orange']};
      margin-left: 11px;
    }
  }
`;

export { ModalContent };
