import React from 'react';
import PropTypes from 'prop-types';

import { Icon } from 'antd';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import { CONTRACT_TOKEN_ADDRESS, EXPLORER_LINK } from 'constants/address';
import addToken from 'utilities/addToken';
import { formatNumber, getBigNumber } from 'utilities/number';
import iconWallet from 'assets/img/icons/wallet.svg';
import { ReactComponent as iconLink } from 'assets/img/icons/link.svg';
import { Card } from 'components/common/style';
import plusImg from 'assets/img/plus.svg';
import plusImgLight from 'assets/img/plus_light.svg';
import earthImg from 'assets/img/earth-small.svg';
import earthImgLight from 'assets/img/earth-small-light.svg';
import CustomButton from 'components/common/button';
import { isProd } from 'constants/env';
import { CardWrapper } from './style';

function CoinInfo({ settings, ui }) {
  const handleLink = () => {
    window.open(
      `${EXPLORER_LINK[settings.networkName]}/token/${CONTRACT_TOKEN_ADDRESS[settings.networkName].chee.address}?a=${
        settings.selectedAddress
      }`,
      '_blank'
    );
  };

  const leftNum = formatNumber(settings.userCHEEBalance);
  return (
    <Card>
      <CardWrapper className="flex align-center">
        <div className="flex flex-column ">
          <div className="flex flex-column">
            <div className="img-wrap">
              <img src={iconWallet} alt="chee-balance" />
            </div>
            <div>
              <div className="title m-t-12">CHEE</div>
              <div className="number m-t-10 md-hidden">{leftNum}</div>
            </div>
          </div>
          <div className="number xs-hidden m-t-12">{leftNum}</div>
        </div>
        <div className="apply">
          <CustomButton
            className="flex align-center translucent disabled-btn"
            onClick={() => addToken('chee', 18, 'token', settings.networkName)}
          >
            <img className="m-r-9" src={ui.theme === 'dark' ? plusImg : plusImgLight} alt="" />
            <span className="font">Add to Metamask</span>
          </CustomButton>
          <CustomButton
            className={`flex align-center m-t-10 translucent  ${isProd ? 'disabled-btn' : ''}`}
            onClick={() => handleLink()}
          >
            <img className="m-r-9" src={ui.theme === 'dark' ? earthImg : earthImgLight} alt="" />
            <span className="font">View on Explorer</span>
          </CustomButton>
        </div>
      </CardWrapper>
    </Card>
  );
}

CoinInfo.propTypes = {
  settings: PropTypes.object,
};

CoinInfo.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

export default compose(connectAccount(mapStateToProps, undefined))(CoinInfo);
