import styled from 'styled-components';

const P2PDetailWrapper = styled.div`
  .card-wrap {
    margin-top: 20px;
    display: flex;
    .left {
      flex: 1;
    }
    .right {
      margin-left: 20px;
      flex: 1;
    }
  }
`;

export { P2PDetailWrapper };
