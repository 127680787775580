import styled from 'styled-components';
import svg from 'assets/img/bg.png';
import assets from 'assets/img/assets.svg';

import { dim } from 'modules/ui/dim';

const CardWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${dim(190)};
  border-radius: 4px;
  background: url(${svg});
  background-size: 100% 100%;
  color: white;
  @media only screen and (max-width: 768px) {
    height: 193px;
  }
  .net-wrap {
    position: absolute;
    color: #c7e9d6;
    left: 30px;
    top: 26px;
    .per-wrap {
      margin-top: 10px;
      .strong {
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        color: white;
        @media only screen and (max-width: 768px) {
          font-size: 23px;
          line-height: 23px;
        }
      }
    }
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 20px;
      top: 20px;
      left: 20px;
    }
  }
  .apt-wrap {
    position: absolute;
    right: 30px;
    top: 26px;
    height: 20px;
    .logo-wrap {
      width: 18px;
      height: 20px;
      img {
        width: 18px;
        height: 20px;
        display: block;
      }
    }
    .logo-wrap2 {
      width: 12px;
      height: 14px;
      img {
        width: 12px;
        height: 14px;
        display: block;
      }
    }
    .name {
      margin-right: 10px;
      margin-left: 6px;
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    @media only screen and (max-width: 768px) {
      top: 20px;
      right: 20px;
    }
  }
  .balance-wrap {
    position: absolute;
    color: #c7e9d6;
    left: 30px;
    top: 120px;
    .num {
      font-weight: 400;
      font-size: 18px;
      margin-top: 10px;
      color: white;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
      }
    }
    .left {
      margin-right: 40px;
      &::after {
        content: '';
        background-color: #ffffff;
        opacity: 0.3;
        width: 1px;
        height: 33px;
        position: absolute;
        left: 138px;
        top: 10px;
      }
    }
    .right-wrap {
      margin-left: 40px;
    }
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      top: 93px;
      left: 20px;
    }
  }
  .credit-wrap {
    position: absolute;
    color: #c7e9d6;
    bottom: 21px;
    width: 100%;
    .available {
      margin-right: 10px;
    }
    .progress {
      width: 42%;
      height: 12px;
      background: #009c4f;
      border-radius: 40px;
      margin-right: 10px;
      .light {
        height: 12px;
        border-radius: 40px;
        background: #0c1111;
      }
    }
  }
  @media only screen and (max-width: 1440px) {
    // margin-top: 10px;
  }

  .second {
    // border: 1px solid red;
    margin-top: 24px;
    display: flex;
    align-items: center;
  }
  .label {
    font-size: 14px;
    color: #ffffff;
  }

  .value {
    font-size: 25px;
    font-weight: 900;
    // color: ${(props) => props.theme['--color-text-main']};
    color: #ffffff;
    // margin-top: 44px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }
  .title {
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
  }

  @media only screen and (max-width: 768px) {
    .label {
      font-size: 12px;
    }
    .value {
      font-size: 12px;
    }
    .apy-toggle {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  @media only screen and (mix-width: 1280px) {
    .label {
      font-size: 16px;
    }
    .value {
      font-size: 20px;
    }
  }
`;

const BalancerWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  &::before {
    content: '';
    background-color: #ffffff;
    width: 3px;
    height: 43px;
    position: absolute;
    left: 0px;
  }
`;

const ToggleWrapperBallance = styled.div`
  /* height: 15px; */
  // margin-top: -5px;

  .ant-switch {
    background-color: white;
    height: 20px;
    width: 32px;
    display: block;
    &::after {
      background-color: #229558;
      margin-top: -1px;
    }
    @media only screen and (max-width: 768px) {
      height: 16px;
      width: 27px;
      min-width: 27px;
      &::after {
        top: 2px;
        width: 12px;
        height: 12px;
      }
    }
  }

  .ant-switch-checked {
    background-color: white;
    &::after {
      background-color: #229558;
    }
  }
`;

const MainContainer = styled.div`
  width: 100%;
  padding: 0 0 20px;
  background-size: cover;
  overflow: hidden;
  @media screen and (max-width: 768px) {
    padding: 0 0 20px;
  }
  .top {
    background: #243f3d;
    border-radius: 4px;
    padding: 20px 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      @media screen and (max-width: 768px) {
        margin-top: 10px;
        align-items: flex-start;
        flex-basis: 45%;
        flex-grow: 0;
      }
      .title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* identical to box height */
        color: #c6dbda;
        @media screen and (max-width: 768px) {
          font-size: 10px;
          line-height: 12px;
        }
      }
      .symbol {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        color: #c6dbda;
        @media screen and (max-width: 768px) {
          font-size: 15px;
          line-height: 18px;
        }
      }
      .number {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 15px;
        line-height: 18px;
        color: #c6dbda;
        @media screen and (max-width: 768px) {
          font-size: 15px;
          line-height: 18px;
        }
      }
    }
  }
  .bottom {
    display: flex;
    align-items: center;
    height: 44px;
    .icon {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      background: url(${assets}) no-repeat center;
    }
    .title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 14px;
      color: #ffffff;
    }
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export { CardWrapper, BalancerWrapper, ToggleWrapperBallance, MainContainer };
