import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { Row, Col, Icon } from 'antd';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import MainLayout from 'components/layout/wrap';
// import { getCaiTokenContract } from 'utilities/contractService';
import { promisify } from 'utilities/promisify';
import { CONTRACT_TOKEN_ADDRESS, CONTRACT_CAI_TOKEN_ADDRESS, REMAINING } from 'constants/address';
import LoadingSpinner from 'components/common/loadingSpinner';
import { totalDistributed, getPerday } from 'utilities/distributed';
import { PageTitle } from 'components/common/style';
import { formatNumber, getBigNumber } from 'utilities/number';
import CheeIcon from 'assets/img/coins/chee.png';
import { SpinnerWrapper } from '../dashBoard/style';
import { MarketWrapper, TableWrapper, TitleWrapper, TableWrapperMView, ScrollContent } from './style';

function Market({ history, settings, getTreasuryBalance }) {
  const [totalSupply, setTotalSupply] = useState('0');
  const [totalBorrow, setTotalBorrow] = useState('0');
  const [clickCount, setclickCounter] = useState({ field: '', clicks: 0 });
  const [availableLiquidity, setAvailableLiquidity] = useState('0');
  const [sortInfo, setSortInfo] = useState({ field: '', sort: 'desc' });
  const [totalTreasury, setTotalTreasury] = useState(0);
  const [canShowPage, setCanShowPage] = useState(false);

  // const loadTreasuryBalance = useCallback(async () => {
  //   await promisify(getTreasuryBalance, {})
  //     .then((res) => {
  //       const total = (res.data || []).reduce((accumulator, asset) => {
  //         return accumulator + Number(asset.balance) * Number(asset.price);
  //       }, 0);
  //       setTotalTreasury(total.toFixed(2));
  //     })
  //     .catch(() => {});
  // }, []);

  // useEffect(() => {
  //   loadTreasuryBalance();
  // }, [settings.markets]);

  const getTotalInfo = async () => {
    // const caiContract = getCaiTokenContract(CONTRACT_CAI_TOKEN_ADDRESS[settings.networkName]);
    const tempTS = (settings.markets || []).reduce((accumulator, market) => {
      return getBigNumber(accumulator).plus(getBigNumber(market.totalSupplyUsd));
    }, getBigNumber(0));
    const tempTB = (settings.markets || []).reduce((accumulator, market) => {
      return getBigNumber(accumulator).plus(getBigNumber(market.totalBorrowsUsd));
    }, getBigNumber(0));
    const tempAL = (settings.markets || []).reduce((accumulator, market) => {
      return getBigNumber(accumulator).plus(getBigNumber(market.liquidity));
    }, getBigNumber(0));

    setTotalSupply(tempTS.plus(settings.vaultCaiStaked).dp(2, 1).toString(10));
    setTotalBorrow(tempTB.dp(2, 1).toString(10));
    setAvailableLiquidity(tempAL.plus(settings.vaultCaiStaked).dp(2, 1).toString(10));
  };

  useEffect(() => {
    if (settings.markets && settings.dailyChee) {
      getTotalInfo();
      setCanShowPage(true);
    }
  }, [settings.markets]);

  const clickCounter = (field) => {
    setclickCounter({
      field,
      clicks: clickCount.clicks + 1,
    });
  };

  const handleSort = (field) => {
    setSortInfo({
      field,
      sort: sortInfo.field === field && sortInfo.sort === 'desc' ? 'asc' : 'desc',
    });
    clickCounter(field);
  };

  return (
    <MainLayout title={<FormattedMessage id="All Markets" />}>
      {!canShowPage ? (
        <SpinnerWrapper>
          <LoadingSpinner />
        </SpinnerWrapper>
      ) : (
        <MarketWrapper>
          <PageTitle>
            <FormattedMessage id="Market Data" />
          </PageTitle>
          <TitleWrapper>
            <div className="total-items left">
              {[
                { id: 'Total Supply', value: totalSupply },
                { id: 'Total Borrow', value: totalBorrow },
                { id: 'Available Liquidity', value: availableLiquidity },
                // { id: 'Total Treasury', value: totalTreasury },
              ].map((item, index) => (
                <div className="total-item" key={index}>
                  <div className="prop">
                    <FormattedMessage id={item.id} />
                  </div>
                  <div className="value">
                    <div className="dollar"> $</div>
                    <div>{formatNumber(item.value)}</div>
                  </div>
                </div>
              ))}
            </div>
            <div className="total-items right">
              <div className="total-item">
                <div className="prop">
                  <FormattedMessage id="Distributed" />
                </div>
                <div className="value">
                  <div className="dollar"> $</div>
                  <div>{formatNumber(totalDistributed(settings.networkName))}</div>
                </div>
              </div>
              <div className="total-item">
                <div className="prop">
                  <FormattedMessage id="Remaining" />
                </div>
                <div className="value">
                  <div className="dollar"> $</div>
                  <div>0</div>
                </div>
              </div>
            </div>
          </TitleWrapper>
          <ScrollContent>
            <TableWrapper>
              <Row className="table_header">
                <Col span={2}>
                  <span>
                    <FormattedMessage id="Coins" />
                  </span>
                </Col>
                <Col span={3}>
                  <span onClick={() => handleSort('total_supply')}>
                    <FormattedMessage id="Total Supply" />{' '}
                    {sortInfo.field === 'total_supply' && clickCount.field === 'total_supply' && (
                      <Icon
                        type={clickCount.clicks % 3 === 0 ? '' : sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'}
                      />
                    )}
                  </span>
                </Col>
                <Col span={3}>
                  <span onClick={() => handleSort('supply_apy')}>
                    <FormattedMessage id="Supply APY" />{' '}
                    {sortInfo.field === 'supply_apy' && clickCount.field === 'supply_apy' && (
                      <Icon
                        type={clickCount.clicks % 3 === 0 ? '' : sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'}
                      />
                    )}
                  </span>
                </Col>
                <Col span={3}>
                  <span onClick={() => handleSort('total_borrow')}>
                    <FormattedMessage id="Total Borrow" />{' '}
                    {sortInfo.field === 'total_borrow' && clickCount.field === 'total_borrow' && (
                      <Icon
                        type={clickCount.clicks % 3 === 0 ? '' : sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'}
                      />
                    )}
                  </span>
                </Col>
                <Col span={3}>
                  <span onClick={() => handleSort('borrow_apy')}>
                    <FormattedMessage id="Borrow APY" />{' '}
                    {sortInfo.field === 'borrow_apy' && clickCount.field === 'borrow_apy' && (
                      <Icon
                        type={clickCount.clicks % 3 === 0 ? '' : sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'}
                      />
                    )}
                  </span>
                </Col>
                <Col span={2}>
                  <span onClick={() => handleSort('liquidity')}>
                    <FormattedMessage id="Liquidity" />{' '}
                    {sortInfo.field === 'liquidity' && clickCount.field === 'liquidity' && (
                      <Icon
                        type={
                          clickCount.clicks % 3 === 0
                            ? ''
                            : sortInfo.sort === 'desc'
                            ? 'caret-down'
                            : sortInfo.sort === 'none'
                            ? ''
                            : 'caret-up'
                        }
                      />
                    )}
                  </span>
                </Col>
                <Col span={2}>
                  <span onClick={() => handleSort('reserve')}>
                    <FormattedMessage id="Reserve Factor" />{' '}
                    {sortInfo.field === 'reserve' && clickCount.field === 'reserve' && (
                      <Icon
                        type={clickCount.clicks % 3 === 0 ? '' : sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'}
                      />
                    )}
                  </span>
                </Col>
                <Col span={2}>
                  <span onClick={() => handleSort('collateral')}>
                    <FormattedMessage id="Collateral Factor" />
                    {sortInfo.field === 'collateral' && clickCount.field === 'collateral' && (
                      <Icon
                        type={clickCount.clicks % 3 === 0 ? '' : sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'}
                      />
                    )}
                  </span>
                </Col>
                <Col span={2}>
                  <span onClick={() => handleSort('price')}>
                    <FormattedMessage id="Price" />{' '}
                    {sortInfo.field === 'price' && clickCount.field === 'price' && (
                      <Icon
                        type={clickCount.clicks % 3 === 0 ? '' : sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'}
                      />
                    )}
                  </span>
                </Col>
                <Col span={2}>
                  <span onClick={() => handleSort('perday')}>
                    <FormattedMessage id="CHEEs per day" />{' '}
                    {sortInfo.field === 'perday' && clickCount.field === 'perday' && (
                      <Icon
                        type={clickCount.clicks % 3 === 0 ? '' : sortInfo.sort === 'desc' ? 'caret-down' : 'caret-up'}
                      />
                    )}
                  </span>
                </Col>
              </Row>
              <div className="table_content">
                {settings.markets &&
                  (settings.markets || [])
                    .map((market) => {
                      return {
                        ...market,
                        totalSupplyApy: getBigNumber(market.supplyApy).plus(getBigNumber(market.supplyCheeApy)),
                        totalBorrowApy: getBigNumber(market.borrowCheeApy).plus(getBigNumber(market.borrowApy)),
                        perDay: getPerday(settings.networkName, market.underlyingSymbol.toLowerCase()),
                      };
                    })
                    .sort((a, b) => {
                      if (!sortInfo.field) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      if (sortInfo.field === 'total_supply') {
                        return sortInfo.sort === 'desc'
                          ? +getBigNumber(b.totalSupplyUsd).minus(getBigNumber(a.totalSupplyUsd)).toString(10)
                          : +getBigNumber(a.totalSupplyUsd).minus(getBigNumber(b.totalSupplyUsd)).toString(10);
                      }
                      if (clickCount.field === 'total_supply' && clickCount.clicks % 3 === 0) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      if (sortInfo.field === 'supply_apy') {
                        return sortInfo.sort === 'desc'
                          ? b.totalSupplyApy.minus(a.totalSupplyApy).toNumber()
                          : a.totalSupplyApy.minus(b.totalSupplyApy).toNumber();
                      }
                      if (clickCount.field === 'supply_apy' && clickCount.clicks % 3 === 0) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      if (sortInfo.field === 'total_borrow') {
                        return sortInfo.sort === 'desc'
                          ? +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10)
                          : +getBigNumber(a.totalBorrowsUsd).minus(getBigNumber(b.totalBorrowsUsd)).toString(10);
                      }
                      if (clickCount.field === 'total_borrow' && clickCount.clicks % 3 === 0) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      if (sortInfo.field === 'borrow_apy') {
                        return sortInfo.sort === 'desc'
                          ? b.totalBorrowApy.minus(a.totalBorrowApy).toNumber()
                          : a.totalBorrowApy.minus(b.totalBorrowApy).toNumber();
                      }
                      if (clickCount.field === 'borrow_apy' && clickCount.clicks % 3 === 0) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      if (sortInfo.field === 'liquidity') {
                        return sortInfo.sort === 'desc'
                          ? +getBigNumber(b.liquidity).minus(getBigNumber(a.liquidity)).toString(10)
                          : +getBigNumber(a.liquidity).minus(getBigNumber(b.liquidity)).toString(10);
                      }
                      if (clickCount.field === 'liquidity' && clickCount.clicks % 3 === 0) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      if (sortInfo.field === 'reserve') {
                        const formate = (val) => {
                          return +getBigNumber(val.reserveFactor || 0)
                            .div(getBigNumber(10).pow(18))
                            .times(100)
                            .dp(2, 1)
                            .toString(10);
                        };
                        return sortInfo.sort === 'desc' ? formate(b) - formate(a) : formate(a) - formate(b);
                      }
                      if (clickCount.field === 'reserve' && clickCount.clicks % 3 === 0) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      if (sortInfo.field === 'collateral') {
                        const formate = (val) => {
                          return +getBigNumber(val.collateralFactor || 0)
                            .div(getBigNumber(10).pow(18))
                            .times(100)
                            .dp(2, 1)
                            .toString(10);
                        };
                        return sortInfo.sort === 'desc' ? formate(b) - formate(a) : formate(a) - formate(b);
                      }
                      if (clickCount.field === 'collateral' && clickCount.clicks % 3 === 0) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      if (sortInfo.field === 'price') {
                        return sortInfo.sort === 'desc'
                          ? +getBigNumber(b.tokenPrice).minus(getBigNumber(a.tokenPrice)).toString(10)
                          : +getBigNumber(a.tokenPrice).minus(getBigNumber(b.tokenPrice)).toString(10);
                      }
                      if (clickCount.field === 'price' && clickCount.clicks % 3 === 0) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      if (sortInfo.field === 'perday') {
                        return sortInfo.sort === 'desc' ? b.perDay - a.perDay : a.perDay - b.perDay;
                      }
                      if (clickCount.field === 'perday' && clickCount.clicks % 3 === 0) {
                        return +getBigNumber(b.totalBorrowsUsd).minus(getBigNumber(a.totalBorrowsUsd)).toString(10);
                      }
                      return 0;
                    })
                    .map((item, index) => (
                      <div key={index}>
                        <Row className="table_content_DesktopView">
                          <Col span={2}>
                            <div className="table-item-1">
                              <img
                                src={
                                  CONTRACT_TOKEN_ADDRESS[settings.networkName][item.underlyingSymbol.toLowerCase()]
                                    ? CONTRACT_TOKEN_ADDRESS[settings.networkName][item.underlyingSymbol.toLowerCase()]
                                        .asset
                                    : null
                                }
                                alt="asset"
                              />
                              <p className="item-title">{item.underlyingSymbol}</p>
                            </div>
                          </Col>
                          <Col span={3}>
                            <div className="table-item">
                              <p className="item-title">{formatNumber(item.totalSupplyUsd, true, 2, '$')}</p>
                              <p className="item-value">
                                {formatNumber(
                                  getBigNumber(item.totalSupplyUsd).div(getBigNumber(item.tokenPrice)),
                                  true,
                                  2
                                )}{' '}
                                {item.underlyingSymbol}
                              </p>
                            </div>
                          </Col>
                          <Col span={3}>
                            <div className="table-item">
                              <p className="item-title green">{item.totalSupplyApy.toFormat(2)}%</p>
                              <p className="item-value-chee-img">
                                <img src={CheeIcon} alt="Chee-Icon" />
                                {getBigNumber(item.supplyCheeApy).toFormat(2)}%
                              </p>
                            </div>
                          </Col>

                          <Col span={3}>
                            <div className="table-item">
                              <p className="item-title">{formatNumber(item.totalBorrowsUsd, true, 2, '$')}</p>
                              <p className="item-value">
                                {formatNumber(
                                  getBigNumber(item.totalBorrowsUsd).div(getBigNumber(item.tokenPrice)),
                                  true,
                                  2
                                )}{' '}
                                {item.underlyingSymbol}
                              </p>
                            </div>
                          </Col>
                          <Col span={3}>
                            <div className="table-item">
                              <p className={`item-title${item.totalBorrowApy.lt(0) ? ' red' : ' green'}`}>
                                {item.totalBorrowApy.toFormat(2)}%
                              </p>
                              <p className="item-value-chee-img">
                                <img src={CheeIcon} alt="Chee-Icon" />
                                {getBigNumber(item.borrowCheeApy).toFormat(2)}%
                              </p>
                            </div>
                          </Col>
                          <Col span={2}>
                            <div className="table-item">
                              <p className="item-title">{formatNumber(item.liquidity, true, 2, '$')}</p>
                            </div>
                          </Col>
                          <Col span={2}>
                            <div className="table-item">
                              <p className="item-title">
                                {`${getBigNumber(item.reserveFactor || 0)
                                  .div(getBigNumber(10).pow(18))
                                  .multipliedBy(100)
                                  .dp(8, 1)
                                  .toString(10)}%`}
                              </p>
                            </div>
                          </Col>
                          <Col span={2}>
                            <div className="table-item">
                              <p className="item-title">
                                {`${getBigNumber(item.collateralFactor || 0)
                                  .div(getBigNumber(10).pow(18))
                                  .times(100)
                                  .dp(2, 1)
                                  .toString(10)}%`}
                              </p>
                            </div>
                          </Col>
                          <Col span={2}>
                            <div className="table-item">
                              <p className="item-title">{formatNumber(item.tokenPrice, true, 2, '$')}</p>
                            </div>
                          </Col>
                          <Col span={2}>
                            <div className="table-item">
                              <p className="item-title">{item.perDay}</p>
                            </div>
                          </Col>
                        </Row>
                        <TableWrapperMView>
                          <div className="table_content_M_View">
                            <div className="item-value-chee-img-icon">
                              <div className="table-item-1">
                                <img
                                  src={
                                    CONTRACT_TOKEN_ADDRESS[settings.networkName][item.underlyingSymbol.toLowerCase()]
                                      ? CONTRACT_TOKEN_ADDRESS[settings.networkName][
                                          item.underlyingSymbol.toLowerCase()
                                        ].asset
                                      : null
                                  }
                                  alt="asset"
                                />
                                <p className="item-title">{item.underlyingSymbol}</p>
                              </div>
                            </div>
                            <div className="table-items">
                              <div className="table-item">
                                <div className="Mobile-title">Total Supply</div>
                                <p className="item-title">{formatNumber(item.totalSupplyUsd, true, 2, '$')}</p>
                                <p className="item-value">
                                  {formatNumber(
                                    getBigNumber(item.totalSupplyUsd).div(getBigNumber(item.tokenPrice)),
                                    true,
                                    2
                                  )}{' '}
                                  {item.underlyingSymbol}
                                </p>
                              </div>
                              <div className="table-item">
                                <div className="Mobile-title">Supply APY</div>
                                <p className="item-title green">{item.totalSupplyApy.toFormat(2)}%</p>
                                <p className="item-value-chee-img">
                                  <img src={CheeIcon} alt="Chee-Icon" />
                                  {getBigNumber(item.supplyCheeApy).toFormat(2)}%
                                </p>
                              </div>
                              <div className="table-item">
                                <div className="Mobile-title">Total Borrow</div>
                                <p className="item-title">{formatNumber(item.totalBorrowsUsd, true, 2, '$')}</p>
                                <p className="item-value">
                                  {formatNumber(
                                    getBigNumber(item.totalBorrowsUsd).div(getBigNumber(item.tokenPrice)),
                                    true,
                                    2
                                  )}{' '}
                                  {item.underlyingSymbol}
                                </p>
                              </div>
                              <div className="table-item">
                                <div className="Mobile-title">Borrow APY</div>
                                <p className={`item-title${item.totalBorrowApy.lt(0) ? ' red' : ' green'}`}>
                                  {item.totalBorrowApy.toFormat(2)}%
                                </p>
                                <p className="item-value-chee-img">
                                  <img src={CheeIcon} alt="Chee-Icon" />
                                  {getBigNumber(item.borrowCheeApy).toFormat(2)}%
                                </p>
                              </div>
                              <div className="table-item">
                                <div className="Mobile-title">Liquidity</div>
                                <p className="item-title">{formatNumber(item.liquidity, true, 2, '$')}</p>
                              </div>
                              <div className="table-item">
                                <div className="Mobile-title">Reserve Factor</div>
                                <p className="item-title">
                                  {`${getBigNumber(item.reserveFactor || 0)
                                    .div(getBigNumber(10).pow(18))
                                    .multipliedBy(100)
                                    .dp(8, 1)
                                    .toString(10)}%`}
                                </p>
                              </div>
                              <div className="table-item">
                                <div className="Mobile-title">Collateral Factor</div>
                                <p className="item-title">
                                  {`${getBigNumber(item.collateralFactor || 0)
                                    .div(getBigNumber(10).pow(18))
                                    .times(100)
                                    .dp(2, 1)
                                    .toString(10)}%`}
                                </p>
                              </div>
                              <div className="table-item">
                                <div className="Mobile-title">Price</div>
                                <p className="item-title">{formatNumber(item.tokenPrice, true, 2, '$')}</p>
                              </div>
                              <div className="table-item">
                                <div className="Mobile-title">Price</div>
                                <p className="item-title">{item.perDay}</p>
                              </div>
                            </div>
                          </div>
                        </TableWrapperMView>
                      </div>
                    ))}
              </div>
            </TableWrapper>
          </ScrollContent>
        </MarketWrapper>
      )}
    </MainLayout>
  );
}

Market.propTypes = {
  history: PropTypes.object,
  settings: PropTypes.object,
};

Market.defaultProps = {
  history: {},
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { getTreasuryBalance } = accountActionCreators;

  return bindActionCreators(
    {
      getTreasuryBalance,
    },
    dispatch
  );
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(Market);
