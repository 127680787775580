import styled from 'styled-components';

const P2PCardWrap = styled.div`
  border: 1px solid #273130;
  border-radius: 4px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  .title {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #c6dbda;
  }
  .desc {
    margin-top: 30px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 150%;
    color: #858c8c;
  }
  .table {
    margin: 30px 0;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    &__item {
      margin-bottom: 12px;
      height: 34px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &--title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #d6dede;
      }
      &--desc {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #ffffff;
        .sym {
          font-family: 'Inter';
          font-style: normal;
          font-size: 14px;
          line-height: 14px;
          color: #ffffff;
          font-weight: 400;
        }
      }
    }
  }
  .chart {
    margin-top: 30px;
    width: 100%;
    height: 81px;
    background: linear-gradient(273.37deg, #2a3131 0%, #132221 100%);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    &__item {
      display: flex;
      align-items: center;
      .info {
        flex: 1;
        margin-left: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &--title {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;
      }
      &--num {
        margin-top: 12px;
        font-family: 'Inter';
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        color: #d6dede;
        .sym {
          margin-left: 5px;
          font-weight: 400;
        }
      }
    }
  }
`;
export { P2PCardWrap };
