import React, { useEffect, useState, useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { Progress, Tooltip, Form } from 'antd';
import arrowRightImg from 'assets/img/arrow-right.png';
import coinImg from 'assets/img/chee-64.png';
import infoImg from 'assets/img/icons/info.svg';
import BigNumber from 'bignumber.js';
import { TabContent, TabContentWrapper, LoadingBgWrapper } from 'components/common/style';
import { CONTRACT_BASE_TOKEN, CONTRACT_CTOKEN_ADDRESS, CONTRACT_TOKEN_ADDRESS } from 'constants/address';
import { accountActionCreators, connectAccount } from 'modules';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import NumberFormat from 'react-number-format';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { sendSupply } from 'utilities/baseConnect';
import { getCTokenContract, getTokenContract, methods } from 'utilities/contractService';
import eventBus from 'utilities/eventBus';
import { LOAD_GLOBAL_DATA } from 'constants/eventCenter';
import { formatNumber, getBigNumber } from 'utilities/number';
import LoadingSpinner from 'components/common/loadingSpinner';

const abortController = new AbortController();

function SupplyTab({ asset, settings, onCancel, setSetting, ui }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isEnabled, setIsEnabled] = useState(false);
  const [amount, setAmount] = useState({ value: getBigNumber() });
  const [assetName, setAssetName] = useState('');
  const [borrowLimit, setBorrowLimit] = useState(getBigNumber(0));
  const [borrowPercent, setBorrowPercent] = useState(getBigNumber(0));
  const [newBorrowLimit, setNewBorrowLimit] = useState(getBigNumber(0));
  const [newBorrowPercent, setNewBorrowPercent] = useState(getBigNumber(0));
  const [showEnabledBtn, setShowEnabledBtn] = useState(false);

  const updateInfo = useCallback(async () => {
    const totalBorrowBalance = getBigNumber(settings.totalBorrowBalance);
    const totalBorrowLimit = getBigNumber(settings.totalBorrowLimit);
    const tokenPrice = getBigNumber(asset.tokenPrice);
    const collateralFactor = getBigNumber(asset.collateralFactor);

    if (tokenPrice && !amount.value.isZero() && !amount.value.isNaN()) {
      const temp = totalBorrowLimit.plus(amount.value.times(tokenPrice).times(collateralFactor));
      setNewBorrowLimit(BigNumber.maximum(temp, 0));
      setNewBorrowPercent(totalBorrowBalance.div(temp).times(100));
      if (totalBorrowLimit.isZero()) {
        setBorrowLimit(getBigNumber(0));
        setBorrowPercent(getBigNumber(0));
      } else {
        setBorrowLimit(totalBorrowLimit);
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    } else if (BigNumber.isBigNumber(totalBorrowLimit)) {
      setBorrowLimit(totalBorrowLimit);
      setNewBorrowLimit(totalBorrowLimit);
      if (totalBorrowLimit.isZero()) {
        setBorrowPercent(getBigNumber(0));
        setNewBorrowPercent(getBigNumber(0));
      } else {
        setBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
        setNewBorrowPercent(totalBorrowBalance.div(totalBorrowLimit).times(100));
      }
    }
  }, [settings.selectedAddress, amount]);

  useEffect(() => {
    setIsEnabled(asset.isEnabled);
  }, [asset.isEnabled]);

  /**
   * Get Allowed amount
   */
  useEffect(() => {
    if (asset.ctokenAddress && settings.selectedAddress) {
      updateInfo();
    }
    return function cleanup() {
      abortController.abort();
    };
  }, [settings.selectedAddress, updateInfo]);

  useEffect(() => {
    setShowEnabledBtn(!asset.isEnabled && asset.id !== CONTRACT_BASE_TOKEN[settings.networkName]);
  }, [asset.isEnabled, asset.id, settings.networkName]);

  const validateAmount = (value) => {
    if (value === '0') {
      return {
        validateStatus: 'error',
        errorMsg: 'Amount is required',
      };
    }
    const temp = getBigNumber(value || 0);
    const isNotAllowed = temp.isGreaterThan(asset.walletBalance);
    if (isNotAllowed) {
      return {
        validateStatus: 'error',
        errorMsg: 'Input amount too large',
      };
    }
    return {
      validateStatus: 'success',
      errorMsg: null,
    };
  };
  const onAmountInput = ({ value }) => {
    setAmount({ ...validateAmount(value), value: getBigNumber(value) });
  };
  /**
   * Approve underlying token
   */
  const onApprove = async () => {
    if (asset.id && settings.selectedAddress && asset.id !== CONTRACT_BASE_TOKEN[settings.networkName]) {
      setIsLoading(true);
      const tokenContract = getTokenContract(asset.id, CONTRACT_TOKEN_ADDRESS[settings.networkName]);
      methods
        .send(
          tokenContract.methods.approve,
          [asset.ctokenAddress, getBigNumber(2).pow(256).minus(1).toString(10)],
          settings.selectedAddress
        )
        .then(() => {
          eventBus.emit(LOAD_GLOBAL_DATA);
          setIsEnabled(true);
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    }
  };

  /**
   * Supply
   */
  const handleSupply = () => {
    const cTokenContract = getCTokenContract(asset.id, CONTRACT_CTOKEN_ADDRESS[settings.networkName]);
    if (asset.id && settings.selectedAddress) {
      setIsLoading(true);
      setSetting({
        pendingInfo: {
          type: 'Supply',
          status: true,
          amount: formatNumber(amount, false, 8),
          symbol: asset.symbol,
        },
      });
      if (asset.id !== CONTRACT_BASE_TOKEN[settings.networkName]) {
        methods
          .send(
            cTokenContract.methods.mint,
            [amount.value.times(getBigNumber(10).pow(settings.decimals[asset.id]?.token)).integerValue().toString(10)],
            settings.selectedAddress
          )
          .then(() => {
            setAmount({ value: getBigNumber(0) });
            setIsLoading(false);
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: '',
              },
            });
            onCancel();
          })
          .catch(() => {
            setIsLoading(false);
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: '',
              },
            });
          });
      } else {
        sendSupply(
          settings.selectedAddress,
          amount.value.times(getBigNumber(10).pow(settings.decimals[asset.id]?.token)).toString(10),
          settings.networkName,
          () => {
            setAmount({ value: getBigNumber(0) });
            setIsLoading(false);
            setSetting({
              pendingInfo: {
                type: '',
                status: false,
                amount: 0,
                symbol: '',
              },
            });
            onCancel();
          }
        );
      }
    }
  };
  /**
   * Max amount
   */
  const handleSetAmountByProportion = (proportion) => {
    setAmount({ value: asset.walletBalance.multipliedBy(proportion) });
  };
  /**
   * Checking asset Value and changing the state value accordingly
   */

  if (assetName !== asset.displayName) {
    setAmount({ value: getBigNumber(0) });
    setAssetName(asset.displayName);
  }

  return (
    <TabContentWrapper>
      {isLoading && (
        <LoadingBgWrapper>
          <LoadingSpinner />
        </LoadingBgWrapper>
      )}
      <Form name="basic" labelCol={{ span: 0 }} wrapperCol={{ span: 24 }} autoComplete="off">
        <TabContent
          blurBg={(showEnabledBtn && !isEnabled) || isLoading}
          className="flex flex-column align-center just-center"
        >
          <div className="tabcontent-title align-center">
            <img className="asset-img" src={asset.img} alt="asset" />
            <span>Supply Amount</span>
          </div>
          <Form.Item
            name="amount"
            rules={[{ required: true, message: 'Please input amount!' }]}
            validateStatus={amount.validateStatus}
            help={amount.errorMsg}
          >
            <div className="flex align-center input-wrapper">
              <NumberFormat
                autoFocus
                value={amount.value.isZero() ? null : amount.value.toString(10)}
                onValueChange={onAmountInput}
                thousandSeparator
                allowNegative={false}
                placeholder="0"
              />
              <span className="coin-name">{asset.displayName}</span>
            </div>
          </Form.Item>
          <div className="flex align-center balance-content">
            <span className="wallet-balance">
              {/* Wallet Balance */}
              <FormattedMessage id="Wallet Balance" />: {formatNumber(asset.walletBalance, false, 5)} {asset.symbol}
            </span>
            <div className="flex align-center">
              <span
                className={`proportion-wrapper ${
                  asset.id === CONTRACT_BASE_TOKEN[settings.networkName] || isEnabled
                    ? 'pointer'
                    : 'pointer--not-allowed'
                }`}
              >
                {[
                  { label: '25%', val: 0.25 },
                  { label: '50%', val: 0.5 },
                  { label: 'MAX', val: 1 },
                ].map((proportion) => (
                  <span
                    key={proportion.val}
                    onClick={() => {
                      if (asset.id === CONTRACT_BASE_TOKEN[settings.networkName] || isEnabled)
                        handleSetAmountByProportion(proportion.val);
                    }}
                  >
                    {proportion.label}
                  </span>
                ))}
              </span>
              <Tooltip placement="top" title={<FormattedMessage id="Message" values={{ name: asset.displayName }} />}>
                <img className="info_img" src={infoImg} alt="infoImg" />
              </Tooltip>
            </div>
          </div>
          {isEnabled && (
            <div className="flex flex-column just-center align-center apy-content">
              <div className="borrow-limit">
                <span>
                  {/* Borrow Limit */}
                  <FormattedMessage id="Borrow Limit" />
                </span>
                {amount.value.isZero() || amount.value.isNaN() ? (
                  <span>{formatNumber(borrowLimit, true, 2, '$')}</span>
                ) : (
                  <div className="flex align-center just-between">
                    <span>{formatNumber(borrowLimit, true, 2, '$')}</span>
                    <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                    <span>{formatNumber(newBorrowLimit, true, 2, '$')}</span>
                  </div>
                )}
              </div>
              <div className="flex align-center just-between borrow-limit-used">
                <span>
                  {/* Borrow Limit Used */}
                  <FormattedMessage id="Borrow Limit Used" />
                </span>
                {amount.value.isZero() || amount.value.isNaN() ? (
                  <span>{formatNumber(borrowPercent, false, 2, '', '%')}</span>
                ) : (
                  <div className="flex align-center just-between">
                    <span>{formatNumber(borrowPercent, false, 2, '', '%')}</span>
                    <img className="arrow-right-img" src={arrowRightImg} alt="arrow" />
                    <span>{formatNumber(newBorrowPercent, false, 2, '', '%')}</span>
                  </div>
                )}
              </div>
              <Progress
                percent={newBorrowPercent.toNumber()}
                className="apy-progress"
                strokeColor="linear-gradient(270deg, #498885 0%, #BDF4F1 100%)"
                trailColor={`${ui.theme === 'dark' ? '#000000' : '#FFFFFF'}`}
                strokeWidth={7}
                showInfo={false}
              />
            </div>
          )}
          <Button
            className="button enable"
            disabled={
              isLoading ||
              amount.value.isNaN() ||
              amount.value.isZero() ||
              amount.value.isGreaterThan(asset.walletBalance)
            }
            onClick={handleSupply}
          >
            <FormattedMessage id="Supply" />
          </Button>
          <div className="flex flex-wrap just-between">
            {[
              { img: asset.img, label: 'Supply APY', val: `${formatNumber(asset.supplyApy, false, 2, '', '%')}` },
              { img: coinImg, label: 'CHEE APY', val: `${formatNumber(asset.cheeSupplyApy, false, 2, '', '%')}` },
            ].map((item) => (
              <div key={item.label} className="trans-proportion">
                <img className="asset-img" src={item.img} alt="asset" />
                <span>
                  {/* Supply APY */}
                  <FormattedMessage id={item.label} />
                </span>
                <span>{item.val}</span>
              </div>
            ))}
          </div>
        </TabContent>
        {showEnabledBtn && !isEnabled ? (
          <div className="flex flex-column approve-enable">
            <div className="flex flex-center align-center just-center title">
              <img src={asset.img} alt="coinImg" />
              {asset.displayName}
            </div>
            <Button className="button enable" disabled={isLoading} onClick={onApprove}>
              <FormattedMessage id="Enable" />
            </Button>
          </div>
        ) : null}
      </Form>
    </TabContentWrapper>
  );
}

SupplyTab.propTypes = {
  asset: PropTypes.object,
  settings: PropTypes.object,
  onCancel: PropTypes.func,
  setSetting: PropTypes.func.isRequired,
};

SupplyTab.defaultProps = {
  asset: {},
  settings: {},
  onCancel: () => {},
};

const mapStateToProps = ({ account, ui }) => ({
  settings: account.setting,
  ui,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;

  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(connectAccount(mapStateToProps, mapDispatchToProps))(SupplyTab);
