import styled from 'styled-components';

const FaucetWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  height: 100%;
  flex: 1;
  padding: 20px;
  input {
    width: 100%;
    height: 42px;
  }

  div {
    &::-webkit-scrollbar {
      /* width: 10px; */
    }
    /* Handle */
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 12px;
      -moz-border-radius: 12px;
      border-radius: 12px;
      background-color: ${(props) => props.theme['--color-scrollbar']};
    }
    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      /* background-color: #f7c408cc; */
    }

    ::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  .header {
    font-size: 36px;
    font-weight: 600;
    color: ${(props) => props.theme['--color-text-main']};
    margin-top: 20px;
    margin-bottom: 30px;
    text-align: center;

    @media only screen and (max-width: 768px) {
      font-size: 28px;
      margin-top: 0px;
    }
  }

  .bottom {
    color: ${(props) => props.theme['--color-text-main']};
    padding: 30px 0;

    .title {
      font-size: 24px;
      font-weight: 600;
    }

    .description {
      margin-top: 10px;
      font-size: 16px;
      font-weight: normal;
      text-align: center;
    }
  }

  .button-section {
    margin: 20px 0;
  }

  .empty-menu {
    opacity: 0;

    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 20px;
  .button {
    width: 400px;
    height: 40px;
    border-radius: 5px;
    background: #008a83;
    .MuiButton-label {
      font-size: 15px;
      font-weight: 500;
      color: ${(props) => props.theme['--color-text-main']};
      text-transform: capitalize;

      @media only screen and (max-width: 1440px) {
        font-size: 12px;
      }
    }
  }
`;

export { FaucetWrapper, ButtonWrapper };
