/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { compose } from 'recompose';
import { Row as RowSpe, PageTitle } from 'components/common/style';
import { Row, Col } from 'antd';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import MainLayout from 'components/layout/wrap';
import {
  getVeNFTContract,
  getVeTokenContract,
  methods,
  getLendContract,
  getCheeTokenContract,
  web3Instance,
} from 'utilities/contractService';
import {
  VEPOOLS_ADDRESS_VENFT,
  VEPOOLS_ADDRESS_LEND,
  VEPOOLS_ADDRESS_VETOKEN,
  VEPOOLS_ADDRESS_CHEETOKEN,
  VETOKENASSET,
} from 'constants/address';
import LoadingSpinner from 'components/common/loadingSpinner';
import supplyImg from 'assets/img/vePools-supply.svg';
import cheeTokensImg from 'assets/img/chee-tokens.svg';
import yellowArrowImg from 'assets/img/yellow-arrow.svg';
import suppliedLabel from 'assets/img/supplied-label.svg';
import btcImg from 'assets/img/coins/btc.png';
import testImg from 'assets/img/coins/token_test.png';
import izi from 'assets/img/coins/izi.png';
import avatarImg from 'assets/img/chee-64.png';
import moment from 'moment';
import { networkList } from 'constants/network';
import { formatNumber, getBigNumber } from 'utilities/number';
import MintEnableModal from './components/mintEnableModal';
import MintModal from './components/mintModal';
import RedeemModal from './components/redeemModal';
import { MarketWrapper, TableWrapper, TitleWrapper, ScrollContent, FontHint, SpinnerWrapper } from './style';

const initList = {
  ETH: [{ Asset: 'cheeiZi', name: 'cheeiZi - iZi', Balance: '--', img: avatarImg }],
  CELO: [{ Asset: 'cheeTest', name: 'cheeTest - Test', Balance: '--', img: avatarImg }],
};
const hintMessage = {
  ETH: (
    <div>
      You do not own any veiZis. Please visit <a>https://izumi.finance</a> to get some.
    </div>
  ),
  CELO: <div>You do not own any veTests. Please use our Faucet to get some.</div>,
};
function VePools({ settings, setSetting }) {
  const address = settings.selectedAddress;
  const currentNet = settings.networkName;
  const [cheeTokensList, setCheeTokensList] = useState(initList);
  const [currentVeNFT, setCurrentVeNFT] = useState({});
  const [canShowPage, setCanShowPage] = useState(false);
  const [isOpenMintEnableModal, setIsOpenMintEnableModal] = useState(false);
  const [isOpenMintModal, setIsOpenMintModal] = useState(false);
  const [isOpenRedeemModal, setIsOpenRedeemModal] = useState(false);
  const [currentCheeTokenEnabled, setCurrentCheeEabled] = useState(false);
  const [currentVeTokenEnabled, setCurrentVeTokenEnabled] = useState(false);
  const [mintEnableProgressShow, setMintEnableProgressShow] = useState(false);
  const [mintProgressShow, setMintProgressShow] = useState(false);
  const [redeemEnableProgressShow, setRedeemEnableProgressShow] = useState(false);

  const [veNFTContract, setVeNFTContract] = useState(null);
  const [cheeTokenContract, setCheeTokenContract] = useState(null);
  const [veTokenContract, setVeTokenContract] = useState(null);
  const [lendContract, setLendContract] = useState(null);

  const [currentMintEnabled, setCurrentMintEnabled] = useState(false);
  const [currentIsSupplied, setCurrentIsSupplied] = useState(false);
  const [currentMintIndex, setCurrentMintIndex] = useState(-1);
  const getTotalInfo = async () => {
    if (!settings.selectedAddress || !veNFTContract) {
      return;
    }
    setCanShowPage(true);
    const cheeTokenBalanceInfos = await methods.call(cheeTokenContract.methods.balanceOf, [address]);
    const veTokenBalanceInfos = await methods.call(veTokenContract.methods.balanceOf, [
      VEPOOLS_ADDRESS_VENFT[settings.networkName],
    ]);

    let initVeNFTList = [];
    const nftNum = await methods.call(veNFTContract.methods.balanceOf, [address]);
    let nftIndex = [];
    for (let i = 0; i <= nftNum - 1; i++) {
      nftIndex.push(i);
    }
    await Promise.all(
      nftIndex.map(async (itemIndex) => {
        const currentId = await methods.call(veNFTContract.methods.tokenOfOwnerByIndex, [address, itemIndex]);
        initVeNFTList.push(currentId);
      })
    );

    const idLists = await methods.call(lendContract.methods.getLentNFTIds, [
      VEPOOLS_ADDRESS_VENFT[settings.networkName],
      address,
    ]);
    const uniqIds = [...new Set(idLists)];
    initVeNFTList = [...new Set(initVeNFTList.concat(uniqIds))];

    let result = [];
    await Promise.all(
      initVeNFTList.map(async (ele) => {
        let res = {};
        // supplied
        const ownerOfAddress = await methods.call(veNFTContract.methods.ownerOf, [ele]);
        res.isSupplied = ownerOfAddress === VEPOOLS_ADDRESS_LEND[settings.networkName] ? 1 : 0;

        const veNFTBalanceInfos = await methods.call(veNFTContract.methods.nftLocked, [ele]);
        res.isFilter = Number(veNFTBalanceInfos.amount) === 0;
        res.Balance = formatNumber(
          getBigNumber(veNFTBalanceInfos.amount).div(getBigNumber(10).pow(18)).dp(4, 1).toString(10)
        );
        res.date = moment(
          Number(veNFTBalanceInfos.end.length === 10 ? veNFTBalanceInfos.end * 1000 : veNFTBalanceInfos.end)
        ).format('MM/DD/YYYY HH:mm');
        res.ID = ele;

        res.Asset = VETOKENASSET[settings.networkName];
        res.img = settings.networkName === 'ETH' ? izi : testImg;
        result.push(res);

        // getMaxBorrowedCheeizi
        const maxCheeTokenAmount = await methods.call(lendContract.methods.getMaxCheeTokenAmount, [
          VEPOOLS_ADDRESS_VENFT[settings.networkName],
          ele,
        ]);
        res.maxCheeTokenAmount = formatNumber(getBigNumber(maxCheeTokenAmount).div(getBigNumber(10).pow(18)), true, 4);
        // getMaxBlock for days
        const maxDurationBlocks = await methods.call(lendContract.methods.getMaxDurationBlocks, []);
        res.maxDays = (maxDurationBlocks * 3) / 60 / 60 / 24;
        // getGraceHours
        const getGraceTimeBlocks = await methods.call(lendContract.methods.getGraceTimeBlocks, []);
        res.graceHours = (getGraceTimeBlocks * 3) / 60 / 60;
        // getIntersPerDays
        const interestPerBlock = await methods.call(lendContract.methods.getInterestRatePerBlock, []);
        res.interestPerBlock = interestPerBlock;
        // getFeeRate
        const feeRate = await methods.call(lendContract.methods.getFeeRate, []);
        res.feeRate = feeRate;
        // if redeem enabled
        const allowanceVeToken = await methods.call(veTokenContract.methods.allowance, [
          address,
          VEPOOLS_ADDRESS_LEND[settings.networkName],
        ]);
        const isVeTokenEnabled = !getBigNumber(allowanceVeToken).isZero();
        res.isVeTokenEnabled = isVeTokenEnabled;
        // if redeem enabled
        const allowanceCheeToken = await methods.call(cheeTokenContract.methods.allowance, [
          address,
          VEPOOLS_ADDRESS_LEND[settings.networkName],
        ]);
        const isCheeTokenEnabled = !getBigNumber(allowanceCheeToken).isZero();
        res.isCheeTokenEnabled = isCheeTokenEnabled;
        // ismintable
        // if venft mint enabled
        const approveAddress = await methods.call(veNFTContract.methods.getApproved, [ele]);
        const isMintEnabled = approveAddress === VEPOOLS_ADDRESS_LEND[settings.networkName];
        res.isMintEnabled = isMintEnabled;
        // getIZInum
        const getVeTokenAmount = await methods.call(lendContract.methods.getAccruedInterestAmount, [
          VEPOOLS_ADDRESS_VENFT[settings.networkName],
          ele,
        ]);
        const getVeTokenBalance = await methods.call(veTokenContract.methods.balanceOf, [address]);
        const veTokenBalance = getBigNumber(getVeTokenBalance).div(getBigNumber(10).pow(18));
        const num = getBigNumber(getVeTokenAmount).div(getBigNumber(10).pow(18));
        res.veTokenNum = num;
        res.veTokenBalance = veTokenBalance;
        // getCheeNum
        const getCheeMount = await methods.call(lendContract.methods.getCheeTokenAmount, [
          VEPOOLS_ADDRESS_VENFT[settings.networkName],
          ele,
        ]);
        const getCheeBalance = await methods.call(cheeTokenContract.methods.balanceOf, [address]);
        const cheeBalance = formatNumber(getBigNumber(getCheeBalance).div(getBigNumber(10).pow(18)), true, 4);
        const cheeNum = formatNumber(getBigNumber(getCheeMount).div(getBigNumber(10).pow(18)), true, 4);
        res.cheeNum = cheeNum;
        res.cheeBalance = cheeBalance;
      })
    );

    if (cheeTokensList[settings.networkName]) {
      cheeTokensList[settings.networkName][0].Balance = formatNumber(
        getBigNumber(cheeTokenBalanceInfos).div(getBigNumber(10).pow(18)),
        true,
        4
      );
    }
    const list = {};
    list[settings.networkName] = result;
    setSetting({
      vepoolsList: list,
    });
    setCheeTokensList(cheeTokensList);
    setCanShowPage(false);
  };

  const handleVeTokenApprove = async () => {
    setMintEnableProgressShow(true);
    methods
      .send(veNFTContract.methods.approve, [VEPOOLS_ADDRESS_LEND[settings.networkName], currentVeNFT.ID], address)
      .then(() => {
        setIsOpenMintEnableModal(false);
        // approve之后 要mint弹框
        setIsOpenMintModal(true);
        setMintEnableProgressShow(false);
        setCurrentMintEnabled(true);
        setCurrentMintIndex(currentVeNFT.ID);
      })
      .catch(() => {
        setIsOpenMintEnableModal(false);
        setMintEnableProgressShow(false);
      });
  };

  const handleVeTokenAndVeNFTApprove = async (params) => {
    setRedeemEnableProgressShow(true);
    methods
      .send(
        params === 0 ? cheeTokenContract.methods.approve : veTokenContract.methods.approve,
        [VEPOOLS_ADDRESS_LEND[settings.networkName], getBigNumber(2).pow(256).minus(1).toString(10)],
        address
      )
      .then(() => {
        if (params === 0) {
          setCurrentCheeEabled(true);
          setRedeemEnableProgressShow(false);
        } else {
          setCurrentVeTokenEnabled(true);
          setRedeemEnableProgressShow(false);
        }
      })
      .catch(() => {
        // setCurrentCheeEabled(false);
        setRedeemEnableProgressShow(false);
      });
  };

  const onMint = async ({ amount, lockedTime }) => {
    setMintProgressShow(true);
    methods
      .send(
        lendContract.methods.mint,
        [
          VEPOOLS_ADDRESS_VENFT[settings.networkName],
          currentVeNFT.ID,
          getBigNumber(amount).times(getBigNumber(10).pow(18)).dp(0).toString(10),
          lockedTime,
        ],
        address
      )
      .then((res) => {
        setIsOpenMintModal(false);
        setMintProgressShow(false);
        // refresh data
        getTotalInfo();
      })
      .catch((err) => {
        setIsOpenMintModal(false);
        setMintProgressShow(false);
      });
  };

  const onRedeem = async () => {
    setRedeemEnableProgressShow(true);
    await methods
      .send(lendContract.methods.redeem, [VEPOOLS_ADDRESS_VENFT[settings.networkName], currentVeNFT.ID], address)
      .then(() => {
        setIsOpenRedeemModal(false);
        setRedeemEnableProgressShow(false);
        setCurrentIsSupplied(false);
        setCurrentMintIndex(-1);
        // refresh data
        getTotalInfo();
      })
      .catch((err) => {
        setIsOpenRedeemModal(false);
        setRedeemEnableProgressShow(false);
      });
  };

  const handleData = (data) => {
    data.sort((a, b) => {
      if (a.isSupplied !== b.isSupplied) {
        return Number(b.isSupplied) - Number(a.isSupplied);
      }
      return Number(a.ID) - Number(b.ID);
    });
    return data.filter((item) => !item.isFilter);
  };

  const handleItem = async (market) => {
    setCurrentVeNFT(market);
    if (market.isSupplied || currentIsSupplied) {
      setIsOpenRedeemModal(true);
    } else if (market.isMintEnabled || Number(currentMintIndex) === Number(market.ID)) {
      setIsOpenMintModal(true);
    } else {
      setIsOpenMintEnableModal(true);
    }
  };

  useEffect(() => {
    const networkItem = networkList.find((item) => item.shortName === settings.networkName);
    if (networkItem && web3Instance.value && !networkItem?.supportNFTBtnDisabled) {
      setVeNFTContract(getVeNFTContract(VEPOOLS_ADDRESS_VENFT[settings.networkName], settings.networkName));
      setCheeTokenContract(getCheeTokenContract(VEPOOLS_ADDRESS_CHEETOKEN[settings.networkName], settings.networkName));
      setVeTokenContract(getVeTokenContract(VEPOOLS_ADDRESS_VETOKEN[settings.networkName], settings.networkName));
      setLendContract(getLendContract(VEPOOLS_ADDRESS_LEND[settings.networkName], settings.networkName));
      getTotalInfo();
    }
  }, [settings.networkName, settings.selectedAddress, web3Instance.value]);

  useEffect(() => {
    const networkItem = networkList.find((item) => item.shortName === settings.networkName);
    if (veNFTContract && !settings.vepoolsList?.[settings.networkName]?.length && !networkItem?.supportNFTBtnDisabled) {
      getTotalInfo();
    }
  }, [veNFTContract]);

  return (
    <MainLayout title={<FormattedMessage id="vePools" />}>
      <>
        <MarketWrapper>
          <div className="title">vePools</div>
          <RowSpe>
            <PageTitle>
              <FormattedMessage id="vePools" />
            </PageTitle>
          </RowSpe>
          <TitleWrapper>
            {/* ************  左侧Supply区域开始 ************ */}
            <div className="total-items total-items-left">
              <div className="total-item  flex align-center">
                <img src={supplyImg} alt="supplyIcon" />
                <span className="text">Supply</span>
              </div>
              <ScrollContent>
                <TableWrapper>
                  <Row className="table_header">
                    <Col span={6}>
                      <span>
                        <FormattedMessage id="Asset" />
                      </span>
                    </Col>
                    <Col span={6}>
                      <span>
                        <FormattedMessage id="ID" />
                      </span>
                    </Col>
                    <Col span={6}>
                      <span>
                        <FormattedMessage id="Balance" />{' '}
                      </span>
                    </Col>
                    <Col span={6} style={{ textAlign: 'right' }}>
                      <span>
                        <FormattedMessage id="Locked Until" />
                      </span>
                    </Col>
                  </Row>
                  {canShowPage ? (
                    <SpinnerWrapper>
                      <LoadingSpinner />
                    </SpinnerWrapper>
                  ) : (
                    <div className="table_content">
                      {settings.vepoolsList && handleData(settings.vepoolsList?.[settings.networkName])?.length ? (
                        handleData(settings.vepoolsList?.[settings.networkName]).map((market) => {
                          return (
                            <div
                              key={market.ID}
                              onClick={(ev) => {
                                handleItem(market);
                              }}
                            >
                              <Row className="table_content_DesktopView flex align-center">
                                {market.isSupplied ? (
                                  <img src={suppliedLabel} alt="suppliedLabel" className="supplied-label" />
                                ) : null}
                                <Col span={6}>
                                  <div className="table-item-1">
                                    <img src={market.img} alt="" />
                                    <p className="asset">{market.Asset}</p>
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div className="table-item">
                                    <p className="">{market.ID}</p>
                                  </div>
                                </Col>

                                <Col span={6}>
                                  <div className="table-item">
                                    <p className="bold">{market.Balance}</p>
                                  </div>
                                </Col>
                                <Col span={6}>
                                  <div className="table-item" style={{ right: 0 }}>
                                    <p className="datetime">{market.date}</p>
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          );
                        })
                      ) : (
                        <FontHint>{hintMessage[settings.networkName]}</FontHint>
                      )}
                    </div>
                  )}
                </TableWrapper>
              </ScrollContent>
            </div>
            {/* ************  左侧Supply区域结束 ************ */}

            {/* ************  右侧cheeTokens区域开始 ************ */}
            <div className="total-items total-items-right">
              <div className="total-item flex align-center">
                <img src={cheeTokensImg} alt="cheeTokensImg" />
                <span className="text">cheeTokens</span>
              </div>
              <ScrollContent>
                <TableWrapper>
                  <Row className="table_header">
                    <Col span={7}>
                      <span>
                        <FormattedMessage id="Asset" />
                      </span>
                    </Col>
                    <Col span={7}>
                      <span>
                        <FormattedMessage id="Balance" />
                      </span>
                    </Col>
                    <Col span={7}>
                      <span>
                        <FormattedMessage id="Pool Name" />{' '}
                      </span>
                    </Col>
                  </Row>

                  <div className="table_content bg-right">
                    {cheeTokensList[settings.networkName]?.map((market, i) => {
                      return (
                        <div key={market.name}>
                          <Row className="table_content_DesktopView_noHover flex align-center">
                            <Col span={7}>
                              <div className="table-item-1">
                                <img src={market.img} alt="" />
                                {market.Asset}
                              </div>
                            </Col>
                            <Col span={7}>
                              <div className="table-item">
                                <p className="bold">{market.Balance}</p>
                              </div>
                            </Col>
                            <Col span={7}>
                              <div className="table-item">
                                <p className="">{market.name}</p>
                              </div>
                            </Col>
                            <Col span={3}>
                              <div className="table-item" style={{ right: 0 }}>
                                <img className="arrow" src={yellowArrowImg} alt="" />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      );
                    })}
                  </div>
                </TableWrapper>
              </ScrollContent>
            </div>
            {/* ************  右侧cheeTokens区域结束 ************ */}
          </TitleWrapper>
        </MarketWrapper>
      </>

      <MintEnableModal
        visible={isOpenMintEnableModal}
        selectedRow={currentVeNFT}
        mintEnableProgressShow={mintEnableProgressShow}
        onApprove={handleVeTokenApprove}
        onCancel={() => setIsOpenMintEnableModal(false)}
      />
      <MintModal
        visible={isOpenMintModal}
        selectedRow={currentVeNFT}
        mintProgressShow={mintProgressShow}
        cheeTokenBalanceOf={cheeTokensList[settings.networkName] && cheeTokensList[settings.networkName][0]?.Balance}
        networkName={settings.networkName}
        onMint={onMint}
        onCancel={() => setIsOpenMintModal(false)}
      />
      <RedeemModal
        visible={isOpenRedeemModal}
        selectedRow={currentVeNFT}
        redeemEnableProgressShow={redeemEnableProgressShow}
        currentCheeTokenEnabled={currentVeNFT.isCheeTokenEnabled || currentCheeTokenEnabled}
        currentVeTokenEnabled={currentVeNFT.isVeTokenEnabled || currentVeTokenEnabled}
        networkName={settings.networkName}
        onApprove={handleVeTokenAndVeNFTApprove}
        onRedeem={onRedeem}
        onCancel={() => setIsOpenRedeemModal(false)}
      />
    </MainLayout>
  );
}

VePools.defaultProps = {
  history: {},
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;
  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(VePools);
