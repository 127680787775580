import styled from 'styled-components';

const MarketWrapper = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #ffff;

  .total-items {
    height: 100px;
    background: linear-gradient(91.48deg, #03a79f 0%, #007771 100%);
    /* background: ${(props) =>
      `url(${props.imgUrl})`} no-repeat, linear-gradient(283.46deg, #c67700 4.5%, #e2cc00 100%); */
    background-size: contain;
    border-radius: 4px;
    backdrop-filter: blur(150px);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    .total-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      gap: 10px;
      @media screen and (max-width: 768px) {
        gap: 5px;
        width: 50%;
        flex-grow: 0;
        margin-bottom: 20px;
      }
      .prop {
        font-weight: 400;
        font-size: 12px;
        @media screen and (max-width: 768px) {
          color: #c7e9d6;
        }
      }
      .value {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: row;
        gap: 2px;
        font-weight: 600;
        font-size: 18px;
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
        .dollar {
          font-size: 10px;
          font-weight: 400;
        }
      }
    }
  }
  .left {
    width: 60%;
  }
  .right {
    width: 40%;
    background: linear-gradient(273.37deg, #2a3131 0%, #132221 100%);
    background-size: contain;
  }
  @media (max-width: 1124px) {
    flex-direction: column;
    .left {
      width: 100%;
    }
    .right {
      width: 100%;
    }
  }
  @media only screen and (max-width: 768px) {
    align-items: flex-start;
    justify-content: center;
    .left {
      padding-left: 20px;
      height: fit-content;
      background-position: bottom right;
      background-size: cover;
    }
    .right {
      padding-left: 20px;
      height: fit-content;
      background-position: bottom right;
      background-size: cover;
    }
    .total-items {
      padding: 20px 20px 0 20px;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  height: fit-content;
  background: ${(props) => props.theme['--color-bg-table']};
  border-radius: 4px;
  border: 1px solid #272929;
  /* @media (max-width: 1124px) {
    background: none;
  } */
  padding: 0 30px;
  @media only screen and (max-width: 768px) {
    width: 1530px;
  }
  .table_header {
    color: ${(props) => props.theme['--color-text-secondary']};
    border-bottom: 1px solid #272929;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    padding: 30px 15px 0;
    /* @media (max-width: 1124px) {
      display: none;
    } */

    span {
      &:hover {
        opacity: 0.7;
      }
    }
  }
  .table_content_DesktopView {
    padding: 20px 30px 20px;
    color: ${(props) => props.theme['--color-text-top-light']};
    border-bottom: 1px solid #272929;
    cursor: pointer;
    /* @media (max-width: 1124px) {
      display: none;
    } */
    &:hover {
      box-sizing: border-box;
      background: ${(props) => props.theme['--color-connect-btn-hover-row']};
    }
    img {
      height: 20px;
      width: 20px;
    }
    .table-item-1 {
      display: flex;
      flex-direction: row;
      gap: 10px;
      &:hover {
        box-sizing: border-box;
        background: ${(props) => props.theme['--color-connect-btn-hover-row']};
      }
      .item-title {
        font-weight: 400;
        font-size: 14px;
      }
    }
    .table-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
      word-break: break-all;

      .item-title {
        font-weight: 700;
        font-size: 14px;
        &.green {
          color: #229558;
        }

        &.red {
          color: #f9053e;
        }
      }
      .item-value {
        font-weight: 400;
        font-size: 12px;
        color: ${(props) => props.theme['--color-text-secondary']};
      }
      .item-value-chee-img {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        font-weight: 400;
        font-size: 12px;
        color: ${(props) => props.theme['--color-text-secondary']};
        img {
          width: 12px;
          height: 12px;
        }
      }
    }
  }
`;
const TableWrapperMView = styled.div`
  .table_content_M_View {
    display: none;
    /* @media (max-width: 1124px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      margin: 10px;
      border-radius: 4px;
      width: 98%;
      height: fit-content;
      background: ${(props) => props.theme['--color-bg-table']};
      @media (max-width: 860px) {
        flex-direction: column;
      }

      .item-value-chee-img-icon {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 16px;
        color: ${(props) => props.theme['--color-text-top-light']};
        @media (max-width: 860px) {
          width: 100%;
          padding: 16px;
        }
        .table-item-1 {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
      .table-items {
        width: 50%;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        color: ${(props) => props.theme['--color-text-top-light']};
        @media (max-width: 860px) {
          width: 100%;
          padding-bottom: 20px;
        }
        @media (max-width: 460px) {
          grid-template-columns: 1fr 1fr;
        }

        .table-item {
          display: flex;
          flex-direction: column;
          padding-left: 20px;
          gap: 2px;
          .Mobile-title {
            font-weight: 800;
            font-size: 16px;
            padding: 2px;
            padding-left: 0px;
          }
          .item-title {
            font-weight: 700;
            font-size: 14px;
            &.green {
              color: #229558;
            }

            &.red {
              color: #f9053e;
            }
          }
          .item-value {
            font-weight: 400;
            font-size: 12px;
            color: ${(props) => props.theme['--color-text-secondary']};
          }
        }
        .item-value-chee-img {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          font-weight: 400;
          font-size: 12px;
          color: ${(props) => props.theme['--color-text-secondary']};
          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    } */
  }
`;

const ScrollContent = styled.div`
  width: 100%;
  @media only screen and (max-width: 768px) {
    overflow-x: scroll;
    .table_content {
      overflow: hidden;
      overflow-y: scroll;
      max-height: 350px;
    }
    .ant-row {
      display: flex;
    }
    .ant-col {
      width: 150px;
    }
  }
`;
export { MarketWrapper, TableWrapper, TitleWrapper, TableWrapperMView, ScrollContent };
