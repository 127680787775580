import styled from 'styled-components';

const MarketWrapper = styled.div`
  width: 100%;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  position: relative;
  margin-top: 88px;

  @media screen and (max-width: 768px) {
    padding: 0 13px;
    margin-top: 0px;
  }

  .title {
    @media screen and (max-width: 768px) {
      display: none;
    }
    font-weight: 600;
    font-size: 32px;
    line-height: 32px;
    color: ${(props) => props.theme['--color-pools-text']};
    margin: 0 auto 66px;
    margin-left: 0;
    position: absolute;
    left: 0;
    top: -90px;
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  color: #ffff;
  @media only screen and (max-width: 768px) {
    width: 100%;
    gap: 10px;
    color: #ffff;
    display:block;
  }
  .total-items-left{
      width:57.5%;
      margin-right:40px;
      @media only screen and (max-width: 768px) {
        width:100%;
        margin-right:0;
      }

  }
  .total-items-right{
    width:42.5%;
    @media only screen and (max-width: 768px) {
      width:100%;
    }
  }
  .total-items {

    .total-item {
      padding-bottom: 20px;
      border-bottom: 1px solid ${(props) => props.theme['--color-pools-line']};
      img {
        display: inline-block;
        width: 32px;
        height: 32px;
        margin-right: 10px;
        @media only screen and (max-width: 768px) {
          width: 16px;
          height: 16px;
        }
      }
      .text {
        font-size: 32px;
        font-weight: 600;
        color: ${(props) => props.theme['--color-pools-text']};
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }
    }
  }
  }
`;
const TableWrapper = styled.div`
  width: 100%;
  height: fit-content;

  border-radius: 4px;
  /* @media (max-width: 1124px) {
    background: none;
  } */
  @media only screen and (max-width: 768px) {
    width: 1530px;
    width: 100%;
  }
  .table_header {
    color: ${(props) => props.theme['--color-pools-table-header-text']};
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    padding: 20px 30px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
  }

  .table_content_DesktopView {
    height: 80px;
    padding: 20px 30px 20px;
    color: ${(props) => props.theme['--color-pools-table-text']};
    background: ${(props) => props.theme['--color-pools-bg-table']};
    margin-bottom: 15px;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      padding: 10px 15px 10px;
      height: 50px;
      line-height: 50px;
      text-align: center;
    }
    &:hover {
      background: ${(props) => props.theme['--color-connect-btn-hover-borderImage']};
      background-repeat: no-repeat;
      color: ${(props) => props.theme['--color-text-top-light']};
      &::after {
        position: absolute;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        border-radius: 6px;
        top: 2px;
        left: 2px;
        content: '';
        z-index: 0;
        background: ${(props) => props.theme['--color-connect-btn-hover-background']};
      }
    }
    .supplied-label {
      display: inline-block;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 10;
      @media (max-width: 860px) {
        width: 45px;
      }
    }
    .table-item-1 {
      display: flex;
      flex-direction: row;
      gap: 10px;
      // line-height: 24px;
      // position: absolute;
      // top: -12px;
      // z-index: 100;
      .asset {
        z-index: 100;
      }
      @media only screen and (max-width: 768px) {
        display: flex;
        flex-direction: row;
        gap: 10px;
        line-height: 50px;
        // position: absolute;
        z-index: 10;
      }
      img {
        height: 24px;
        width: 24px;
        gap: 10px;
        z-index: 100;
        @media only screen and (max-width: 768px) {
          position: relative;
          height: 12px;
          width: 12px;
          // gap: 5px;
          margin-top: 18px;
        }
      }
    }
    .table-item {
      gap: 10px;
      position: absolute;
      top: -12px;
      z-index: 10;

      .bold {
        font-weight: 700;
      }
      .datetime {
        color: ${(props) => props.theme['--color-pools-datetime']};
      }
    }
  }
  .table_content_DesktopView_noHover {
    height: 94px;
    padding: 20px 30px 20px;
    color: #ffffff;
    border-radius: 4px;
    position: relative;
    @media only screen and (max-width: 768px) {
      padding: 10px 15px 10px;
      height: 50px;
    }
    img {
      height: 24px;
      width: 24px;
      @media only screen and (max-width: 768px) {
        height: 14px;
        width: 14px;
      }
    }
    .table-item-1 {
      display: flex;
      flex-direction: row;
      gap: 10px;
      line-height: 24px;
      // position: absolute;
      // top: -12px;
      z-index: 10;
      img {
        @media only screen and (max-width: 768px) {
          position: relative;
          height: 12px;
          width: 12px;
          margin-top: 7px;
        }
      }
    }
    .table-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
      // position: absolute;
      // top: -12px;
      z-index: 10;

      .bold {
        font-weight: 700;
      }
      .arrow {
        cursor: pointer;
      }
    }
  }
`;
const TableWrapperMView = styled.div`
  .table_content_M_View {
    display: none;
    /* @media (max-width: 1124px) {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 16px;
      margin: 10px;
      border-radius: 4px;
      width: 98%;
      height: fit-content;
      background: ${(props) => props.theme['--color-bg-table']};
      @media (max-width: 860px) {
        flex-direction: column;
      }

      .item-value-chee-img-icon {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 800;
        font-size: 16px;
        color: ${(props) => props.theme['--color-text-top-light']};
        @media (max-width: 860px) {
          width: 100%;
          padding: 16px;
        }
        .table-item-1 {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
        img {
          width: 25px;
          height: 25px;
        }
      }
      .table-items {
        width: 50%;
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 16px;
        align-items: flex-start;
        justify-content: center;
        font-weight: 700;
        font-size: 14px;
        color: ${(props) => props.theme['--color-text-top-light']};
        @media (max-width: 860px) {
          width: 100%;
          padding-bottom: 20px;
        }
        @media (max-width: 460px) {
          grid-template-columns: 1fr 1fr;
        }

        .table-item {
          display: flex;
          flex-direction: column;
          padding-left: 20px;
          gap: 2px;
          .Mobile-title {
            font-weight: 800;
            font-size: 16px;
            padding: 2px;
            padding-left: 0px;
          }
          .item-title {
            font-weight: 700;
            font-size: 14px;
            &.green {
              color: #229558;
            }

            &.red {
              color: #f9053e;
            }
          }
          .item-value {
            font-weight: 400;
            font-size: 12px;
            color: ${(props) => props.theme['--color-text-secondary']};
          }
        }
        .item-value-chee-img {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 4px;
          font-weight: 400;
          font-size: 12px;
          color: ${(props) => props.theme['--color-text-secondary']};
          img {
            width: 12px;
            height: 12px;
          }
        }
      }
    } */
  }
`;

const ScrollContent = styled.div`
  width: 100%;
  .bg-right {
    background: linear-gradient(277deg, #ff0000 -20%, #0047ff 69%);
    box-shadow: inset -20px -10px 100px 0px rgb(255 158 70 / 85%);
    border-radius: 4px;
    @media only screen and (max-width: 768px) {
      background: #0d1915;
      box-shadow: none;
      border-radius: 4px;
    }
  }
  @media only screen and (max-width: 768px) {
    overflow-x: scroll;
    .table_content {
      overflow: hidden;
      overflow-y: scroll;
      max-height: 350px;
    }

    .ant-row {
      display: flex;
    }
    .ant-col {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

const FontHint = styled.div`
  // position: absolute;
  // top: 50%;
  // transform: translateY(-50%);

  text-align: center;
  margin: 0 auto;
  color: ${(props) => props.theme['--color-pools-text']};
  font-size: 20px;
  margin-top: 30%;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-top: 20px !important;
  }
`;

const SpinnerWrapper = styled.div`
  height: 40vh;
  width: 100%;
`;
export { MarketWrapper, TableWrapper, TitleWrapper, TableWrapperMView, ScrollContent, FontHint, SpinnerWrapper };
