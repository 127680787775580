import styled, { keyframes } from 'styled-components';
import { dim } from 'modules/ui/dim';

const ModalContent = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme['--color-bg-primary']};

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    width: 24px;
    height: 24px;
    box-sizing: border-box;
    padding: 4px 4px 8px 10px;
    background: #243f3d;
    border-radius: 0 0 0 24px;
    overflow: visible;
    @media only screen and (max-width: 768px) {
      right: 5px;
      top: 5px;
      width: 12px;
      height: 12px;
      background: none;
      padding: 0;
    }
    &:hover {
      /* filter: invert(100%); */
    }
  }
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    .header-content {
      height: 60px !important;
    }
    /* .close-btn {
      top: 10px;
      right: 10px;
    } */
  }
  .header-content {
    width: 100%;
    height: 80px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    position: relative;

    img {
      height: 30px;
      width: 30px;
      margin-right: 20px;
    }

    .title {
      font-size: ${dim(24.5)};
      color: ${(props) => props.theme['--color-text-main']};
    }
  }
`;

const progressBar = keyframes`
  from {
    width: 0;
  }
  to {
    width: calc(100% - 5px);
  }
`;
const ProgressBar = styled.div`
  animation-play-state: paused;
  opacity: 1;
  width: 100%;
  height: 5px;
  background: #008a83;
  position: absolute;
  top: -5px;
  border-radius: 2px 2px 0 0;
  z-index: 1;
  &:after {
    border-radius: 2px 2px 2px 0;
    content: '';
    height: 5px;
    position: absolute;
    left: 0;
    width: 0;
    animation: ${progressBar} 40s cubic-bezier(0.25, 0.25, 0.25, 1) 1;
    animation-fill-mode: forwards;
    background: #229558;
  }
`;
export { ModalContent, ProgressBar };
