/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Form, Dropdown, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import MainLayout from 'components/layout/wrap';
import { getFaucetContract, methods, getVeTokenContract, getVeNFTContract } from 'utilities/contractService';
import addToken from 'utilities/addToken';
import { promisify } from 'utilities/promisify';
import Button from '@material-ui/core/Button';
import LoadingSpinner from 'components/common/loadingSpinner';
import toast from 'components/common/toast';
import moment from 'moment';
import {
  CONTRACT_CHEE_TOKEN_ADDRESS,
  CONTRACT_TOKEN_ADDRESS,
  CONTRACT_CAI_TOKEN_ADDRESS,
  CONTRACT_FAUCET_ADDRESS,
  EXPLORER_LINK,
  VEPOOLS_ADDRESS_VETOKEN,
  VEPOOLS_ADDRESS_VENFT,
} from 'constants/address';
import Web3 from 'web3';
import { Row, Column } from 'components/common/style';
import BigNumber from 'bignumber.js';
import { formatNumber, getBigNumber } from 'utilities/number';
import { FaucetWrapper, ButtonWrapper } from './style';

function Faucet({ settings }) {
  const [canCeloClickVeBtn, setCeloCanClickVeBtn] = useState(true);
  const [canCeloApproveBtn, setCanCeloApproveBtn] = useState(true);
  const [canETHClickVeBtn, setETHClickVeBtn] = useState(true);
  const [canETHApproveBtn, setCanETHApproveBtn] = useState(true);
  const handleFaucetWithdraw = async () => {
    const faucetContract = getFaucetContract(CONTRACT_FAUCET_ADDRESS[settings.networkName]);
    await methods.send(faucetContract.methods.withdraw, [], settings.selectedAddress);
  };

  const handleTest = async () => {
    const veTokenContract = getVeTokenContract(VEPOOLS_ADDRESS_VETOKEN[settings.networkName], settings.networkName);
    const balanceOfRandom = Math.floor(((Math.random() * 5) / 10).toFixed(2) * 10000);
    const result = Web3.utils.toWei(String(balanceOfRandom), 'ether');
    await methods
      .send(veTokenContract.methods.claim, [settings.selectedAddress, result], settings.selectedAddress)
      .then(() => {
        if (settings.networkName === 'ETH') {
          setCanETHApproveBtn(false);
        } else {
          setCanCeloApproveBtn(false);
        }
      });
  };

  const handleApprove = async () => {
    const balance = getVeTokenContract(VEPOOLS_ADDRESS_VETOKEN[settings.networkName], settings.networkName);
    const balanceOf = await methods.call(balance.methods.balanceOf, [settings.selectedAddress]);
    const veTokenContract = getVeTokenContract(VEPOOLS_ADDRESS_VETOKEN[settings.networkName], settings.networkName);
    await methods
      .send(
        veTokenContract.methods.approve,
        [VEPOOLS_ADDRESS_VENFT[settings.networkName], getBigNumber(2).pow(256).minus(1).toString(10)],
        settings.selectedAddress
      )
      .then(() => {
        if (!balanceOf) {
          setETHClickVeBtn(true);
          setCeloCanClickVeBtn(true);
          return;
        }
        if (settings.networkName === 'ETH') {
          setETHClickVeBtn(false);
        } else {
          setCeloCanClickVeBtn(false);
        }
      });
  };

  const handleVeTest = async () => {
    // approve
    const veNFTContract = getVeNFTContract(VEPOOLS_ADDRESS_VENFT[settings.networkName], settings.networkName);
    const balance = getVeTokenContract(VEPOOLS_ADDRESS_VETOKEN[settings.networkName], settings.networkName);
    const end = moment(moment().add(Math.floor(Math.random() * 60) + 30, 'day')).unix();
    // balance
    const balanceOf = await methods.call(balance.methods.balanceOf, [settings.selectedAddress]);
    const balanceOfRandom = BigNumber(balanceOf).multipliedBy((Math.random() * 5) / 10);
    const result = balanceOfRandom.dp(0).toString(10);
    await methods
      .send(
        veNFTContract.methods.safeMint,
        ['test.com', end, result, VEPOOLS_ADDRESS_VETOKEN[settings.networkName]],
        settings.selectedAddress
      )
      .then(() => {
        if (settings.networkName === 'ETH') {
          setETHClickVeBtn(true);
          setCanETHApproveBtn(true);
        } else {
          setCeloCanClickVeBtn(true);
          setCanCeloApproveBtn(true);
        }
      });
  };

  return settings.networkName === 'CELO' ? (
    <MainLayout isHeader={false}>
      <div className="flex just-center align-center">
        <FaucetWrapper className="flex flex-column align-center just-center">
          <p className="header">Chee Finance Celo Testnet Faucet</p>
          <Row>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button
                  className="fill-btn next-btn button"
                  href="https://celo.org/developers/faucet"
                  target="_blank"
                  rel="noreferrer"
                >
                  Give Me Test CELO
                </Button>
              </ButtonWrapper>
            </Column>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button className="fill-btn next-btn button" onClick={handleFaucetWithdraw}>
                  Give Me Test BTC, ETH, cUSD, cEUR, cREAL, DAI
                </Button>
              </ButtonWrapper>
            </Column>
            <p className="header">veNFT Faucet</p>
            <Column xs="12" sm="12">
              <Column xs="12" sm="12">
                <ButtonWrapper>
                  <Button className="fill-btn next-btn button" onClick={handleTest}>
                    1. Claim Test
                  </Button>
                </ButtonWrapper>
              </Column>
              <Column xs="12" sm="12">
                <ButtonWrapper>
                  <Button className="fill-btn next-btn button" onClick={handleApprove}>
                    2. Approve Test
                  </Button>
                </ButtonWrapper>
              </Column>
              <Column xs="12" sm="12">
                <ButtonWrapper>
                  <Button className="fill-btn next-btn button" onClick={handleVeTest}>
                    3. Claim veTest
                  </Button>
                </ButtonWrapper>
              </Column>
            </Column>
          </Row>
          <div className="flex flex-column align-center just-center bottom">
            {/* <p className="title">How does this work?</p> */}
            <p className="description">
              {`Click to add `}
              <a onClick={() => addToken('dai', settings.decimals.dai.token, 'token', settings.networkName)}>DAI</a>
              {`, `}
              <a onClick={() => addToken('cusd', settings.decimals.cusd.token, 'token', settings.networkName)}>CUSD</a>
              {`, `}
              <a onClick={() => addToken('ceur', settings.decimals.ceur.token, 'token', settings.networkName)}>CEUR</a>
              {`, `}
              <a onClick={() => addToken('btc', settings.decimals.btc.token, 'token', settings.networkName)}>BTC</a>
              {`, `}
              <a onClick={() => addToken('eth', settings.decimals.eth.token, 'token', settings.networkName)}>ETH</a>
              {` tokens on Metamask.`}
            </p>
            <p className="description">
              Click to get details about{' '}
              <a href="https://docs.celo.org/developer-guide/celo-for-eth-devs" target="_blank" rel="noreferrer">
                Celo
              </a>
            </p>
          </div>
        </FaucetWrapper>
      </div>
    </MainLayout>
  ) : settings.networkName === 'BSC' ? (
    <MainLayout isHeader={false}>
      <div className="flex just-center align-center">
        <FaucetWrapper className="flex flex-column align-center just-center">
          <p className="header">Chee Finance BSC Testnet Faucet</p>
          <Row>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button
                  className="fill-btn next-btn button"
                  href="https://testnet.binance.org/faucet-smart"
                  target="_blank"
                  rel="noreferrer"
                >
                  Give Me Test BNB
                </Button>
              </ButtonWrapper>
            </Column>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button className="fill-btn next-btn button" onClick={handleFaucetWithdraw}>
                  Give Me Test BTC, ETH, USDT and CHEETest
                </Button>
              </ButtonWrapper>
            </Column>
          </Row>
          <div className="flex flex-column align-center just-center bottom">
            <p className="title">How does this work?</p>
            <p className="description">
              {`Click to add `}
              <a onClick={() => addToken('btc', settings.decimals.btc.token, 'token', settings.networkName)}>BTC</a>
              {`, `}
              <a onClick={() => addToken('eth', settings.decimals.eth.token, 'token', settings.networkName)}>ETH</a>
              {`, `}
              <a onClick={() => addToken('usdt', settings.decimals.usdt.token, 'token', settings.networkName)}>USDT</a>
              {`, `}
              <a onClick={() => addToken('cheetest', 18, 'token', settings.networkName)}>CHEETest</a>
              {` tokens on Metamask.`}
            </p>
            <p className="description">
              CHEETest tokens are for testing uses on{' '}
              <a href="https://bridgetest.chee.finance" target="_blank" rel="noreferrer">
                CHEE Bridge
              </a>
            </p>
            <p className="description">
              Click to get details about{' '}
              <a href="https://docs.binance.org/index.html" target="_blank" rel="noreferrer">
                BSC
              </a>
            </p>
          </div>
        </FaucetWrapper>
      </div>
    </MainLayout>
  ) : settings.networkName === 'METER' ? (
    <MainLayout isHeader={false}>
      <div className="flex just-center align-center">
        <FaucetWrapper className="flex flex-column align-center just-center">
          <p className="header">Chee Finance Meter Testnet Faucet</p>
          <Row>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button
                  className="fill-btn next-btn button"
                  href="https://faucet-warringstakes.meter.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Give Me Test MTR
                </Button>
              </ButtonWrapper>
            </Column>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button className="fill-btn next-btn button" onClick={handleFaucetWithdraw}>
                  Give Me Test BTC, ETH, USDT and CHEETest
                </Button>
              </ButtonWrapper>
            </Column>
          </Row>
          <div className="flex flex-column align-center just-center bottom">
            <p className="title">How does this work?</p>
            <p className="description">
              {`Click to add `}
              <a onClick={() => addToken('btc', settings.decimals.btc.token, 'token', settings.networkName)}>BTC</a>
              {`, `}
              <a onClick={() => addToken('eth', settings.decimals.eth.token, 'token', settings.networkName)}>ETH</a>
              {`, `}
              <a onClick={() => addToken('usdt', settings.decimals.usdt.token, 'token', settings.networkName)}>USDT</a>
              {`, `}
              <a onClick={() => addToken('cheetest', 18, 'token', settings.networkName)}>CHEETest</a>
              {` tokens on Metamask.`}
            </p>
            <p className="description">
              CHEETest tokens are for testing uses on{' '}
              <a href="https://bridgetest.chee.finance" target="_blank" rel="noreferrer">
                CHEE Bridge
              </a>
            </p>
            <p className="description">
              Click to get details about{' '}
              <a href="https://docs.meter.io/" target="_blank" rel="noreferrer">
                Meter
              </a>
            </p>
          </div>
        </FaucetWrapper>
      </div>
    </MainLayout>
  ) : settings.networkName === 'POLYGON' ? (
    <MainLayout isHeader={false}>
      <div className="flex just-center align-center">
        <FaucetWrapper className="flex flex-column align-center just-center">
          <p className="header">Chee Finance Polygon Mumbai Testnet Faucet</p>
          <Row>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button
                  className="fill-btn next-btn button"
                  href="https://faucet.polygon.technology/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Give Me Test MATIC
                </Button>
              </ButtonWrapper>
            </Column>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button className="fill-btn next-btn button" onClick={handleFaucetWithdraw}>
                  Give Me Test BTC, ETH, USDT
                </Button>
              </ButtonWrapper>
            </Column>
          </Row>
          <div className="flex flex-column align-center just-center bottom">
            <p className="title">How does this work?</p>
            <p className="description">
              {`Click to add `}
              <a onClick={() => addToken('btc', settings.decimals.btc.token, 'token', settings.networkName)}>BTC</a>
              {`, `}
              <a onClick={() => addToken('eth', settings.decimals.eth.token, 'token', settings.networkName)}>ETH</a>
              {`, `}
              <a onClick={() => addToken('usdt', settings.decimals.usdt.token, 'token', settings.networkName)}>USDT</a>
              {` tokens on Metamask.`}
            </p>
            <p className="description">
              Click to get details about{' '}
              <a href="https://docs.polygon.technology/" target="_blank" rel="noreferrer">
                Polygon
              </a>
            </p>
          </div>
        </FaucetWrapper>
      </div>
    </MainLayout>
  ) : settings.networkName === 'ETH' ? (
    <MainLayout isHeader={false}>
      <div className="flex just-center align-center">
        <FaucetWrapper className="flex flex-column align-center just-center">
          <p className="header">Chee Finance ETH Testnet Faucet</p>
          <Row>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button className="fill-btn next-btn button" onClick={handleTest}>
                  1. Claim iZi
                </Button>
              </ButtonWrapper>
            </Column>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button className="fill-btn next-btn button" onClick={handleApprove}>
                  2. Approve iZi
                </Button>
              </ButtonWrapper>
            </Column>
            <Column xs="12" sm="12">
              <ButtonWrapper>
                <Button className="fill-btn next-btn button" onClick={handleVeTest}>
                  3. Claim veizi
                </Button>
              </ButtonWrapper>
            </Column>
          </Row>
          {/* <div className="flex flex-column align-center just-center bottom">
            <p className="title">How does this work?</p>
            <p className="description">
              {`Click to add `}
              <a onClick={() => addToken('btc', settings.decimals.btc.token, 'token', settings.networkName)}>BTC</a>
              {`, `}
              <a onClick={() => addToken('eth', settings.decimals.eth.token, 'token', settings.networkName)}>ETH</a>
              {`, `}
              <a onClick={() => addToken('usdt', settings.decimals.usdt.token, 'token', settings.networkName)}>USDT</a>
              {` tokens on Metamask.`}
            </p>
            <p className="description">
              Click to get details about{' '}
              <a href="https://docs.polygon.technology/" target="_blank" rel="noreferrer">
                Polygon
              </a>
            </p>
          </div> */}
        </FaucetWrapper>
      </div>
    </MainLayout>
  ) : (
    // eslint-disable-next-line react/self-closing-comp
    <MainLayout isHeader={false}></MainLayout>
  );
}

Faucet.propTypes = {
  settings: PropTypes.object,
};

Faucet.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(Faucet);
