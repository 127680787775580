import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import teleImg from 'assets/img/tele.svg';
import discordImg from 'assets/img/discord.svg';
import twitterImg from 'assets/img/twitter.svg';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import light from 'assets/img/light.png';
import dark from 'assets/img/dark.png';
import { LinkMenuWrapper } from './style';

function LinkMenu({ className }) {
  return (
    <LinkMenuWrapper className={className}>
      <div className="link-menu-item">
        <a href="https://t.me/CheeFinance" target="_blank" rel="noreferrer">
          <div className="img-wrap">
            <img src={teleImg} alt="" />
          </div>
          <div>
            <FormattedMessage id="Telegram" />
          </div>
        </a>
      </div>
      <div className="link-menu-item">
        <a href="https://discord.gg/Pcdp43FTPW" target="_blank" rel="noreferrer">
          <div className="img-wrap">
            <img src={discordImg} alt="" />
          </div>
          <div>
            <FormattedMessage id="Discord" />
          </div>
        </a>
      </div>
      <div className="link-menu-item">
        <a href="https://twitter.com/CheeFinance" target="_blank" rel="noreferrer">
          <div className="img-wrap">
            <img src={twitterImg} alt="" />
          </div>
          <div>
            <FormattedMessage id="Twitter" />
          </div>
        </a>
      </div>
    </LinkMenuWrapper>
  );
}

export default LinkMenu;
