const cTokens = {
  CELO: {
    celo: '0x009CD1B273cFf1F371f6a1d90E046D5439460F7c',
  },
  BSC: {
    bnb: '0x76e51F355Eb570b815118Ab38276d5efeF1ACbd7',
  },
  METER: {
    mtr: '0xC791F499786D8d48D9A8f751C5b98A91d3D026C8',
  },
  POLYGON: {
    matic: '0x5a46256055DE0f74635ff59a07174a9fC9d5A0Bf',
  },
};

export { cTokens };
