import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from 'components/common/loadingSpinner';
import { Icon } from 'antd';
import { compose } from 'recompose';
import { connectAccount } from 'modules';
import { getComptrollerContract, methods } from 'utilities/contractService';
import { CONTRACT_COMPTROLLER_ADDRESS } from 'constants/address';
import toast from 'components/common/toast';
import { Label } from 'components/common/style';
import CollateralConfirmModal from 'components/common/collateralConfirmModal';
import Toggle from 'components/common/toggle';
import SupplyModal from 'components/common/supplyModal';
import MarketTable from 'components/common/table';
import { formatNumber, getBigNumber } from 'utilities/number';

import { SupplyMarketWrapper } from './style';

function SupplyMarket({ settings, suppliedAssets, remainAssets }) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenCollateralConfirm, setIsCollateralConfirm] = useState(false);
  const [record, setRecord] = useState({});
  const [isCollateralEnalbe, setIsCollateralEnable] = useState(true);

  const handleToggleCollateral = (r) => {
    const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[settings.networkName]);
    if (r && settings.selectedAddress && r.borrowBalance.isZero()) {
      if (!r.collateral) {
        setIsCollateralEnable(false);
        setIsCollateralConfirm(true);
        methods
          .send(appContract.methods.enterMarkets, [[r.ctokenAddress]], settings.selectedAddress)
          .then(() => {
            setIsCollateralConfirm(false);
          })
          .catch(() => {
            setIsCollateralConfirm(false);
          });
      } else if (+r.hypotheticalLiquidity['1'] > 0 || +r.hypotheticalLiquidity['2'] === 0) {
        setIsCollateralEnable(true);
        setIsCollateralConfirm(true);
        methods
          .send(appContract.methods.exitMarket, [r.ctokenAddress], settings.selectedAddress)
          .then(() => {
            setIsCollateralConfirm(false);
          })
          .catch(() => {
            setIsCollateralConfirm(false);
          });
      } else {
        toast.error({
          title: `Collateral Required`,
          description:
            'You need to set collateral at least one asset for your borrowed assets. Please repay all borrowed asset or set other asset as collateral.',
        });
      }
    } else {
      toast.error({
        title: `Collateral Required`,
        description:
          'You need to set collateral at least one asset for your borrowed assets. Please repay all borrowed asset or set other asset as collateral.',
      });
    }
  };

  const supplyColumns = [
    {
      title: <FormattedMessage id="Asset" />,
      dataIndex: 'asset',
      key: 'asset',
      render(img, asset) {
        return {
          children: (
            <div className="flex align-center">
              <img src={asset.img} alt="ethereum" />
              <div className="flex flex-column align-start">
                <Label size="14" primary>
                  {asset.displayName}
                </Label>
                {/* <Label size="14">{asset.supplyApy.dp(2, 1).toString(10)}%</Label> */}
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Supply APY" />,
      dataIndex: 'supplyApy',
      key: 'supplyApy',
      render(supplyApy, asset) {
        const apy = settings.withCHEE ? supplyApy.plus(asset.cheeSupplyApy) : supplyApy;

        return {
          children: (
            <div className="apy-content">
              <div className="apy-green-label">
                {formatNumber(apy, true, 2) >= 0.01 ? <span>+</span> : ''}
                {formatNumber(apy, true, 2, '', '%')}
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Wallet Balance" />,
      dataIndex: 'walletBalance',
      key: 'walletBalance',
      render(walletBalance, asset) {
        return {
          children: (
            <Label size="14" primary>
              {formatNumber(walletBalance, true, 5)} {asset.symbol}
            </Label>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Collateral" />,
      dataIndex: 'collateral',
      key: 'collateral',
      render(collateral, asset) {
        return {
          children: +asset.collateralFactor.toString() ? (
            <Toggle checked={collateral} onChecked={() => handleToggleCollateral(asset)} />
          ) : null,
        };
      },
    },
  ];

  const suppliedColumns = [
    {
      title: <FormattedMessage id="Asset" />,
      dataIndex: 'asset',
      key: 'asset',
      render(img, asset) {
        return {
          children: (
            <div className="flex align-center">
              <img src={asset.img} alt="ethereum" />
              <div className="flex flex-column align-start">
                <Label size="14" primary>
                  {asset.displayName}
                </Label>
                {/* <Label size="14">{asset.supplyApy.dp(2, 1).toString(10)}%</Label> */}
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Supply APY" />,
      dataIndex: 'supplyApy',
      key: 'supplyApy',
      render(supplyApy, asset) {
        const apy = settings.withCHEE ? supplyApy.plus(asset.cheeSupplyApy) : supplyApy;
        return {
          children: (
            <div className="apy-content">
              {/* <Icon type="arrow-up" /> */}
              <div className="apy-green-label">
                {formatNumber(apy, true, 2) >= 0.01 ? <span>+</span> : ''}
                {formatNumber(apy, true, 2, apy.dp(2, 1) > 0 ? '+' : '', '%')}
              </div>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Supply Balance" />,
      dataIndex: 'supplyBalance',
      key: 'supplyBalance',
      render(supplyBalance, asset) {
        return {
          children: (
            <div className="wallet-label flex flex-column">
              <Label size="14" primary>
                ${formatNumber(supplyBalance.times(asset.tokenPrice))}
              </Label>
              <Label size="14">
                {formatNumber(supplyBalance, true, 5)} {asset.symbol}
              </Label>
            </div>
          ),
        };
      },
    },
    {
      title: <FormattedMessage id="Collateral" />,
      dataIndex: 'collateral',
      key: 'collateral',
      render(collateral, asset) {
        return {
          children: +asset.collateralFactor ? (
            <Toggle checked={collateral} onChecked={() => handleToggleCollateral(asset)} />
          ) : null,
        };
      },
    },
  ];

  const handleClickRow = (row) => {
    setRecord(row);
    setIsOpenModal(true);
  };
  return (
    <SupplyMarketWrapper>
      {suppliedAssets.length === 0 && remainAssets.length === 0 && <LoadingSpinner />}
      {suppliedAssets.length > 0 && (
        <MarketTable
          columns={suppliedColumns}
          data={suppliedAssets}
          // title={<FormattedMessage id="Supplied" />}
          handleClickRow={handleClickRow}
        />
      )}
      {/* {settings.pendingInfo &&
        settings.pendingInfo.status &&
        ['Supply', 'Withdraw'].includes(settings.pendingInfo.type) && <PendingTransaction />} */}
      {remainAssets.length > 0 && (
        <MarketTable
          columns={supplyColumns}
          data={remainAssets}
          title={<FormattedMessage id="All Markets" />}
          handleClickRow={handleClickRow}
        />
      )}
      <SupplyModal visible={isOpenModal} asset={record} onCancel={() => setIsOpenModal(false)} />
      <CollateralConfirmModal
        visible={isOpenCollateralConfirm}
        isCollateralEnalbe={isCollateralEnalbe}
        onCancel={() => setIsCollateralConfirm(false)}
      />
    </SupplyMarketWrapper>
  );
}

SupplyMarket.propTypes = {
  suppliedAssets: PropTypes.array,
  remainAssets: PropTypes.array,
  settings: PropTypes.object,
};

SupplyMarket.defaultProps = {
  suppliedAssets: [],
  remainAssets: [],
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(connectAccount(mapStateToProps))(SupplyMarket);
