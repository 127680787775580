import React, { useState, useEffect } from 'react';
import { compose } from 'recompose';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import Sidebar from 'components/layout/sidebar/index';
import { networkInfo, networkList } from 'constants/network';
import Top from 'components/layout/top/index';
import { Row, Column } from 'components/common/style';
import CustomNetworkModal from 'components/common/customNetworkModal';
import { connectAccount, accountActionCreators } from 'modules';
import { isTest } from 'constants/env';
import { MainLayoutWrapper } from './style';
import LangDrawer from '../components/lang-drawer';

const transRoutes = ['/dashboard', '/market', '/transaction', '/liquidation'];

function MainLayout({ children, fullHeight, settings }) {
  const [isOpenTransNetworkModal, setIsOpenTransNetworkModal] = useState(false);
  const [isOpenveNFTNetworkModal, setIsOpenveNFTNetworkModal] = useState(false);
  const [isOpenBridgeNetworkModal, setIsOpenBridgeNetworkModal] = useState(false);
  const [canShowPage, setCanShowPage] = useState(isTest);

  useEffect(() => {
    if (!settings.networkName) return;
    const networkItem = networkList.find((item) => item.shortName === settings.networkName);
    switch (window.location.hash) {
      case '#/dashboard':
      case '#/market':
      case '#/transaction':
      case '#/liquidation':
        if (!networkItem?.supportLiquidation) {
          setIsOpenTransNetworkModal(true);
        }
        break;
      case '#/vePools':
        if (!networkItem.supportNFT) {
          setIsOpenveNFTNetworkModal(true);
        }
        break;
      default:
    }
  }, [window.location.hash, settings.networkName]);

  useEffect(() => {
    if (!settings.networkName) return;
    const isSupportTrans = networkList.find(
      (networkItem) => settings.networkName === networkItem.shortName
    )?.supportTrans;
    const networkItem = networkList.find((item) => item.shortName === settings.networkName);
    if (transRoutes.includes(window.location.hash.split('#')[1])) {
      if (!isSupportTrans) {
        setIsOpenTransNetworkModal(true);
        // setCanShowPage(false);
      } else {
        setIsOpenTransNetworkModal(false);
        // setCanShowPage(true);
      }
    } else if (['/vePools'].includes(window.location.hash.split('#')[1])) {
      if (networkItem.supportNFTBtnDisabled) {
        setIsOpenveNFTNetworkModal(true);
        // setCanShowPage(false);
      } else {
        setIsOpenveNFTNetworkModal(false);
        // setCanShowPage(true);
      }
    }
  }, [window.location.hash, settings.networkName]);

  return (
    <MainLayoutWrapper>
      <Column xs="12" sm="2">
        <Sidebar />
      </Column>
      <Column xs="12" sm="10" className="main">
        <Row className="row">
          <Column xs="12" className="top--wrapper">
            <Top />
          </Column>
          <Column xs="12" className={`main-content--wrapper ${fullHeight ? 'full_height' : ''}`}>
            <div className="main-content">
              <CustomNetworkModal
                key="trans"
                visible={isOpenTransNetworkModal}
                web3={settings.web3}
                networkInfo={networkInfo}
                networkList={networkList}
                awaiting={settings.accountLoading}
                walletType={settings.walletType}
                onCancel={() => setIsOpenTransNetworkModal(false)}
                customNetworkList={networkList
                  .filter((item) => item.supportTrans)
                  .map((item) => ({ value: item.value, isDisabled: false }))}
                title="Currently this feature is only supported on the following networks"
              />
              <CustomNetworkModal
                key="nft"
                visible={isOpenveNFTNetworkModal}
                web3={settings.web3}
                awaiting={settings.accountLoading}
                walletType={settings.walletType}
                networkInfo={networkInfo}
                networkList={networkList}
                onCancel={() => setIsOpenveNFTNetworkModal(false)}
                customNetworkList={networkList
                  .filter((item) => item.supportNFT)
                  .map((item) => ({
                    value: item.value,
                    isDisabled: item.supportNFTBtnDisabled,
                  }))}
                title="Currently this feature is only supported on the following networks"
              />
              {children}
            </div>
          </Column>
        </Row>
      </Column>
      <LangDrawer />
    </MainLayoutWrapper>
  );
}

MainLayout.propTypes = {
  fullHeight: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

MainLayout.defaultProps = {
  children: null,
  fullHeight: false,
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

export default compose(withTheme, connectAccount(mapStateToProps))(MainLayout);
