import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// import { Table } from 'antd';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import MainLayout from 'components/layout/wrap';
import { SpinnerWrapper } from 'components/page/dashBoard/style';
import LoadingSpinner from 'components/common/loadingSpinner';
import ComingSoon from 'components/common/comingSoon';
import P2PItem from 'components/common/p2pItem';
import { PageTitle } from 'components/common/style';
import { transTypeApi } from 'utilities/subgraph';
import { FormattedMessage } from 'react-intl';
import p2pHeaderImg from 'assets/img/p2p-header.svg';
import Arrow from 'assets/img/icons/arrow.svg';
import p2p from 'assets/img/icons/p2p.svg';

import TabsTable from 'components/common/tabs-table';
import { P2PWrapper, P2pHeader, TableWrap } from './style';

function P2P({ settings }) {
  const columns = [
    {
      title: '',
      dataIndex: 'name',
      render(createElement, context) {
        return <P2PItem />;
      },
    },
    {
      title: '30 Day APY',
      dataIndex: 'apy',
      render(createElement, context) {
        return <div className="apy">12%</div>;
      },
    },
    {
      title: 'Outstanding Loan Value',
      dataIndex: 'value',
      render(createElement, context) {
        return (
          <div className="flex">
            <div className="table__num">12M</div>
            <div className="table__sym">curd</div>
          </div>
        );
      },
    },
    {
      title: 'Loans Originated',
      dataIndex: 'originated',
      render(createElement, context) {
        return (
          <div className="flex">
            <div className="table__num">12M</div>
            <div className="table__sym">curd</div>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: 'action',
      render(createElement, context) {
        return <img className="arrow" src={Arrow} alt="" />;
      },
    },
  ];

  const data = [];
  // for (let i = 0; i < 10; i += 1) {
  //   data.push({
  //     key: i,
  //     name: `Edward King ${i}`,
  //     age: 32,
  //     address: `London, Park Lane no. ${i}`,
  //   });
  // }

  return (
    <MainLayout title="" fullHeight>
      <PageTitle>
        <img src={p2p} alt="" />
        P2P
      </PageTitle>
      <P2PWrapper>
        <P2pHeader>
          <img className="header-img" src={p2pHeaderImg} alt="" />
          <div className="info">
            <div className="info__item">
              <div className="info__title">
                <FormattedMessage id="Loans Originated" />
              </div>
              <div className="info__desc">
                <FormattedMessage id="p2p.title" />
              </div>
            </div>
            <div className="info__line" />
            <div className="info__item">
              <div className="info__title">
                <FormattedMessage id="Interest Earned" />
              </div>
              <div className="info__desc">
                <FormattedMessage id="p2p.title" />
              </div>
            </div>
            <div className="info__line" />
            <div className="info__item">
              <div className="info__title">
                <FormattedMessage id="Active Loans" />
              </div>
              <div className="info__desc">
                <FormattedMessage id="p2p.title" />
              </div>
            </div>
          </div>
        </P2pHeader>
        <TableWrap>
          <TabsTable
            tableProps={{
              columns,
              dataSource: data,
              pagination: false,
              bordered: true,
              loading: {
                spinning: true,
                indicator: <ComingSoon />,
              },
            }}
          />
        </TableWrap>
      </P2PWrapper>
    </MainLayout>
  );
}

P2P.propTypes = {
  settings: PropTypes.object,
};

P2P.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(P2P);
