import styled, { keyframes } from 'styled-components';
import { dim } from 'modules/ui/dim';

const ModalContent = styled.div`
  border-radius: 4px;
  background-color: ${(props) => props.theme['--color-pool-modal-bg']};

  .close-btn {
    color: #ffffff;
    position: absolute;
    top: 23px;
    right: 23px;
    font-size: 18px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
      top: 20px;
      right: 20px;
    }
    &:hover {
      filter: invert(100%);
    }
  }
  .radius {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }

  .header-content {
    width: 100%;
    background: #0c1111;
    .logo-image {
      width: 65px;
      height: 61px;
      margin-bottom: 39px;
      @media only screen and (max-width: 768px) {
        margin-bottom: 19px;
      }
    }
    .title {
      font-size: ${dim(18)};
      color: #ffffff;
      height: 88px;
      line-height: 88px;
      font-weight: 700;
      @media only screen and (max-width: 768px) {
        height: 54px;
        line-height: 54px;
      }
    }

    .back-btn {
      width: 100%;
      padding: 10px 30px;
      img {
        transform: rotate(180deg);
        margin-right: 10px;
      }
      span {
        color: ${(props) => props.theme['--color-white']};
        font-size: 20px;
      }
    }
  }
  .mint-content-wrapper {
    width: 100%;
    background-position: 0px 0px;
    background-repeat: no-repeat;
    background-size: contain;
  }
  .mint-content {
    width: 100%;
    padding: 30px 55px;
    align-items: flex-start;
    @media only screen and (max-width: 768px) {
      padding: 20px;
    }
    .line {
      height: 1px;
      width: 100%;
      margin: 0 5px;
    }
    & .content-item:last-child {
      margin-bottom: 0 !important;
    }
    .second {
      margin-top: 30px;
      margin-bottom: 0;
      @media only screen and (max-width: 768px) {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
    .content-item {
      display: flex;
      align-items: center;
      // margin-bottom: 10px;
      width: 100%;

      .label-items {
        color: #ffffff;
        line-height: 38px;
      }

      .coin-type {
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        margin-right: 26px;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
        img {
          display: inline-block;
          width: 36px;
          height: 36px;
          margin-right: 10px;
          @media only screen and (max-width: 768px) {
            width: 16px;
            height: 16px;
            margin-right: 5px;
          }
        }
      }
      .coin-price {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: ${(props) => props.theme['--color-pools-text']};
        @media only screen and (max-width: 768px) {
          font-size: 16px;
        }
      }

      .coin-info {
        color: #939498;
        margin-bottom: 10px;
        width: 100%;
        @media only screen and (max-width: 768px) {
          font-size: 12px;
        }
        .error-msg {
          color: #ff4949;
        }
      }
    }

    // 输入框样式——————开始
    .input {
      width: 155px;
      height: 38px;
      border: 1px solid #2f413b;
      border-radius: 4px;
      background-color: transparent;
      /*去外边框*/
      outline: none;
      /*文本颜色*/
      color: ${(props) => props.theme['--color-pool-modal-input-placeholder']};
    }
    input:-moz-placeholder,
    textarea:-moz-placeholder {
      color: ${(props) => props.theme['--color-pool-modal-input-placeholder']};
    }
    input:-ms-input-placeholder,
    textarea:-ms-input-placeholder {
      color: ${(props) => props.theme['--color-pool-modal-input-placeholder']};
    }
    input::-webkit-input-placeholder,
    textarea::-webkit-input-placeholder {
      color: ${(props) => props.theme['--color-pool-modal-input-placeholder']};
    }
    .ant-input:placeholder-shown {
      text-overflow: inherit;
    }
    // 输入框样式——————结束

    .coin-count {
      margin-top: 18px;
      display: inline-block !important;

      .count-labels-wrapper {
        margin-top: 10px;

        .count-label {
          color: #939498;
        }
        .num-list {
          color: #229558;

          span {
            margin-right: 10px;
          }
          & > span:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
  .mint-price-content {
    margin-top: 10px;
    .receive-label {
      color: #ffffff;
      line-height: 38px;
    }

    .tag-input {
      position: relative;
      .tag {
        color: #229558;
        position: absolute;
        right: 10px;
      }
    }
    .label {
      text-align: left;
      width: 100%;
    }

    .mint-price-items {
      width: 100%;
      background: #101c18;
      border-radius: 4px;
      padding: 10px;
      margin-top: 10px;

      & .mint-price-item:last-child {
        margin-bottom: 0;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(270deg, #60ffab 0%, #30bd72 100%);
    border-radius: 4px;
    width: 100%;
    height: 50px;
    cursor: default;
    font-size: 18px;
    font-weight: 700;
    color: #ffffff;
    margin-top: 30px;
    @media only screen and (max-width: 768px) {
      height: 44px;
      font-size: 14px;
      margin-top: 20px;
    }
  }
  .btn-hover {
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
`;

const progressBarArg = keyframes`
  from {
    width: 0;
  }
  to {
    width: calc(100% - 5px);
  }
`;
const ProgressBar = styled.div`
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  animation-play-state: paused;
  opacity: 1;
  width: 100%;
  height: 5px;
  background: #008a83;
  position: absolute;
  top: -5px;
  border-radius: 2px 2px 0 0;
  z-index: 1;
  &:after {
    border-radius: 2px 2px 2px 0;
    content: '';
    height: 5px;
    position: absolute;
    left: 0;
    width: 0;
    animation: ${progressBarArg} 40s cubic-bezier(0.25, 0.25, 0.25, 1) 1;
    animation-fill-mode: forwards;
    background: #229558;
  }
`;

export { ModalContent, ProgressBar };
