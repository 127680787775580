import React, { useEffect, useState, useCallback } from 'react';
import Button from 'components/common/button';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import MainLayout from 'components/layout/wrap';
import P2PCard from 'components/common/p2pCard';
import P2PItemForDetail from 'components/common/p2pItemForDetail';
import { PageTitle } from 'components/common/style';
import { FormattedMessage } from 'react-intl';
import TabsTable from 'components/common/tabs-table';
import VuesaxImg from 'assets/img/icons/vuesax.svg';
import { P2PDetailWrapper } from './style';

function P2PDetail({ settings }) {
  return (
    <MainLayout title="" fullHeight>
      <PageTitle>P2P</PageTitle>
      <P2PDetailWrapper>
        <P2PItemForDetail />
        <div className="card-wrap">
          <div className="left">
            <P2PCard>
              <div className="title">Pool Overview</div>
              <div className="desc">
                Lorem ipsum dolor sit amet consectetur. Vulputate diam duis nunc arcu quam. Aenean nisi ullamcorper
                euismod turpis varius. Varius metus feugiat diam pellentesque viverra id purus. At interdum at dolor
                augue faucibus faucibus eu at. Pretium sed rhoncus turpis nisl maecenas aenean nunc scelerisque.
                Fermentum nulla lacus sed blandit ac viverra rutrum.
              </div>
              <div className="table">
                <div className="table__item">
                  <div className="table__item--title">Outstanding Loan Value</div>
                  <div className="table__item--desc">
                    4,000,000.00 <span className="sym">cUSD</span>
                  </div>
                </div>
                <div className="table__item">
                  <div className="table__item--title">Loans Originated</div>
                  <div className="table__item--desc">
                    4,000,000.00 <span className="sym">cUSD</span>
                  </div>
                </div>
                <div className="table__item">
                  <div className="table__item--title">30-Day APY</div>
                  <div className="table__item--desc">
                    4,000,000.00 <span className="sym">cUSD</span>
                  </div>
                </div>
                <div className="table__item">
                  <div className="table__item--title">Active Loans</div>
                  <div className="table__item--desc">
                    4,000,000.00 <span className="sym">cUSD</span>
                  </div>
                </div>
                <div className="table__item">
                  <div className="table__item--title">Idle Pool Liquidity</div>
                  <div className="table__item--desc">
                    4,000,000.00 <span className="sym">cUSD</span>
                  </div>
                </div>
              </div>
              <Button className="primary-next w-full">
                <FormattedMessage id="p2pDetail.btn" />
              </Button>
            </P2PCard>
          </div>
          <div className="right">
            <P2PCard>
              <div className="title">Your Pool Lending Statistics</div>
              <div className="chart">
                <div className="chart__item">
                  <img src={VuesaxImg} alt="" />
                  <div className="info">
                    <div className="chart__item--title">wwww</div>
                    <div className="chart__item--num">
                      400000<span className="sym">cUSD</span>
                    </div>
                  </div>
                </div>
                <div className="chart__item">
                  <img src={VuesaxImg} alt="" />
                  <div className="info">
                    <div className="chart__item--title">wwww</div>
                    <div className="chart__item--num">
                      400000<span className="sym">cUSD</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="table">
                <div className="table__item">
                  <div className="table__item--title">Outstanding Loan Value</div>
                  <div className="table__item--desc">
                    4,000,000.00 <span className="sym">cUSD</span>
                  </div>
                </div>
                <div className="table__item">
                  <div className="table__item--title">Loans Originated</div>
                  <div className="table__item--desc">
                    4,000,000.00 <span className="sym">cUSD</span>
                  </div>
                </div>
                <div className="table__item">
                  <div className="table__item--title">30-Day APY</div>
                  <div className="table__item--desc">
                    4,000,000.00 <span className="sym">cUSD</span>
                  </div>
                </div>
              </div>
              <Button className="primary w-full">
                <FormattedMessage id="p2pDetail.btn" />
              </Button>
            </P2PCard>
            <P2PCard>
              <div className="title">Your Pool Lending Statistics</div>
              <div className="desc">You have not scheduled a withdrawal window.</div>
              <div className="chart">
                <div className="chart__item">
                  <img src={VuesaxImg} alt="" />
                  <div className="info">
                    <div className="chart__item--title">wwww</div>
                    <div className="chart__item--num">
                      400000<span className="sym">cUSD</span>
                    </div>
                  </div>
                </div>
                <div className="chart__item">
                  <img src={VuesaxImg} alt="" />
                  <div className="info">
                    <div className="chart__item--title">wwww</div>
                    <div className="chart__item--num">
                      400000<span className="sym">cUSD</span>
                    </div>
                  </div>
                </div>
              </div>
            </P2PCard>
          </div>
        </div>
      </P2PDetailWrapper>
    </MainLayout>
  );
}

P2PDetail.propTypes = {
  settings: PropTypes.object,
};

P2PDetail.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({}, dispatch);
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(P2PDetail);
