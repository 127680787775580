import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { connectAccount } from 'modules';
import { compose } from 'recompose';
import { themeCollection } from 'constants/theme';
import { connectUI } from 'modules/ui/connectUI';

function Theme({ children, ui }) {
  return (
    // <ThemeProvider theme={ui.theme === 'dark' ? themeCollection.dark : themeCollection.light}>{children}</ThemeProvider>
    <ThemeProvider theme={themeCollection.dark}>{children}</ThemeProvider>
  );
}

Theme.propTypes = {
  children: PropTypes.node.isRequired,
  ui: PropTypes.object,
};

Theme.defaultProps = {
  ui: {
    theme: 'dark',
  },
};

const mapStateToProps = ({ ui }) => {
  return {
    ui,
  };
};
export default compose(connectAccount(mapStateToProps, undefined), connectUI(mapStateToProps, undefined))(Theme);
