import React from 'react';
import { hot } from 'react-hot-loader/root';
import { addLocaleData } from 'react-intl';
import { Provider } from 'react-redux';
import { HashRouter, Route, Redirect, Switch } from 'react-router-dom';
import { toast, ToastContainer, Bounce } from 'react-toastify';
import { store } from 'store/index';
import Dashboard from 'components/page/dashBoard/index';
import Faucet from 'components/page/faucet/index';
import CHEE from 'components/page/chee/index';
import Market from 'components/page/market';
import P2P from 'components/page/p2p';
import P2PDetail from 'components/page/p2pDetail';
import { networkInfo, networkList } from 'constants/network';
import coinbaseWalletModule from '@web3-onboard/coinbase';
import magicModule from '@web3-onboard/magic';
import walletConnectModule from '@web3-onboard/walletconnect';
import injectedModule from '@web3-onboard/injected-wallets';
import Onboard from '@web3-onboard/core';
import Transaction from 'components/page/transaction/index';
import VePools from 'components/page/vePools/index';
import en from 'react-intl/locale-data/en';
import id from 'react-intl/locale-data/id';
import ja from 'react-intl/locale-data/ja';
import ko from 'react-intl/locale-data/ko';
import ru from 'react-intl/locale-data/ru';
import tl from 'react-intl/locale-data/tl';
import tr from 'react-intl/locale-data/tr';
import vi from 'react-intl/locale-data/vi';
import avatarImg from 'assets/img/chee-64.png';

import Theme from './Theme';
import 'react-toastify/dist/ReactToastify.min.css';
import 'assets/styles/App.scss';
import Lang from './Lang';
import Liquidation from './page/liquidation';

const coinbaseWallet = coinbaseWalletModule();
const magicWallet = magicModule({
  apiKey: 'pk_live_2B9695058B6E9E2B',
  // userEmail: localStorage.getItem('magicUserEmail'),
});
const walletConnect = walletConnectModule();
const injected = injectedModule();

export const chains = Object.values(networkInfo).map((item) => ({
  id: item.chainId,
  token: item.shortName,
  label: item.chainName,
  rpcUrl: item.rpcUrls[0],
  blockExplorerUrl: item.blockExplorerUrls[0],
  publicRpcUrl: item.rpcUrls[0],
}));
const modules = [coinbaseWallet, magicWallet, walletConnect, injected];
const onboardI18 = /Mobi|Android|iPhone/i.test(navigator.userAgent)
  ? {
      en: {
        connect: {
          selectingWallet: {
            header: 'Metamask users, please visit our app directly on your Metamask Mobile browser.',
          },
        },
      },
    }
  : undefined;
export const onboard = Onboard({
  wallets: modules, // created in previous step
  chains,
  accountCenter: {
    desktop: {
      enabled: false,
      position: 'topLeft',
    },
  },
  appMetadata: {
    name: 'Chee Finance',
    icon: avatarImg,
    logo: avatarImg,
    description: 'Web3 money market with ERC20/NFT liquidity.',
    recommendedInjectedWallets: [
      { name: 'Coinbase', url: 'https://wallet.coinbase.com/' },
      { name: 'MetaMask', url: 'https://metamask.io' },
    ],
  },
  i18n: onboardI18,
});
const previouslyConnectedWallets = JSON.parse(window.localStorage.getItem('connectedWallets'));

class App extends React.Component {
  constructor(props) {
    super(props);
    if (previouslyConnectedWallets) {
      onboard.connectWallet({
        autoSelect: { label: previouslyConnectedWallets[0], disableModals: true },
      });
    } else {
      onboard.connectWallet();
    }
    addLocaleData([...en, ...id, ...ja, ...ko, ...ru, ...tl, ...tr, ...vi]);
  }

  render() {
    return (
      <Provider store={store}>
        <Theme>
          <Lang>
            <HashRouter>
              <ToastContainer
                autoClose={30000}
                transition={Bounce}
                newestOnTop
                position={toast.POSITION.TOP_RIGHT}
                pauseOnFocusLoss
                pauseOnHover
                limit={2}
              />
              <Switch
                atEnter={{ opacity: 0 }}
                atLeave={{ opacity: 0.5 }}
                atActive={{ opacity: 1 }}
                className="switch-wrapper"
              >
                <Route exact path="/dashboard" component={Dashboard} />
                {/* <Route exact path="/chee" component={CHEE} /> */}
                <Route exact path="/market" component={Market} />
                <Route exact path="/transaction" component={Transaction} />
                <Route exact path="/vePools" component={VePools} />
                <Route exact path="/liquidation" component={Liquidation} />
                <Route exact path="/p2p" component={P2P} />
                <Route exact path="/p2p/:id" component={P2PDetail} />

                {/* <Route exact path="/vault" component={Vault} /> */}
                {/* <Route exact path="/market/:asset" component={MarketDetail} /> */}
                {(process?.env.REACT_APP_AWS_ENV === 'dev' || process?.env.REACT_APP_ENV === 'dev') && (
                  <Route exact path="/faucet" component={Faucet} />
                )}
                <Redirect from="/" to="/dashboard" />
              </Switch>
            </HashRouter>
          </Lang>
        </Theme>
      </Provider>
    );
  }
}

export default hot(App);
