import * as CONTRACT_TEST from './address-test';
import * as CONTRACT_MAIN from './address-main';
import * as ABIS from './abis';

let temp = {};
if (process?.env.REACT_APP_AWS_ENV === 'prod') {
  temp = { ...CONTRACT_MAIN, ...ABIS };
} else {
  temp = { ...CONTRACT_TEST, ...ABIS };
}

export const {
  BLOCKS_PER_MINUTE,
  CONTRACT_BASE_TOKEN,
  CONTRACT_CAI_TOKEN_ADDRESS,
  CONTRACT_TOKEN_ADDRESS,
  CONTRACT_CTOKEN_ADDRESS,
  CONTRACT_CAI_TOKEN_ABI,
  CONTRACT_CAI_CONTROLLER_ABI,
  CONTRACT_CAI_UNITROLLER_ADDRESS,
  CONTRACT_CAI_VAULT_ABI,
  CONTRACT_CAI_VAULT_ADDRESS,
  CONTRACT_VAULT_ABI,
  CONTRACT_VAULT_ADDRESS,
  CONTRACT_CHEE_ABI,
  CONTRACT_ERC20_TOKEN_ABI,
  CONTRACT_CTOKEN_ABI,
  CONTRACT_CBASE_ABI,
  CONTRACT_COMPTROLLER_ADDRESS,
  CONTRACT_PRICE_ORACLE_ADDRESS,
  CONTRACT_PRICE_ORACLE_ABI,
  CONTRACT_VOTE_ABI,
  CONTRACT_VOTE_ADDRESS,
  CONTRACT_INTEREST_MODEL_ABI,
  CONTRACT_CHEE_LENS_ABI,
  CONTRACT_CHEE_LENS_ADDRESS,
  CONTRACT_COMPTROLLER_ABI,
  CONTRACT_CHEE_STORE_ADDRESS,
  CONTRACT_CHEE_TOKEN_ADDRESS,
  CONTRACT_FAUCET_ABI,
  CONTRACT_FAUCET_ADDRESS,
  EXPLORER_LINK,
  SUBGRAPH_URL,
  GRAPH_TICKER,
  REMAINING,
  DISTRIBUTED,
  CONTRACT_LEND_ABI,
  CONTRACT_VETOKEN_ABI,
  VEPOOLS_ADDRESS_LEND,
  VEPOOLS_ADDRESS_VETOKEN,
  VEPOOLS_ADDRESS_VENFT,
  VEPOOLS_ADDRESS_CHEETOKEN,
  CONTRACT_VENFT_ABI,
  CONTRACT_CHEEIZI_ABI,
  VENFT_URL,
  VENFTPRICE,
  YEARBLOCK,
  VETOKENASSET,
  CHEETOKEN,
  VETOKENNAME,
  CONTRACT_CBEP_ABI,
} = temp;
