import React from 'react';
import { Icon } from 'antd';
import PropTypes from 'prop-types';

import { P2PCardWrap } from './style';

function P2PCard({ children }) {
  return (
    <P2PCardWrap>
      {/* <div className="title">{title}</div>
      <div className="title">{desc}</div>
      <div className='table'>

      </div> */}
      {children}
    </P2PCardWrap>
  );
}

P2PCard.propTypes = {
  // title: PropTypes.string.isRequired,
  // desc: PropTypes.string,
};

P2PCard.defaultProps = {
  // desc: '',
};

export default P2PCard;
