import styled from 'styled-components';
import { dim } from 'modules/ui/dim';

const SidebarWrapper = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .menu,
  .sidebar-drawer {
    display: none;
  }
  background-color: ${(props) => props.theme['--color-bg']};

  @media only screen and (max-width: 768px) {
    display: flex;
    height: ${(props) => props.theme['--m-header-height']};
    flex-direction: row;
    // justify-content: space-between;
    align-items: center;
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
    background-color: ${(props) => props.theme['--color-bg-drawer']};
    .menu {
      display: block;
      width: 20px !important;
      color: ${(props) => props.theme['--color-bg-active']};
    }
    .sidebar-drawer {
      display: block;
    }
  }
  .ant-drawer-body {
    padding: 0;
    background-color: ${(props) => props.theme['--color-bg-sidebar']};
    @media only screen and (max-width: 768px) {
      background-color: ${(props) => props.theme['--color-bg-drawer']};
    }
  }
  .sidebar-nav {
    position: relative;
    width: 90%;
    height: 46px;

    color: ${(props) => props.theme['--color-text-top-light']};
    @media only screen and (max-width: 768px) {
      border-radius: unset;
      width: 100%;
      &:hover {
        font-weight: 600;
        font-size: 17px;
      }
    }
  }
  .nav-icon-wrap {
    width: 22px;
    height: 22px;
    margin-right: 15px;
    margin-left: 15px;

    @media only screen and (max-width: 1280px) {
      width: 18px;
      height: 18px;
      margin-right: 10px;
      margin-left: 10px;
    }
  }
`;

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  i {
    font-size: 18px;
  }
  img {
    cursor: pointer;
    &:hover {
      opacity: 0.7;
      /* filter: invert(100%); */
    }
  }

  @media only screen and (max-width: 768px) {
    height: 50px;
    padding: 0 ${dim(26)};
    justify-content: space-between;
    width: 100%;
    .logo-text {
      width: 69px !important;
    }
  }

  @media only screen and (max-width: 1280px) {
    i {
      font-size: 13px !important;
    }
    img {
      width: 80px;
    }
  }
`;

const MainMenu = styled.div`
  margin-top: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  .nav {
    border: 1px solid red;
  }

  @media only screen and (max-width: 768px) {
    margin: 0 20px;
  }

  .chee-active-icon {
    display: none;
  }

  a {
    i,
    img {
      width: 20%;
      margin: 0 10%;
      svg {
        fill: ${(props) => props.theme['--color-text-sidebar']};
      }
    }
    .transaction {
      width: 14%;
      margin: 0 4% 0 12%;
    }
    img {
      width: 10%;
      margin: 0 13%;
    }
    @media only screen and (max-width: 1440px) {
      span {
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 1280px) {
      span {
        font-size: 13px;
      }

      /* &:hover {
        span {
          font-size: 13px !important;
        }
      } */
    }

    &:not(:last-child) {
      margin-bottom: 15px;
    }
  }

  .active {
    // background-color: ${(props) => props.theme['--color-bg-active']};
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -4px;
      width: 4px;
      height: 100%;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
      background: #008a83;
    }
    background: #0c1111;
    svg {
      fill: ${(props) => props.theme['--color-yellow']};
    }
    span {
      color: ${(props) => props.theme['--color-text-sidebar']};
    }
    path {
      fill: ${(props) => props.theme['--color-yellow']};
    }
    .chee-icon {
      display: none;
    }
    .chee-active-icon {
      display: block;
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const FaucetMenu = styled.div`
  width: 100%;
  margin-top: auto;
  .token-btn {
    width: 80%;
    margin: 0 auto;
    background-color: ${(props) => props.theme['--color-token-btn']};
    border-radius: 4px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
  }
  a {
    padding: 7px 0px;
    svg {
      fill: ${(props) => props.theme['--color-text-main']};
      margin-left: 34px;
      margin-right: 26px;
    }

    &:hover {
      span {
        color: ${(props) => props.theme['--color-white']};
      }
    }

    @media only screen and (max-width: 1440px) {
      span {
        font-size: 14px;
      }
    }

    @media only screen and (max-width: 1280px) {
      span {
        font-size: 13px;
      }

      &:hover {
        span {
          color: ${(props) => props.theme['--color-light']};
        }
      }
    }
  }
  .active {
    background-color: ${(props) => props.theme['--color-bg-active']};
    svg {
      fill: ${(props) => props.theme['--color-light']};
    }
    span {
      color: ${(props) => props.theme['--color-light']};
    }
    border-radius: 4px;
  }
  .liquidation {
    margin: 41px 0;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    text-decoration-line: underline;
    color: ${(props) => props.theme['--color-text-main']};
    text-align: center;
    cursor: pointer;
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const TotalValue = styled.div`
  width: 100%;
  margin-top: 6px;
  margin-bottom: 20px;
  font-weight: 700;

  > div {
    span:first-child {
      word-break: break-all;
      text-align: center;
    }
  }

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const MobileMenu = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    position: relative;
    .ant-select {
      .ant-select-selection {
        background-color: transparent;
        border: none;
        color: ${(props) => props.theme['--color-text-main']};
        font-size: 17px;
        font-weight: 900;
        color: ${(props) => props.theme['--color-text-main']};
        margin-top: 4px;
        i {
          color: ${(props) => props.theme['--color-text-main']};
        }
      }
    }
    .asset-select-menu {
      background-color: ${(props) => props.theme['--color-bg-primary']};
      color: ${(props) => props.theme['--color-text-main']};
    }
  }
`;

const ConnectButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }

  .connect-btn {
    width: 108px;
    height: 26px;
    border-radius: 6px;
    background-color: #4f5269;

    @media only screen and (max-width: 768px) {
      width: 100px;
      margin-right: 20px;
    }

    .MuiButton-label {
      font-size: 13px;
      font-weight: 500;
      color: ${(props) => props.theme['--color-text-sidebar']};
      text-transform: capitalize;

      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const NetworkButton = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 146px;
  z-index: 11;

  @media only screen and (max-width: 768px) {
    margin: 0;
  }
  .red {
    background-color: red !important;
    width: 128px !important;
  }
  .connect-btn {
    width: 108px;
    height: 26px;
    border-radius: 6px;
    background-color: #5fd495;
    @media only screen and (max-width: 768px) {
      width: 100px;
    }
    &:hover {
      background-color: #5fd495;
    }

    .MuiButton-label {
      font-size: 13px;
      font-weight: 500;
      color: ${(props) => props.theme['--color-text-sidebar']};
      text-transform: capitalize;

      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
  }
`;

const Label = styled.span`
  font-size: ${({ size }) => size || 16}px;
  color: ${(props) => props.theme['--color-text-top-light']};
  font-weight: 400;
`;

const SidebarImg = styled.span`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const LinkMenu = styled.div`
  width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-top: 34px;
  margin-bottom: 130px;
  @media only screen and (max-width: 768px) {
    display: none;
  }
  z-index: 11;
  .link-menu-item {
    flex: 1;
    text-align: center;
    a {
      color: ${(props) => props.theme['--color-text-top-light']};
    }
    .img-wrap {
      width: 26px;
      height: 26px;
      border-radius: 50%;
      margin: 0 auto;
      margin-bottom: 6px;
    }
    /* @media only screen and (max-width: 768px) {
      display: flex;
      flex-direction: row;
      margin: 50%;
      padding: 27em;
    } */
  }
`;

const NetworkWrap = styled.div`
  position: absolute;
  right: 55px;
  display: none;
  @media only screen and (max-width: 768px) {
    display: block;
    top: 10px;
  }

  .info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
  }
  .title {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme['--color-text-gray']};
  }
  .desc {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme['--color-text-main']};
  }
  .icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
    margin-top: -2px;
  }
`;

export {
  SidebarWrapper,
  Logo,
  MainMenu,
  FaucetMenu,
  TotalValue,
  MobileMenu,
  ConnectButton,
  NetworkButton,
  Label,
  SidebarImg,
  LinkMenu,
  NetworkWrap,
};
