import bnb from 'assets/img/coins/bnb.png';
import busd from 'assets/img/coins/busd.png';
import cai from 'assets/img/coins/cai.png';
import celo from 'assets/img/coins/celo.png';
import stcelo from 'assets/img/coins/stcelo.png';
// eslint-disable-next-line import/no-duplicates
import chee from 'assets/img/coins/chee.png';
import btc from 'assets/img/coins/btc.png';
// import cbtc_celo from 'assets/img/coins/cbtc_celo.png';
// import wbtc_celo from 'assets/img/coins/wbtc_celo.png';
import eth from 'assets/img/coins/eth.png';
import cusd from 'assets/img/coins/cusd.png';
import ceur from 'assets/img/coins/ceur.png';
import creal from 'assets/img/coins/creal.png';
import dai from 'assets/img/coins/dai.png';
import usdt from 'assets/img/coins/usdt.png';
import usdc from 'assets/img/coins/usdc.png';
import mtr from 'assets/img/coins/mtr.png';
import mtrg from 'assets/img/coins/mtrg.png';
import gusd from 'assets/img/coins/gusd.png';
import plastik from 'assets/img/coins/plastik.png';

import cbnb from 'assets/img/coins/cbnb.png';
import cbusd from 'assets/img/coins/cbusd.png';
import ccelo from 'assets/img/coins/ccelo.png';
import cstcelo from 'assets/img/coins/cstcelo.png';
// eslint-disable-next-line import/no-duplicates
import cchee from 'assets/img/coins/chee.png';
import cbtc from 'assets/img/coins/cbtc.png';
import ceth from 'assets/img/coins/ceth.png';
import ccusd from 'assets/img/coins/ccusd.png';
import cceur from 'assets/img/coins/cceur.png';
import ccreal from 'assets/img/coins/ccreal.png';
import cdai from 'assets/img/coins/cdai.png';
import cusdt from 'assets/img/coins/cusdt.png';
import cusdc from 'assets/img/coins/cusdc.png';
import cmtr from 'assets/img/coins/cmtr.png';
import cmtrg from 'assets/img/coins/cmtrg.png';
import cgusd from 'assets/img/coins/cgusd.png';
import cplastik from 'assets/img/coins/cplastik.png';

const CELO = {
  TOKEN: {
    stcelo: '0xc668583dcbdc9ae6fa3ce46462758188adfdfc24'.toLowerCase(),
    btc: '0xD629eb00dEced2a080B7EC630eF6aC117e614f1b'.toLowerCase(),
    // 'wbtc': '0xBAAB46E28388d2779e6E31Fd00cF0e5Ad95E327B'.toLowerCase(),
    // 'eth': '0x122013fd7dF1C6F636a5bb8f03108E876548b455'.toLowerCase(),
    cusd: '0x765de816845861e75a25fca122bb6898b8b1282a'.toLowerCase(),
    ceur: '0xd8763cba276a3738e6de85b4b3bf5fded6d6ca73'.toLowerCase(),
    // 'dai': '0xE4fE50cdD716522A56204352f00AA110F731932d'.toLowerCase(),
    // 'usdc': '0xef4229c8c3250C675F21BCefa42f58EfbfF6002a'.toLowerCase(),
    weth: '0x66803fb87abd4aac3cbb3fad7c3aa01f6f3fb207'.toLowerCase(),
    usdc: '0x37f750B7cC259A2f741AF45294f6a16572CF5cAd'.toLowerCase(),
    gusd: '0x65C36903fF14950D895E142340FB0273c7D7c632'.toLowerCase(),
    plastik: '0x27cd006548dF7C8c8e9fdc4A67fa05C2E3CA5CF9'.toLowerCase(),
  },
  CTOKEN: {
    celo: '0x9de4171EDc1F69EAD07f7595bD3beD62d9215532'.toLowerCase(),
    stcelo: '0x1258fdb35c1cdd340827e0417b4bbe2356ec756d'.toLowerCase(),
    btc: '0xa14Dee11e440fD31636C76c9FFDDc6b3bE4D8FdB'.toLowerCase(),
    // 'wbtc': '0x1DD52Bf2A40d77B75914aE9F15Fb9EB2f3aB2Fe6'.toLowerCase(),
    // 'eth': '0x7C0F978cE7E15c43500d9b75CCC49F08Cb62167f'.toLowerCase(),
    cusd: '0x7CDc41129b3fDfCB2bA55f616D61ad45b7A8Cc74'.toLowerCase(),
    ceur: '0x859290e834b3ce4D78690cC9E7A73647494fd3bC'.toLowerCase(),
    // 'dai': '0x932eF43096aEC7ddB2F8b3Aa2114030F894d2fa2'.toLowerCase(),
    // 'usdc': '0xAB1E0fcF2260A84E319A9aE19C4639a36960C2c3'.toLowerCase(),
    weth: '0x4e6e2D14508b6f35e35F794aa0e10e103E9627BE'.toLowerCase(),
    usdc: '0xE7275E81bcc39bCF432D808231a82DAceB55eDA2'.toLowerCase(),
    gusd: '0x7Da225ECf3B3D27e20d605B9d126F598594C4C8D'.toLowerCase(),
    plastik: '0xD40964B5096F59987810877C510d6F4598b5Fb46'.toLowerCase(),
  },
};

const BSC = {
  TOKEN: {
    btc: '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c'.toLowerCase(),
    eth: '0x2170Ed0880ac9A755fd29B2688956BD959F933F8'.toLowerCase(),
    usdt: '0x55d398326f99059fF775485246999027B3197955'.toLowerCase(),
    busd: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56'.toLowerCase(),
  },
  CTOKEN: {
    bnb: '0x9437Ea5b08AC7f9dc553861dfE1AA77EE0F2aE69'.toLowerCase(),
    btc: '0x409Caf8e63975f7f1025117D3eabde0B9bf85036'.toLowerCase(),
    eth: '0xe8dA6B8C1e3fA0034e8dBA30600495fd6c498DeE'.toLowerCase(),
    usdt: '0x41e5a435e8c0b31e2F8D9a1275703AbE832b709C'.toLowerCase(),
    busd: '0xCC3f5159FffE5310EdfC6A0642B4D31699Ff5d06'.toLowerCase(),
  },
};

const METER = {
  TOKEN: {
    // btc: '0xc1f6C86ABEe8e2e0B6fd5BD80F0b51fef783635C'.toLowerCase(),
    // eth: '0x79A61D3A28F8c8537A3DF63092927cFa1150Fb3C'.toLowerCase(),
    mtrg: '0x228ebbee999c6a7ad74a6130e81b12f9fe237ba3'.toLowerCase(),
    usdt: '0x5fa41671c48e3c951afc30816947126ccc8c162e'.toLowerCase(),
    busd: '0x24aa189dfaa76c671c279262f94434770f557c35'.toLowerCase(),
    usdc: '0xd86e243fc0007e6226b07c9a50c9d70d78299eb5'.toLowerCase(),
  },
  CTOKEN: {
    // mtr: '0xbF5cffE28d3CbA3376Cd02fF12eBECa43Bc3f14A'.toLowerCase(),
    // btc: '0x0276a80F02FB2a9A8aB691a6a6A24393792e23F8'.toLowerCase(),
    // eth: '0x91c5F639681B22493E002C62835a8E27b76aB61c'.toLowerCase(),
    mtrg: '0x56Ce151c20f775bb657f5fB51c6e08F44D16e1C4'.toLowerCase(),
    mtr: '0xbF5cffE28d3CbA3376Cd02fF12eBECa43Bc3f14A'.toLowerCase(),
    usdt: '0xeEb9497eCfB20138960775362535881beC12401A'.toLowerCase(),
    busd: '0xa35874fa4aBa0A44226D5C038ec7caFA18B7740c'.toLowerCase(),
    usdc: '0x4B3790aaa7b131418dD3C4C7947E4d54667175C1'.toLowerCase(),
  },
};

const CONTRACT_BASE_TOKEN = {
  CELO: 'celo',
  BSC: 'bnb',
  METER: 'mtr',
};

const BLOCKS_PER_MINUTE = {
  CELO: 12,
  BSC: 20,
  METER: 24,
  POLYGON: 30,
  ETH: 4,
};

const CONTRACT_CHEE_TOKEN_ADDRESS = {
  CELO: CELO.TOKEN.chee,
  BSC: BSC.TOKEN.chee,
  METER: METER.TOKEN.chee,
};

const CONTRACT_CAI_TOKEN_ADDRESS = {
  // REACT_APP_TEST_CAI_TOKEN_ADDRESS
  CELO: '',
  BSC: '',
  METER: '',
};

// Contract ABI
const CONTRACT_TOKEN_ADDRESS = {
  CELO: {
    celo: {
      id: 'celo',
      symbol: 'CELO',
      displayName: 'CELO',
      address: CELO.TOKEN.celo,
      asset: celo,
      casset: ccelo,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: '',
      linkLabel: '',
    },
    stcelo: {
      id: 'stcelo',
      symbol: 'stCELO',
      displayName: 'stCELO',
      address: CELO.TOKEN.stcelo,
      asset: stcelo,
      casset: cstcelo,
      decimal: 18,
      category: 'Staked',
      isNew: false,
      isReFi: false,
      link: 'https://app.stcelo.xyz',
      linkLabel: 'stCELO.xyz',
    },
    // chee: {
    //   id: 'chee',
    //   symbol: 'CHEE',
    //   address: CELO.TOKEN.chee,
    //   asset: chee,
    //   casset: cchee,
    //   decimal: 18,
    // },
    btc: {
      id: 'btc',
      symbol: 'BTC',
      displayName: 'BTC',
      address: CELO.TOKEN.btc,
      asset: btc,
      casset: cbtc,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: 'https://wrapped.com',
      linkLabel: 'Wrapped.com',
    },
    // 'wbtc': {
    //   id: 'wbtc',
    //   symbol: 'WBTC',
    //   displayName: 'WBTC',
    //   address: CELO.TOKEN.wbtc,
    //   asset: wbtc_celo,
    //   casset: cbtc,
    //   decimal: 8,
    //   category: 'ERC20',
    //   isNew: false,
    //   isReFi: false,
    //   link: 'https://optics.app',
    //   linkLabel: 'Optics.app',
    // },
    // 'eth': {
    //   id: 'eth',
    //   symbol: 'ETH',
    //   displayName: 'WETH',
    //   address: CELO.TOKEN.eth,
    //   asset: eth,
    //   casset: ceth,
    //   decimal: 18,
    //   category: 'ERC20',
    //   isNew: false,
    //   isReFi: false,
    //   link: 'https://optics.app',
    //   linkLabel: 'Optics.app',
    // },
    weth: {
      id: 'weth',
      symbol: 'WETH',
      displayName: 'WETH',
      address: CELO.TOKEN.weth,
      asset: eth,
      casset: ceth,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: 'https://www.portalbridge.com/#/transfer',
      linkLabel: 'Portalbridge',
    },
    cusd: {
      id: 'cusd',
      symbol: 'cUSD',
      displayName: 'cUSD',
      address: CELO.TOKEN.cusd,
      asset: cusd,
      casset: ccusd,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: '',
      linkLabel: '',
    },
    // 'usdc': {
    //   id: 'usdc',
    //   symbol: 'USDC',
    //   displayName: 'USDC',
    //   address: CELO.TOKEN.usdc,
    //   asset: usdc,
    //   casset: cusdc,
    //   decimal: 6,
    //   category: 'ERC20',
    //   isNew: false,
    //   isReFi: false,
    //   link: 'https://optics.app',
    //   linkLabel: 'Optics.app',
    // },
    usdc: {
      id: 'usdc',
      symbol: 'USDC',
      displayName: 'USDC',
      address: CELO.TOKEN.usdc,
      asset: usdc,
      casset: cusdc,
      decimal: 6,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: 'https://www.portalbridge.com/#/transfer',
      linkLabel: 'Portalbridge',
    },
    // ceur: {
    //   id: 'ceur',
    //   symbol: 'cEUR',
    //   address: CELO.TOKEN.ceur,
    //   asset: ceur,
    //   casset: cceur,
    //   decimal: 18,
    // },
    // creal: {
    //   id: 'creal',
    //   symbol: 'cREAL',
    //   address: CELO.TOKEN.creal,
    //   asset: creal,
    //   casset: ccreal,
    //   decimal: 18,
    // },
    // dai: {
    //   id: 'dai',
    //   symbol: 'DAI',
    //   address: CELO.TOKEN.dai,
    //   asset: dai,
    //   casset: cdai,
    //   decimal: 18,
    // },
    gusd: {
      id: 'gusd',
      symbol: 'gUSD',
      displayName: 'gUSD',
      address: CELO.TOKEN.gusd,
      asset: gusd,
      casset: cgusd,
      decimal: 18,
      category: 'ERC20',
      isNew: true,
      isReFi: false,
      forceDisplay: true,
      link: '',
      linkLabel: '',
    },
    plastik: {
      id: 'plastik',
      symbol: 'PLASTIK',
      displayName: 'PLASTIK',
      address: CELO.TOKEN.plastik,
      asset: plastik,
      casset: cplastik,
      decimal: 9,
      category: 'ERC20',
      isNew: true,
      isReFi: false,
      forceDisplay: true,
      link: '',
      linkLabel: '',
    },
  },
  BSC: {
    bnb: {
      id: 'bnb',
      symbol: 'BNB',
      address: BSC.TOKEN.bnb,
      asset: bnb,
      casset: cbnb,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: '',
      linkLabel: '',
    },
    btcb: {
      id: 'btcb',
      symbol: 'BTC',
      address: BSC.TOKEN.btc,
      asset: btc,
      casset: cbtc,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: '',
      linkLabel: '',
    },
    eth: {
      id: 'eth',
      symbol: 'ETH',
      address: BSC.TOKEN.eth,
      asset: eth,
      casset: ceth,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: '',
      linkLabel: '',
    },
    usdt: {
      id: 'usdt',
      symbol: 'USDT',
      address: BSC.TOKEN.usdt,
      asset: usdt,
      casset: cusdt,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: '',
      linkLabel: '',
    },
    busd: {
      id: 'busd',
      symbol: 'BUSD',
      address: BSC.TOKEN.busd,
      asset: busd,
      casset: cbusd,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: '',
      linkLabel: '',
    },
  },
  METER: {
    'mtrg': {
      id: 'mtrg',
      symbol: 'MTRG',
      address: METER.TOKEN.mtrg,
      asset: mtrg,
      casset: cmtrg,
      decimal: 18,
      category: 'ERC20',
      isNew: true,
      isReFi: false,
      link: '',
      linkLabel: '',
    },
    'mtr': {
      id: 'mtr',
      symbol: 'MTR',
      address: METER.TOKEN.mtr,
      asset: mtr,
      casset: cmtr,
      decimal: 18,
      category: 'ERC20',
      isNew: true,
      isReFi: false,
      link: '',
      linkLabel: '',
    },
    'usdt.eth': {
      id: 'usdt.eth',
      symbol: 'USDT',
      displayName: 'USDT.ETH',
      address: METER.TOKEN.usdt,
      asset: usdt,
      casset: cusdt,
      decimal: 6,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: 'https://passport.meter.io/#/',
      linkLabel: 'Meter Passport',
    },
    'busd.bsc': {
      id: 'busd.bsc',
      symbol: 'BUSD',
      displayName: 'BUSD.BSC',
      address: METER.TOKEN.busd,
      asset: busd,
      casset: cbusd,
      decimal: 18,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: 'https://passport.meter.io/#/',
      linkLabel: 'Meter Passport',
    },
    'usdc.eth': {
      id: 'usdc.eth',
      symbol: 'USDC',
      displayName: 'USDC.ETH',
      address: METER.TOKEN.usdc,
      asset: usdc,
      casset: cusdc,
      decimal: 6,
      category: 'ERC20',
      isNew: false,
      isReFi: false,
      link: 'https://passport.meter.io/#/',
      linkLabel: 'Meter Passport',
    },
  },
};

const CONTRACT_CTOKEN_ADDRESS = {
  CELO: {
    celo: {
      id: 'celo',
      symbol: 'cCELO',
      address: CELO.CTOKEN.celo,
      decimal: 8,
    },
    stcelo: {
      id: 'stcelo',
      symbol: 'cstCelo',
      address: CELO.CTOKEN.stcelo,
      decimal: 8,
    },
    btc: {
      id: 'btc',
      symbol: 'cBTC',
      address: CELO.CTOKEN.btc,
      decimal: 8,
    },
    // 'wbtc': {
    //   id: 'wbtc',
    //   symbol: 'cWBTC',
    //   address: CELO.CTOKEN.wbtc,
    //   decimal: 8,
    // },
    // 'eth': {
    //   id: 'eth',
    //   symbol: 'cETH',
    //   address: CELO.CTOKEN.eth,
    //   decimal: 8,
    // },
    weth: {
      id: 'weth',
      symbol: 'cWETH',
      address: CELO.CTOKEN.weth,
      decimal: 8,
    },
    cusd: {
      id: 'cusd',
      symbol: 'cCUSD',
      address: CELO.CTOKEN.cusd,
      decimal: 8,
    },
    // 'usdc': {
    //   id: 'usdc',
    //   symbol: 'cUSDC',
    //   address: CELO.CTOKEN.usdc,
    //   decimal: 8,
    // },
    usdc: {
      id: 'usdc',
      symbol: 'cUSDC',
      address: CELO.CTOKEN.usdc,
      decimal: 8,
    },
    // 'xusdc-cusd': {
    //   id: 'xusdc-cusd',
    //   symbol: 'cxLP',
    //   address: CELO.CTOKEN['xusdc-cusd'],
    //   decimal: 8,
    // },
    // 'ulp': {
    //   id: 'ulp',
    //   symbol: 'cULP',
    //   address: CELO.CTOKEN.ulp,
    //   decimal: 8,
    // },
    // ceur: {
    //   id: 'ceur',
    //   symbol: 'cCEUR',
    //   address: CELO.CTOKEN.ceur,
    //   decimal: 8,
    // },
    // creal: {
    //   id: 'creal',
    //   symbol: 'cCREAL',
    //   address: CELO.CTOKEN.creal,
    //   decimal: 8,
    // },
    // dai: {
    //   id: 'dai',
    //   symbol: 'cdai',
    //   address: CELO.CTOKEN.dai,
    //   decimal: 8,
    // },
    gusd: {
      id: 'gusd',
      symbol: 'cgusd',
      address: CELO.CTOKEN.gusd,
      decimal: 8,
    },
    plastik: {
      id: 'plastik',
      symbol: 'cplastik',
      address: CELO.CTOKEN.plastik,
      decimal: 8,
    },
  },
  BSC: {
    bnb: {
      id: 'bnb',
      symbol: 'cBNB',
      address: BSC.CTOKEN.bnb,
      decimal: 8,
    },
    // chee: {
    //   id: 'chee',
    //   symbol: 'cCHEE',
    //   address: BSC.CTOKEN.chee,
    //   decimal: 8,
    // },
    btcb: {
      id: 'btcb',
      symbol: 'cBTC',
      address: BSC.CTOKEN.btc,
      decimal: 8,
    },
    eth: {
      id: 'eth',
      symbol: 'cETH',
      address: BSC.CTOKEN.eth,
      decimal: 8,
    },
    usdt: {
      id: 'usdt',
      symbol: 'cUSDT',
      address: BSC.CTOKEN.usdt,
      decimal: 8,
    },
    busd: {
      id: 'busd',
      symbol: 'cBUSD',
      address: BSC.CTOKEN.busd,
      decimal: 8,
    },
  },
  METER: {
    'mtrg': {
      id: 'mtrg',
      symbol: 'cMTRG',
      address: METER.CTOKEN.mtrg,
      decimal: 8,
    },
    'mtr': {
      id: 'mtr',
      symbol: 'cMTR',
      address: METER.CTOKEN.mtr,
      decimal: 8,
    },
    'usdt.eth': {
      id: 'usdt.eth',
      symbol: 'cUSDT',
      address: METER.CTOKEN.usdt,
      decimal: 8,
    },
    'busd.bsc': {
      id: 'busd.bsc',
      symbol: 'cBUSD',
      address: METER.CTOKEN.busd,
      decimal: 8,
    },
    'usdc.eth': {
      id: 'usdc.eth',
      symbol: 'cUSDC',
      address: METER.CTOKEN.usdc,
      decimal: 8,
    },
  },
};

const CONTRACT_CHEE_STORE_ADDRESS = {
  // REACT_APP_TEST_CHEE_STORE_ADDRESS
  CELO: '',
  BSC: '',
  METER: '',
};

const CONTRACT_CAI_UNITROLLER_ADDRESS = {
  // REACT_APP_TEST_CAI_UNITROLLER_ADDRESS
  CELO: '',
  BSC: '',
  METER: '',
};

const CONTRACT_CAI_VAULT_ADDRESS = {
  // REACT_APP_TEST_CAI_VAULT_ADDRESS
  CELO: '',
  BSC: '',
  METER: '',
};

const CONTRACT_COMPTROLLER_ADDRESS = {
  // REACT_APP_TEST_COMPTROLLER_ADDRESS
  CELO: '0x9BD4Fd10b531ae07437676dfE3FA6f505032CB64',
  BSC: '0x0E00Ae24B84148ee93Ce2Cf4F52dE277C91B0B72',
  METER: '0xcc0BfaD4e684023B18e0adfEcaEDd800D91dc8B5',
};

const CONTRACT_VAULT_ADDRESS = {
  // REACT_APP_TEST_VAULT_ADDRESS
  CELO: '',
  BSC: '',
  METER: '',
};

const CONTRACT_PRICE_ORACLE_ADDRESS = {
  // REACT_APP_TEST_PRICE_ORACLE_ADDRESS
  CELO: '0x813a5fD7e82FDFf735Adbf8f43343421ab39D8f0',
  BSC: '0xc58A916B48974787E996F5c464A3aede2dFd8677',
  METER: '0xA6c0A739010c76dBd668C4f839371a19Ff19Bf37',
};

const CONTRACT_CHEE_LENS_ADDRESS = {
  // REACT_APP_TEST_CHEE_LENS_ADDRESS
  CELO: '0xbA527deAcC4f5001fDd8A9DbDFb4cA7566dA99f5',
  BSC: '0xA59FFCA1cDCcC10DB56be736D281A93B6eD1A13B',
  METER: '0x267E10BB464d3937613F60655163D11392829E03',
};

const CONTRACT_FAUCET_ADDRESS = {
  // REACT_APP_TEST_FAUCET_ADDRESS
  CELO: '',
  BSC: '',
  METER: '',
};

const EXPLORER_LINK = {
  // REACT_APP_EXPLORER
  CELO: 'https://celoscan.io/',
  BSC: 'https://bscscan.com/',
  METER: 'https://scan.meter.io/',
};

const SUBGRAPH_URL = {
  CELO: 'https://api.thegraph.com/subgraphs/name/cheenicey/chee-subgraph',
  BSC: 'https://api.thegraph.com/subgraphs/name/cheenicey/chee-subgraph-bsc',
  METER: 'https://graph.meter.io/subgraphs/name/cheenicey/chee-subgraph-meter',
};

const VENFT_URL = {
  ETH: 'https://thegraph.com/hosted-service/subgraph/havoc19/cheeizi',
};

const GRAPH_TICKER = {
  // REACT_APP_GRAPH_TICKER
  CELO: '1hr',
  BSC: '1hr',
  METER: '1hr',
};

const VENFTPRICE = 2;
const VEPOOLS_ADDRESS_LEND = {
  ETH: '0x3bE9Df69BD7266624FD4B42B6A9f7B983f662e9d',
  CELO: '0x2757aB26b28Eba44d0Df6EC8D7dE16906760c914',
};
const VEPOOLS_ADDRESS_VETOKEN = {
  ETH: '0x9ad37205d608b8b219e6a2573f922094cec5c200',
  CELO: '0xa561D40Add7Df0f606b130761E3e2Aa3336BD784',
};
const VEPOOLS_ADDRESS_VENFT = {
  ETH: '0xb56a454d8dac2ad4cb82337887717a2a427fcd00',
  CELO: '0xF25dd6FA4544b4021cb16c027ac9Ccbb6c0d6772',
};
const VEPOOLS_ADDRESS_CHEETOKEN = {
  ETH: '0xFB82E819aEfdA43fC141F0f424a08d41c8fE149d',
  CELO: '0x3f9d2879FBfcF6072833e243931Fe1BBcb0E1af5',
};

const VETOKENASSET = {
  ETH: 'veiZi',
  CELO: 'veTest',
};

const CHEETOKEN = {
  ETH: 'cheeiZi',
  CELO: 'cheeTest',
};

const VETOKENNAME = {
  ETH: 'iZi',
  CELO: 'Test',
};

// Start distribution time & Amount of distribution per unit time
const DISTRIBUTED = {
  CELO: {
    celo: { distributedTime: 1644150779242, perDay: 0 },
    stcelo: { distributedTime: 1644150779242, perDay: 0 },
    chee: { distributedTime: 1644150779242, perDay: 0 },
    btc: { distributedTime: 1644150779242, perDay: 0 },
    // 'wbtc': { distributedTime: 1644150779242, perDay: 0 },
    // 'eth': { distributedTime: 1644150779242, perDay: 0 },
    cusd: { distributedTime: 1644150779242, perDay: 0 },
    // 'usdc': { distributedTime: 1644150779242, perDay: 0 },
    weth: { distributedTime: 1644150779242, perDay: 0 },
    usdc: { distributedTime: 1644150779242, perDay: 0 },
    // 'xusdc-cusd': { distributedTime: 1644150779242, perDay: 0 },
    // 'ulp': { distributedTime: 1644150779242, perDay: 0 },
    // ceur: { distributedTime: 1644150779242, perDay: 0 },
    // creal: { distributedTime: 1644150779242, perDay: 0 },
    // dai: { distributedTime: 1644150779242, perDay: 0 },
    gusd: { distributedTime: 1644150779242, perDay: 0 },
    plastik: { distributedTime: 1644150779242, perDay: 0 },
  },
  BSC: {
    bnb: { distributedTime: 1644150779242, perDay: 0 },
    chee: { distributedTime: 1644150779242, perDay: 0 },
    btcb: { distributedTime: 1644150779242, perDay: 0 },
    eth: { distributedTime: 1644150779242, perDay: 0 },
    usdt: { distributedTime: 1644150779242, perDay: 0 },
    busd: { distributedTime: 1644150779242, perDay: 0 },
  },
  METER: {
    // 'mtr': { distributedTime: 1644150779242, perDay: 0 },
    // 'chee': { distributedTime: 1644150779242, perDay: 0 },
    // 'btc': { distributedTime: 1644150779242, perDay: 0 },
    // 'eth': { distributedTime: 1644150779242, perDay: 0 },
    'mtrg': { distributedTime: 1644150779242, perDay: 0 },
    'mtr': { distributedTime: 1644150779242, perDay: 0 },
    'usdt.eth': { distributedTime: 1644150779242, perDay: 0 },
    'busd.bsc': { distributedTime: 1644150779242, perDay: 0 },
    'usdc.eth': { distributedTime: 1644150779242, perDay: 0 },
  },
  POLYGON: {
    matic: { distributedTime: 1644150779242, perDay: 0 },
    chee: { distributedTime: 1644150779242, perDay: 0 },
    btc: { distributedTime: 1644150779242, perDay: 0 },
    eth: { distributedTime: 1644150779242, perDay: 0 },
    usdt: { distributedTime: 1644150779242, perDay: 0 },
  },
};

const REMAINING = 1699629834;

export {
  BLOCKS_PER_MINUTE,
  CONTRACT_BASE_TOKEN,
  CONTRACT_CAI_TOKEN_ADDRESS,
  CONTRACT_TOKEN_ADDRESS,
  CONTRACT_CTOKEN_ADDRESS,
  CONTRACT_CAI_UNITROLLER_ADDRESS,
  CONTRACT_CAI_VAULT_ADDRESS,
  CONTRACT_VAULT_ADDRESS,
  CONTRACT_COMPTROLLER_ADDRESS,
  CONTRACT_PRICE_ORACLE_ADDRESS,
  CONTRACT_CHEE_LENS_ADDRESS,
  CONTRACT_CHEE_STORE_ADDRESS,
  CONTRACT_CHEE_TOKEN_ADDRESS,
  CONTRACT_FAUCET_ADDRESS,
  EXPLORER_LINK,
  SUBGRAPH_URL,
  GRAPH_TICKER,
  REMAINING,
  DISTRIBUTED,
  VEPOOLS_ADDRESS_LEND,
  VEPOOLS_ADDRESS_VETOKEN,
  VEPOOLS_ADDRESS_VENFT,
  VEPOOLS_ADDRESS_CHEETOKEN,
  VENFT_URL,
  VENFTPRICE,
  VETOKENASSET,
  CHEETOKEN,
  VETOKENNAME,
};
