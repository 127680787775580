import styled from 'styled-components';

const ComingSoonWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: rgba(12, 17, 17, 0.6);
  backdrop-filter: blur(9px);
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    margin-top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 24px;
    color: #c6dbda;
  }
  .desc {
    margin-top: 10px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #c6dbda;
  }
`;
export { ComingSoonWrapper };
