import celoImg from 'assets/img/networks/celo.png';
import bscImg from 'assets/img/networks/bsc.png';
import meterImg from 'assets/img/networks/meter.png';
import polygonImg from 'assets/img/networks/polygon.png';
import ethImg from 'assets/img/networks/eth.png';

export const NET_IMG = {
  METER: meterImg,
  CELO: celoImg,
  BSC: bscImg,
  POLYGON: polygonImg,
  ETH: ethImg,
};
