import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import MainLayout from 'components/layout/wrap';
import { connectAccount, accountActionCreators } from 'modules';
import LoadingSpinner from 'components/common/loadingSpinner';
import toast from 'components/common/toast';
import {
  getTokenContract,
  getCTokenContract,
  getComptrollerContract,
  getCaiControllerContract,
  getCaiTokenContract,
  methods,
  getWeb3,
} from 'utilities/contractService';
import BigNumber from 'bignumber.js';
import {
  CONTRACT_BASE_TOKEN,
  CONTRACT_CAI_UNITROLLER_ADDRESS,
  CONTRACT_COMPTROLLER_ADDRESS,
  CONTRACT_TOKEN_ADDRESS,
  CONTRACT_CTOKEN_ADDRESS,
  CONTRACT_CAI_TOKEN_ADDRESS,
} from 'constants/address';
import { FormattedMessage } from 'react-intl';
import { Card, PageTitle } from 'components/common/style';
import { Col, Row as AntdRow } from 'antd';
import CustomCard from 'components/common/card';
import { formatNumber, getBigNumber } from 'utilities/number';
import { ASSETS_STATUS_MAP } from 'constants/enum';
import CollateralConfirmModal from 'components/common/collateralConfirmModal';
import Market from './components/market';
import WalletBalance from './components/walletBalance';
import Overview from './components/overview';
import BorrowLimit from './components/borrowLimit';
import CaiInfo from './components/caiInfo';
import CoinInfo from './components/coinInfo';
import Claim from './components/claim';
import MoreInfo from './components/more';
import SupplyMarket from './components/market/supplyMarket';
import { DashboardWrapper, SpinnerWrapper, Row, Column, SupplyWrap } from './style';

function Dashboard({ settings, setSetting }) {
  const [isMarketInfoUpdating, setMarketInfoUpdating] = useState(false);
  const [canShowPage, setCanShowPage] = useState(false);
  const [suppliedAssets, setSuppliedAssets] = useState([]);
  const [borrowedAssets, setBorrowedAssets] = useState([]);
  const [allTableData, setAllTableData] = useState([]);
  const [cardListData, setCardListData] = useState([]);
  const [isCollateralEnalbe, setIsCollateralEnable] = useState(true);
  const [isOpenCollateralConfirm, setIsCollateralConfirm] = useState(false);

  useEffect(() => {
    setCanShowPage(settings.selectedAddress && !settings.accountLoading && !settings.wrongNetwork);
  }, [settings.selectedAddress, settings.accountLoading, settings.wrongNetwork]);

  const updateMarketInfo = async () => {
    const accountAddress = settings.selectedAddress;
    if (!accountAddress || !settings.decimals || !settings.markets?.length || isMarketInfoUpdating) {
      return;
    }
    const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[settings.networkName]);

    // const caiControllerContract = getCaiControllerContract(
    //   CONTRACT_CAI_UNITROLLER_ADDRESS[settings.networkName]
    // );
    // const caiContract = getCaiTokenContract(CONTRACT_CAI_TOKEN_ADDRESS[settings.networkName]);

    setMarketInfoUpdating(true);

    try {
      let cheeBalance = getBigNumber(0);
      // let [userCaiBalance, userCaiMinted] = await Promise.all([
      //   methods.call(caiContract.methods.balanceOf, [accountAddress]),
      //   methods.call(appContract.methods.mintedCAIs, [accountAddress]),
      //   // methods.call(caiControllerContract.methods.getMintableCAI, [
      //   //   accountAddress,
      //   // ]),
      //   // methods.call(caiContract.methods.allowance, [
      //   //   accountAddress,
      //   //   CONTRACT_CAI_UNITROLLER_ADDRESS,
      //   // ]),
      // ]);

      let userCaiBalance = 0;
      let userCaiMinted = 0;
      userCaiBalance = getBigNumber(userCaiBalance).div(getBigNumber(10).pow(18));
      userCaiMinted = getBigNumber(userCaiMinted).div(getBigNumber(10).pow(18));

      let mintableCai = 0;
      let allowBalance = 0;
      mintableCai = getBigNumber(mintableCai).div(getBigNumber(10).pow(18));
      allowBalance = getBigNumber(allowBalance).div(getBigNumber(10).pow(18));

      const userCaiEnabled = allowBalance.isGreaterThanOrEqualTo(userCaiMinted);
      const assetsIn = await methods.call(appContract.methods.getAssetsIn, [accountAddress]);

      let totalBorrowLimit = getBigNumber(0);
      let totalBorrowBalance = getBigNumber(userCaiMinted);

      const assetList = await Promise.all(
        Object.values(CONTRACT_TOKEN_ADDRESS[settings.networkName]).map(async (item, index) => {
          let market = settings.markets.find(
            (ele) =>
              ele.symbol.toLowerCase() === CONTRACT_CTOKEN_ADDRESS[settings.networkName][item.id].symbol.toLowerCase()
          );
          if (!market) market = {};
          const asset = {
            key: index,
            id: item.id,
            img: item.asset,
            vimg: item.casset,
            name: market.underlyingSymbol || '',
            displayName: item.displayName || market.underlyingSymbol,
            category: item.category || '',
            isNew: item.isNew || false,
            isReFi: item.isReFi || false,
            forceDisplay: item.forceDisplay || false,
            link: item.link || '',
            linkLabel: item.linkLabel || '',
            symbol: market.underlyingSymbol || '',
            tokenAddress: market.underlyingAddress,
            csymbol: market.symbol,
            ctokenAddress: CONTRACT_CTOKEN_ADDRESS[settings.networkName][item.id].address,
            supplyApy: getBigNumber(market.supplyApy || 0),
            borrowApy: getBigNumber(market.borrowApy || 0),
            cheeSupplyApy: getBigNumber(market.supplyCheeApy || 0),
            cheeBorrowApy: getBigNumber(market.borrowCheeApy || 0),
            collateralFactor: getBigNumber(market.collateralFactor || 0).div(1e18),
            tokenPrice: getBigNumber(market.tokenPrice || 0),
            liquidity: getBigNumber(market.liquidity || 0),
            borrowCaps: getBigNumber(market.borrowCaps || 0),
            borrowAble: market.borrowAble,
            totalBorrows: getBigNumber(market.totalBorrows2 || 0),
            walletBalance: getBigNumber(0),
            supplyBalance: getBigNumber(0),
            borrowBalance: getBigNumber(0),
            isEnabled: false,
            collateral: false,
            percentOfLimit: '0',
          };

          const tokenDecimal = settings.decimals[item.id] ? settings.decimals[item.id].token : 18;
          const cTokenContract = getCTokenContract(item.id, CONTRACT_CTOKEN_ADDRESS[settings.networkName]);
          asset.collateral = assetsIn.map((item2) => item2.toLowerCase()).includes(asset.ctokenAddress.toLowerCase());

          let borrowBalance;
          let supplyBalance;
          let totalBalance;

          if (item.id !== CONTRACT_BASE_TOKEN[settings.networkName]) {
            // Non-base tokens
            const tokenContract = getTokenContract(item.id, CONTRACT_TOKEN_ADDRESS[settings.networkName]);
            const [walletBalance, allowBalance2, borrowBalanceCurrent, balanceOfUnderlying, balance] =
              await Promise.all([
                methods.call(tokenContract.methods.balanceOf, [accountAddress]),
                methods.call(tokenContract.methods.allowance, [accountAddress, asset.ctokenAddress]),
                methods.call(cTokenContract.methods.borrowBalanceCurrent, [accountAddress]),
                methods.call(cTokenContract.methods.balanceOfUnderlying, [accountAddress]),
                // methods.call(cTokenContract.methods.getAccountSnapshot, [accountAddress]), // outdated method
                methods.call(cTokenContract.methods.balanceOf, [accountAddress]),
              ]);
            // console.log(item.id, walletBalance, allowBalance2, borrowBalanceCurrent, balanceOfUnderlying, balance);

            // supplyBalance = getBigNumber(snapshot[1]).times(getBigNumber(snapshot[3])).div(getBigNumber(10).pow(18));
            // // eslint-disable-next-line prefer-destructuring
            // borrowBalance = snapshot[2];
            supplyBalance = balanceOfUnderlying;
            borrowBalance = borrowBalanceCurrent;
            totalBalance = balance;

            asset.walletBalance = getBigNumber(walletBalance).div(getBigNumber(10).pow(tokenDecimal));

            if (asset.id === 'chee') {
              cheeBalance = asset.walletBalance;
            }

            // allowance
            asset.isEnabled = getBigNumber(allowBalance2)
              .div(getBigNumber(10).pow(tokenDecimal))
              .isGreaterThan(asset.walletBalance);
          } else {
            // base tokens
            const web3 = getWeb3();
            const [borrowBalanceCurrent, balanceOfUnderlying, balance, walletBalance] = await Promise.all([
              methods.call(cTokenContract.methods.borrowBalanceCurrent, [accountAddress]),
              methods.call(cTokenContract.methods.balanceOfUnderlying, [accountAddress]),
              methods.call(cTokenContract.methods.balanceOf, [accountAddress]),
              web3.eth.getBalance(accountAddress),
            ]);
            // supplyBalance = getBigNumber(snapshot[1]).times(getBigNumber(snapshot[3])).div(getBigNumber(10).pow(18));
            // // eslint-disable-next-line prefer-destructuring
            // borrowBalance = snapshot[2];
            supplyBalance = balanceOfUnderlying;
            borrowBalance = borrowBalanceCurrent;
            totalBalance = balance;

            if (window.ethereum || window.BinanceChain) {
              asset.isEnabled = true;
              asset.walletBalance = getBigNumber(walletBalance).div(getBigNumber(10).pow(tokenDecimal));
            }
          }

          // supply balance
          asset.supplyBalance = getBigNumber(supplyBalance).div(getBigNumber(10).pow(tokenDecimal));

          // borrow balance
          asset.borrowBalance = getBigNumber(borrowBalance).div(getBigNumber(10).pow(tokenDecimal));

          // percent of limit
          asset.percentOfLimit = getBigNumber(settings.totalBorrowLimit).isZero()
            ? '0'
            : asset.borrowBalance
                .times(asset.tokenPrice)
                .div(settings.totalBorrowLimit)
                .times(100)
                // .dp(2, 1)
                .toString(10);

          // hypotheticalLiquidity
          asset.hypotheticalLiquidity = await methods.call(appContract.methods.getHypotheticalAccountLiquidity, [
            accountAddress,
            asset.ctokenAddress,
            totalBalance,
            0,
          ]);

          const supplyBalanceUSD = asset.supplyBalance.times(asset.tokenPrice);
          const borrowBalanceUSD = asset.borrowBalance.times(asset.tokenPrice);

          totalBorrowBalance = totalBorrowBalance.plus(borrowBalanceUSD);
          if (asset.collateral) {
            totalBorrowLimit = totalBorrowLimit.plus(supplyBalanceUSD.times(asset.collateralFactor));
          }
          return asset;
        })
      ).catch((e) => {
        Promise.resolve([]);
      });

      setSetting({
        assetList,
        userCaiMinted,
        totalBorrowLimit: totalBorrowLimit.toString(10),
        totalBorrowBalance,
        userCaiBalance,
        userCaiEnabled,
        userCHEEBalance: cheeBalance,
        mintableCai,
      });
    } finally {
      setMarketInfoUpdating(false);
    }
  };
  const handleToggleCollateral = (r) => {
    const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[settings.networkName]);
    if (r && settings.selectedAddress && r.borrowBalance.isZero()) {
      if (!r.collateral) {
        setIsCollateralEnable(false);
        setIsCollateralConfirm(true);
        methods
          .send(appContract.methods.enterMarkets, [[r.ctokenAddress]], settings.selectedAddress)
          .then(() => {
            setIsCollateralConfirm(false);
          })
          .catch(() => {
            setIsCollateralConfirm(false);
          });
      } else if (+r.hypotheticalLiquidity['1'] > 0 || +r.hypotheticalLiquidity['2'] === 0) {
        setIsCollateralEnable(true);
        setIsCollateralConfirm(true);
        methods
          .send(appContract.methods.exitMarket, [r.ctokenAddress], settings.selectedAddress)
          .then(() => {
            setIsCollateralConfirm(false);
          })
          .catch(() => {
            setIsCollateralConfirm(false);
          });
      } else {
        toast.error({
          title: `Collateral Required`,
          description:
            'You need to set collateral at least one asset for your borrowed assets. Please repay all borrowed asset or set other asset as collateral.',
        });
      }
    } else {
      toast.error({
        title: `Collateral Required`,
        description:
          'You need to set collateral at least one asset for your borrowed assets. Please repay all borrowed asset or set other asset as collateral.',
      });
    }
  };

  const handleAccountChange = async () => {
    await updateMarketInfo().catch(() => {});
    setSetting({
      accountLoading: false,
    });
  };

  useEffect(() => {
    if (settings.selectedAddress) {
      handleAccountChange();
    }
  }, [settings.markets, settings.selectedAddress]);

  const updateMarketTable = async () => {
    const tempAllTableData = [];
    if (!settings.assetList || settings.assetList.length === 0) {
      setAllTableData([]);
      setCardListData([]);
      return;
    }
    settings.assetList.forEach((item) => {
      if (!item) return;
      const temp = {
        ...item,
        supplyApy: getBigNumber(item.supplyApy),
        borrowApy: getBigNumber(item.borrowApy),
        walletBalance: getBigNumber(item.walletBalance),
        supplyBalance: getBigNumber(item.supplyBalance),
        cTokenBalance: getBigNumber(item.cTokenBalance),
        borrowBalance: getBigNumber(item.borrowBalance),
        collateralFactor: getBigNumber(item.collateralFactor),
        tokenPrice: getBigNumber(item.tokenPrice),
        liquidity: getBigNumber(item.liquidity),
        status: 1,
      };
      tempAllTableData.push(temp);
    });

    const filteredAllTableData = tempAllTableData
      .filter((item) => !item.collateralFactor.isZero() || item.forceDisplay)
      .map((element) => {
        if (element.supplyBalance.isZero() && element.borrowBalance.isZero()) {
          return {
            ...element,
            status: ASSETS_STATUS_MAP.NONE,
          };
        }
        if (element.supplyBalance.isZero() && !element.borrowBalance.isZero()) {
          return {
            ...element,
            status: ASSETS_STATUS_MAP.BORROW,
          };
        }
        if (!element.supplyBalance.isZero() && element.borrowBalance.isZero()) {
          return {
            ...element,
            status: ASSETS_STATUS_MAP.SUPPLY,
          };
        }
        return {
          ...element,
        };
      });
    setAllTableData(filteredAllTableData);

    const cardList = filteredAllTableData.filter((item) => item.status !== ASSETS_STATUS_MAP.NONE);
    // if supplyBalance && borrowBalance, display both status
    filteredAllTableData.forEach((item) => {
      if (!item.supplyBalance.isZero() && !item.borrowBalance.isZero()) {
        cardList.push({
          ...item,
          status: item.status === ASSETS_STATUS_MAP.SUPPLY ? ASSETS_STATUS_MAP.BORROW : ASSETS_STATUS_MAP.SUPPLY,
        });
      }
    });
    setCardListData(cardList);
  };

  useEffect(() => {
    updateMarketTable();
  }, [settings.assetList]);

  return (
    <MainLayout title={<FormattedMessage id="Dashboard" />}>
      <DashboardWrapper className="flex">
        {/* {!canShowPage ? (
          <SpinnerWrapper>
            <LoadingSpinner />
          </SpinnerWrapper>
        ) : (

        )} */}
        <Row>
          <PageTitle>
            <FormattedMessage id="My Assets" />
          </PageTitle>
          <AntdRow
            gutter={[
              { xs: 0, sm: 20 },
              { xs: 10, sm: 10, md: 10, lg: 0 },
            ]}
          >
            <Col xs={24} sm={24} md={24} lg={12}>
              <WalletBalance />
            </Col>
            <Col xs={24} sm={24} md={24} lg={6}>
              <CoinInfo />
            </Col>
            <Col xs={24} sm={24} md={24} lg={6}>
              <Claim />
            </Col>
            {/* <Column xs="12" sm="12" md="2">
                <MoreInfo />
              </Column> */}
          </AntdRow>
          <AntdRow
            className="m-t-10"
            gutter={[
              { xs: 0, sm: 10 },
              { xs: 0, sm: 0, md: 0, lg: 0 },
            ]}
          >
            {cardListData.map((item, index) => {
              return (
                <Col md={24} lg={8} className="m-b-20" key={index}>
                  <CustomCard switchCollateral={handleToggleCollateral} asset={item} />
                </Col>
              );
            })}
          </AntdRow>
          <AntdRow>
            <Market switchCollateral={handleToggleCollateral} allTableData={allTableData} />
          </AntdRow>
        </Row>
      </DashboardWrapper>
      <CollateralConfirmModal
        visible={isOpenCollateralConfirm}
        isCollateralEnalbe={isCollateralEnalbe}
        onCancel={() => setIsCollateralConfirm(false)}
      />
    </MainLayout>
  );
}

Dashboard.propTypes = {
  settings: PropTypes.object,
  setSetting: PropTypes.func.isRequired,
};

Dashboard.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { setSetting } = accountActionCreators;
  return bindActionCreators(
    {
      setSetting,
    },
    dispatch
  );
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(Dashboard);
