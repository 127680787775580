import styled from 'styled-components';

const P2PWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* height: fit-content; */
  @media only screen and (max-width: 768px) {
    padding: 15px 0;
    gap: 0;
  }
`;
const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  height: fit-content;
  font-weight: 400;
  font-size: 12px;
  color: ${(props) => props.theme['--color-btn-text-t']};
  @media only screen and (max-width: 768px) {
    gap: 15px;
    flex-wrap: wrap;
  }
  .title {
    font-weight: 400;
    font-size: 14px;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

const P2pHeader = styled.div`
  width: 100%;
  height: 109px;
  background: linear-gradient(91.48deg, #03a79f 0%, #007771 100%);
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 15px 30px;
  @media only screen and (max-width: 768px) {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 15px;
  }
  .header-img {
    height: 76px;
    width: auto;
    @media only screen and (max-width: 768px) {
      height: 40px;
    }
  }
  .info {
    margin-left: 50px;
    display: flex;
    align-items: center;
    flex: 1;
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
      justify-content: space-between;
    }
    &__item {
      flex: 1;
    }
    &__title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #ffffff;
    }
    &__desc {
      margin-top: 20px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      filter: blur(5px);
    }
    &__line {
      margin: 0 50px;
      width: 1px;
      height: 20px;
      background: #c6dbda;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }
  }
`;

const TableWrap = styled.div`
  @media only screen and (max-width: 768px) {
    margin-top: 40px;
  }
  .ant-table-content {
    min-height: 400px;
  }
  .apy {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
  }
  .table__num {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #c6dbda;
  }
  .table__sym {
    margin-left: 5px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #c6dbda;
  }
  .arrow {
    cursor: pointer;
  }
`;

export { P2PWrapper, HeaderWrapper, P2pHeader, TableWrap };
