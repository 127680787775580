import styled from 'styled-components';

const DrawerWrapper = styled.div`
  .ant-drawer-open {
    top: ${(props) => props.theme['--m-header-height']};
    height: calc(100% - ${(props) => props.theme['--m-header-height']});
  }

  .m-sidebar-nav {
    width: 95%;
    height: 56px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    color: ${(props) => props.theme['--color-text-top-light']};

    &:hover {
      opacity: 0.7;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      height: 54px;
      border-radius: unset;
    }
  }
  .active-mobile-option {
    .text {
      @media only screen and (max-width: 768px) {
        color: #008a83;
      }
    }
  }
  .m-nav-icon-wrap {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    margin-left: 30px;
    @media only screen and (max-width: 768px) {
      width: 16px;
      height: 18px;
      margin: 0 20px;
    }
    @media only screen and (max-width: 1280px) {
      width: 18px;
      height: 18px;
    }
  }
`;
const Label = styled.span`
  font-size: ${({ size }) => size || 16}px;
  color: ${(props) => props.theme['--color-text-top-light']};
  font-weight: 400;
  @media only screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    flex-grow: 1;
    height: 100%;
    border-bottom: 1px solid ${(props) => props.theme['--color-border']};
  }
`;

const InfoList = styled.div`
  margin: 40px 0 10px;
  padding: 0 20px;
  .info-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
  }
  .title {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme['--color-text-gray']};
  }
  .desc {
    font-size: 12px;
    font-weight: 400;
    color: ${(props) => props.theme['--color-text-main']};
  }
  .icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
`;

const Tools = styled.div`
  margin-bottom: 20px;
  padding: 0 20px;
  .tools-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 42px;
    border-bottom: 1px solid ${(props) => props.theme['--color-border']};
    .title {
      font-size: 12px;
      font-weight: 400;
      color: ${(props) => props.theme['--color-text-top-light']};
    }
    .desc {
      font-size: 12px;
      font-weight: 400;
      color: ${(props) => props.theme['--color-text-main']};
      cursor: pointer;
    }
    .icon {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
    .arrow {
      width: 5px;
      height: 19px;
      margin-left: 10px;
    }
  }
`;

export { DrawerWrapper, Label, InfoList, Tools };
