import styled from 'styled-components';

const P2PItemWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .avatar {
    width: 60px;
    height: 60px;
    background: #d9d9d9;
    border-radius: 50%;
    margin-right: 10px;
  }
  .info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &__title {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 28px;
      line-height: 36px;
      color: #c6dbda;
    }
    &__permissioned {
      margin-top: 16px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #c88739;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
    &__permissionless {
      margin-top: 16px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #008a83;
      display: flex;
      align-items: center;
      img {
        margin-right: 5px;
      }
    }
  }
`;
export { P2PItemWrapper };
