import fetch from 'isomorphic-fetch';
import { includes, mapValues, filter } from 'lodash';
import { getComptrollerContract, methods, getPriceOracleContract } from './contractService';
import {
  BLOCKS_PER_MINUTE,
  CONTRACT_COMPTROLLER_ADDRESS,
  SUBGRAPH_URL,
  CONTRACT_CTOKEN_ADDRESS,
  CONTRACT_PRICE_ORACLE_ADDRESS,
  VENFT_URL,
} from '../constants/address';

async function getSubgraphData(networkName) {
  const query = `
    {
      markets {
        borrowRate
        supplyRate
        cash
        collateralFactor
        exchangeRate
        interestRateModelAddress
        name
        reserves
        symbol
        id
        totalBorrows
        totalSupply
        underlyingAddress
        underlyingName
        underlyingPrice
        underlyingSymbol
        accrualBlockNumber
        blockTimestamp
        borrowIndex
        reserveFactor
        underlyingPriceUSD
        underlyingDecimals
      }
    }
  `;

  const response = await fetch(SUBGRAPH_URL[networkName], {
    method: 'POST',
    mode: 'cors',
    headers: {
      'accept': 'application/json',
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      query,
    }),
  });
  const { data } = await response.json();
  return data;
}

export const transTypeApi = [
  { label: 'Transfer', value: 'transferEvents' },
  { label: 'Mint', value: 'mintEvents' },
  { label: 'Redeem', value: 'redeemEvents' },
  { label: 'Liquidate', value: 'liquidationEvents' },
  { label: 'Borrow', value: 'borrowEvents' },
  { label: 'Repay', value: 'repayEvents' },
];
async function getSubgraphTransData(networkName, event, offset = 0, pageSize = 20) {
  if (process?.env.REACT_APP_AWS_ENV !== 'prod') {
    if (networkName === 'METER') {
      return [];
    }
  }
  const commonQuery = `{
    id
    amount
    to
    from
    blockNumber
    blockTime
    cTokenSymbol
  }`;
  const repayAndBorrowQuery = `{
    id
    amount
   	accountBorrows
    borrower
    blockNumber
    blockTime
    underlyingSymbol
  }`;
  const query = `
    {
      ${event}(first: ${pageSize}, skip: ${offset * pageSize},orderBy:blockTime,orderDirection:desc)
    ${['borrowEvents', 'repayEvents'].includes(event) ? repayAndBorrowQuery : commonQuery}}
  `;

  const response = await fetch(SUBGRAPH_URL[networkName], {
    variables: {
      orderBy: 'createdAtTimestamp',
      orderDirection: 'desc',
    },
    method: 'POST',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query,
    }),
  });
  const { data } = await response.json();
  return data[event];
}

async function processMarket(market, networkName) {
  const cheePrice = 200; // TODO: change to price from oracle
  const blocks_per_minute = BLOCKS_PER_MINUTE[networkName];
  const oracleContract = await getPriceOracleContract(CONTRACT_PRICE_ORACLE_ADDRESS[networkName]);
  if (process?.env.REACT_APP_AWS_ENV === 'prod') {
    const mantissaDecimalFactor = 18 - market.underlyingDecimals + 18;
    const underlyingPriceUSD =
      (await methods.call(oracleContract.methods.getUnderlyingPrice, [market.id])) / 10 ** mantissaDecimalFactor;
    Object.assign(market, { underlyingPriceUSD });
  }

  let result = {
    address: market.id,
    symbol: market.symbol,
    name: market.name,
    underlyingAddress: market.underlyingAddress,
    underlyingName: market.underlyingName,
    underlyingSymbol: market.underlyingSymbol,
    underlyingDecimal: market.underlyingDecimals,
    cheeSpeeds: null,
    borrowerDailyChee: null,
    supplierDailyChee: null,
    cheeBorrowIndex: null,
    cheeSupplyIndex: null,
    borrowRatePerBlock: market.borrowRate * 1e18,
    supplyRatePerBlock: market.supplyRate * 1e18,
    exchangeRate: market.exchangeRate,
    underlyingPrice: market.underlyingPriceUSD * 1e18,
    totalBorrows: market.totalBorrows * 1e18,
    totalBorrows2: market.totalBorrows,
    totalBorrowsUsd: market.underlyingPriceUSD * market.totalBorrows,
    totalSupply: market.totalSupply * 1e18 * market.exchangeRate,
    totalSupply2: market.totalSupply * market.exchangeRate,
    totalSupplyUsd: market.underlyingPriceUSD * market.totalSupply * market.exchangeRate,
    cash: market.cash * 1e18,
    totalReserves: market.reserves * 1e18,
    reserveFactor: market.reserveFactor,
    collateralFactor: market.collateralFactor * 1e18,
    borrowApy: -market.borrowRate * blocks_per_minute * 60 * 24 * 365 * 100,
    supplyApy: market.supplyRate * blocks_per_minute * 60 * 24 * 365 * 100,
    borrowCheeApy: null,
    supplyCheeApy: null,
    liquidity: null,
    tokenPrice: market.underlyingPriceUSD,
    totalDistributed: null, // need to calculate separately, not from subgraph
    borrowCaps: null,
    borrowAble: true,
    lastCalculatedBlockNumber: market.accrualBlockNumber,
    borrowerCount: null,
    supplierCount: null,
  };
  const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[networkName]);
  result.cheeSpeeds = parseFloat(await methods.call(appContract.methods.cheeSpeeds, [result.address]));
  result.borrowerDailyChee = result.cheeSpeeds * blocks_per_minute * 60 * 24;
  result.supplierDailyChee = result.cheeSpeeds * blocks_per_minute * 60 * 24;
  result.cheeBorrowIndex = (await methods.call(appContract.methods.cheeBorrowState, [result.address])).index;
  result.cheeSupplyIndex = (await methods.call(appContract.methods.cheeSupplyState, [result.address])).index;
  result.borrowCheeApy =
    ((((result.cheeSpeeds * cheePrice) / 1e18) * blocks_per_minute * 60 * 24 * 365) / result.totalBorrowsUsd) * 100;
  result.supplyCheeApy =
    ((((result.cheeSpeeds * cheePrice) / 1e18) * blocks_per_minute * 60 * 24 * 365) / result.totalSupplyUsd) * 100;
  result.liquidity = result.totalSupplyUsd - result.totalBorrowsUsd;
  // result.totalDistributed = await methods.call(appContract.methods.cheeAccrued, [result.address]); // TODO: verify
  result.borrowCaps = await methods.call(appContract.methods.borrowCaps, [result.address]);
  if (result.borrowCaps === '1') {
    result.borrowAble = false;
  }

  // console.log(result);
  return result;
}

export async function subgraphMain({ networkName }) {
  let result = {
    status: false,
  };
  if (!networkName || !SUBGRAPH_URL[networkName]) return result;
  try {
    let { markets } = await getSubgraphData(networkName);
    const appContract = getComptrollerContract(CONTRACT_COMPTROLLER_ADDRESS[networkName]);
    let data = {
      markets: await Promise.all(
        markets
          .filter((market) => includes(mapValues(CONTRACT_CTOKEN_ADDRESS[networkName], 'address'), market.id))
          .map((market) => processMarket(market, networkName))
      ),
    };
    data.cheeRate = await methods.call(appContract.methods.cheeRate, []);
    data.dailyChee = (data.cheeRate * 12 * 60 * 24).toLocaleString('fullwide', {
      useGrouping: false,
    });
    result.data = data;
    result.status = true;
  } catch (error) {
    result.status = false;
    // eslint-disable-next-line no-console
    console.log(`[subgraphMain] Error: ${networkName} ${error}`);
  }
  return result;
}

export async function getMarketsData({ networkName }) {
  let result = {
    status: false,
  };
  if (!networkName) return result;
  try {
    let { markets } = await getSubgraphData(networkName);
    let data = {
      markets: await Promise.all(
        markets
          .filter((market) => includes(mapValues(CONTRACT_CTOKEN_ADDRESS[networkName], 'address'), market.id))
          .map((market) => {
            return {
              ...market,
              totalSupplyUsd: market.underlyingPriceUSD * market.totalSupply * market.exchangeRate,
            };
          })
      ),
    };
    result.data = data;
    result.status = true;
  } catch (error) {
    result.status = false;
    // eslint-disable-next-line no-console
    console.log(`[getMarketsData] Error: ${networkName} ${error}`);
  }
  return result;
}

export async function subgraphTrans({ networkName, event, offset = 0, pageSize = 20 }) {
  let result = {
    status: false,
  };
  if (!networkName || !SUBGRAPH_URL[networkName]) return result;
  try {
    result.data = await getSubgraphTransData(networkName, event, offset, pageSize).then((res) => {
      return res.map((item) => ({
        ...item,
        amount: +item.amount,
      }));
    });
    result.status = true;
  } catch (error) {
    result.status = false;
    // eslint-disable-next-line no-console
    console.log(`[subgraphTrans] Error: ${networkName} ${error}`);
  }
  return {
    status: 200,
    data: result,
  };
}

export async function getSubgraphVeNFTData(networkName) {
  let result = {
    status: false,
  };
  try {
    const query = `
    {
      totalValueLockeds{
        id
        tvl
      }
    }
  `;
    const response = await fetch(VENFT_URL[networkName], {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query,
      }),
    });
    const { data } = await response.json();
    let res = data.totalValueLockeds[0].tvl;
    result.data = res;
    result.status = true;
  } catch (error) {
    result.status = false;
    // eslint-disable-next-line no-console
    console.log(`[getSubgraphVeNFTData] Error: ${networkName} ${error}`);
  }
  return result;
}
