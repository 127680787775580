import celoImg from 'assets/img/networks/celo.png';
import bscImg from 'assets/img/networks/bsc.png';
import meterImg from 'assets/img/networks/meter.png';
import polygonImg from 'assets/img/networks/polygon.png';
import ethImg from 'assets/img/networks/eth.png';

const networkInfo = {
  'bsc-test': {
    chainId: '0x61', // 97
    chainName: 'BSC Testnet', // 添加到钱包后显示的网络名称
    rpcUrls: [
      'https://rpc.ankr.com/bsc_testnet_chapel', // rpc地址
    ],
    blockExplorerUrls: [
      'https://testnet.bscscan.com', // 网络对应的区块浏览器
    ],
    shortName: 'BSC',
  },
  'celo-test': {
    chainId: '0xaef3', // 44787
    chainName: 'Celo Alfajores', // 添加到钱包后显示的网络名称
    rpcUrls: [
      'https://alfajores-forno.celo-testnet.org', // rpc地址
    ],
    blockExplorerUrls: [
      'https://alfajores-blockscout.celo-testnet.org', // 网络对应的区块浏览器
    ],
    shortName: 'CELO',
  },
  // 主网 测试网的信息暂时未找到
  // 'meter-test': {
  //   chainId: '0x53', // 83
  //   chainName: 'Meter Testnet', // 添加到钱包后显示的网络名称
  //   rpcUrls: [
  //     'https://rpctest.meter.io/', // rpc地址
  //   ],
  //   blockExplorerUrls: [
  //     'https://scan-warringstakes.meter.io/', // 网络对应的区块浏览器
  //   ],
  //   shortName: 'METER',
  // },
  // 'polygon-test': {
  //   chainId: '0x13881', // 80001
  //   chainName: 'Polygon Testnet', // 添加到钱包后显示的网络名称
  //   rpcUrls: [
  //     'https://rpc-mumbai.maticvigil.com', // rpc地址
  //   ],
  //   blockExplorerUrls: [
  //     'https://mumbai.polygonscan.com/', // 网络对应的区块浏览器
  //   ],
  //   shortName: 'POLYGON',
  // },
  'eth-test': {
    chainId: '0x4', // 4
    chainName: 'Ethereum Rinkeby Testnet', // 添加到钱包后显示的网络名称
    rpcUrls: [
      'https://rpc.ankr.com/eth_rinkeby', // rpc地址
    ],
    blockExplorerUrls: [
      'https://rinkey.etherscan.io', // 网络对应的区块浏览器
    ],
    shortName: 'ETH',
  },
};

const networkList = [
  {
    name: 'Celo Alfajores Testnet',
    value: 'celo-test',
    icon: celoImg,
    supportBridge: false,
    supportTrans: true,
    supportNFT: true,
    supportNFTBtnDisabled: false,
    shortName: 'CELO',
    supportNFTSubgraph: false,
    supportLiquidation: true,
  },
  {
    name: 'BNB Chain Testnet',
    value: 'bsc-test',
    icon: bscImg,
    supportBridge: true,
    supportTrans: true,
    supportNFT: false,
    supportNFTBtnDisabled: true,
    shortName: 'BSC',
    supportNFTSubgraph: false,
    supportLiquidation: true,
  },
  // {
  //   name: 'Meter Testnet',
  //   value: 'meter-test',
  //   icon: meterImg,
  //   supportBridge: true,
  //   supportTrans: false,
  //   supportNFT: false,
  //   supportNFTBtnDisabled: true,
  //   shortName: 'METER',
  //   supportNFTSubgraph: false,
  // },
  // {
  //   name: 'Polygon Mumbai Testnet',
  //   value: 'polygon-test',
  //   icon: polygonImg,
  //   supportBridge: false,
  //   supportTrans: true,
  //   supportNFT: true,
  //   supportNFTBtnDisabled: true,
  //   shortName: 'POLYGON',
  //   supportNFTSubgraph: false,
  // },
  // {
  //   name: 'Ethereum Rinkeby Testnet',
  //   value: 'eth-test',
  //   icon: ethImg,
  //   supportBridge: false,
  //   supportTrans: false,
  //   supportNFT: true,
  //   supportNFTBtnDisabled: false,
  //   shortName: 'ETH',
  //   supportNFTSubgraph: true,
  //   supportLiquidation: false,
  // },
];
export { networkInfo, networkList };
