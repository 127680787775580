import * as NETWORK_TEST from './network-test';
import * as NETWORK_MAIN from './network-main';

let temp = {};
if (process?.env.REACT_APP_AWS_ENV === 'prod') {
  temp = NETWORK_MAIN;
} else {
  temp = NETWORK_TEST;
}

export const { networkInfo, networkList } = temp; // networkInfo should not be changed, because it would be post as a params to remote
