import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { Row, Col, Pagination, Select, Icon } from 'antd';
import { bindActionCreators } from 'redux';
import { connectAccount, accountActionCreators } from 'modules';
import MainLayout from 'components/layout/wrap';
import { SpinnerWrapper } from 'components/page/dashBoard/style';
import LoadingSpinner from 'components/common/loadingSpinner';
import { Label, PageTitle } from 'components/common/style';
import { transTypeApi } from 'utilities/subgraph';
import { FormattedMessage } from 'react-intl';
import { isString, findKey } from 'lodash';

import { promisify } from 'utilities/promisify';
// import coinImg from 'assets/img/coins/cai.png';
import moment from 'moment';
import arrowRightImg from 'assets/img/arrow-right.png';
import cheeImg from 'assets/img/coins/chee.png';
import { EXPLORER_LINK, CONTRACT_TOKEN_ADDRESS, CONTRACT_CTOKEN_ADDRESS } from 'constants/address';
import { formatNumber, getBigNumber } from 'utilities/number';
import { TransactionWrapper, TableWrapper, HeaderWrapper, FooterWrapper, ScrollContent } from './style';

const { Option } = Select;

function Transaction({ getTransactionHistory, settings }) {
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);
  const [pageSize, setPageSize] = useState(20);
  const [total, setTotal] = useState(999);
  const [event, setEvent] = useState('transferEvents');
  const [canShowPage, setCanShowPage] = useState(false);

  const loadTransactionHistory = useCallback(async () => {
    setCanShowPage(false);
    await promisify(getTransactionHistory, { networkName: settings.networkName, event, offset })
      .then((res) => {
        if (!res || !res.data) return;
        setData(res.data);
        if (res.data.length < pageSize) {
          const newTotal = offset * pageSize + res.data.length;
          setTotal(newTotal);
        }
        setCanShowPage(true);
      })
      .catch((e) => {
        setCanShowPage(true);
      });
  }, [offset, event, settings.networkName]);

  useEffect(() => {
    if (!settings.networkName) return;
    loadTransactionHistory();
  }, [loadTransactionHistory, settings.networkName]);

  const handleChangePage = (page) => {
    setOffset(page - 1);
  };
  const onNext = () => {
    handleChangePage(offset + 2);
  };
  const onPrev = () => {
    handleChangePage(offset);
  };
  const setEventState = (val) => {
    setOffset(0);
    setEvent(val);
    setTotal(999);
  };

  return (
    <MainLayout title="Transaction History" fullHeight>
      <PageTitle>
        <FormattedMessage id="Transactions" />
      </PageTitle>
      <TransactionWrapper>
        <HeaderWrapper>
          <div className="title">Transaction Type</div>
          <div
            className="transaction-type"
            style={{
              backgroundColor: event === 'transferEvents' ? '#008A83' : '',
              color: event === 'transferEvents' ? '#FFFFFF' : '',
            }}
            onClick={() => setEventState('transferEvents')}
          >
            Transfer
          </div>
          <div
            className="transaction-type"
            style={{
              backgroundColor: event === 'mintEvents' ? '#008A83' : '',
              color: event === 'mintEvents' ? '#FFFFFF' : '',
            }}
            onClick={() => setEventState('mintEvents')}
          >
            Mint
          </div>
          <div
            className="transaction-type"
            style={{
              backgroundColor: event === 'redeemEvents' ? '#008A83' : '',
              color: event === 'redeemEvents' ? '#FFFFFF' : '',
            }}
            onClick={() => setEventState('redeemEvents')}
          >
            Redeem
          </div>
          <div
            className="transaction-type"
            style={{
              backgroundColor: event === 'liquidationEvents' ? '#008A83' : '',
              color: event === 'liquidationEvents' ? '#FFFFFF' : '',
            }}
            onClick={() => setEventState('liquidationEvents')}
          >
            Liquidation
          </div>
          <div
            className="transaction-type"
            style={{
              backgroundColor: event === 'borrowEvents' ? '#008A83' : '',
              color: event === 'borrowEvents' ? '#FFFFFF' : '',
            }}
            onClick={() => setEventState('borrowEvents')}
          >
            Borrow
          </div>
          <div
            className="transaction-type"
            style={{
              backgroundColor: event === 'repayEvents' ? '#008A83' : '',
              color: event === 'repayEvents' ? '#FFFFFF' : '',
            }}
            onClick={() => setEventState('repayEvents')}
          >
            Repay
          </div>
        </HeaderWrapper>
        <ScrollContent>
          <TableWrapper>
            <div className="table_header">
              <Row>
                <Col lg={{ span: 2 }} className="type">
                  Coin
                </Col>
                <Col lg={{ span: 2 }} className="type">
                  Type
                </Col>
                <Col lg={{ span: 4 }} className="hash">
                  ID
                </Col>
                <Col lg={{ span: 3 }} className="block">
                  Block
                </Col>
                <Col lg={{ span: 4 }} className="from">
                  From
                </Col>
                <Col lg={{ span: 3 }} className="to">
                  To
                </Col>
                <Col lg={{ span: 4 }} className="amount">
                  Amount
                </Col>
                <Col lg={{ span: 2 }} className="date">
                  Created At
                </Col>
              </Row>
            </div>
            <div className="table_content">
              {!canShowPage ? (
                <SpinnerWrapper>
                  <LoadingSpinner />
                </SpinnerWrapper>
              ) : (
                data &&
                data.length > 0 &&
                data.map((item, index) => (
                  <Row className="table_item" key={index}>
                    <Col lg={{ span: 2 }} className="coin-type">
                      <img
                        src={
                          CONTRACT_TOKEN_ADDRESS[settings.networkName][
                            isString(item.underlyingSymbol)
                              ? item.underlyingSymbol.toLowerCase()
                              : findKey(CONTRACT_CTOKEN_ADDRESS[settings.networkName], (o) => {
                                  return o.symbol.toLowerCase() === item.cTokenSymbol.toLowerCase();
                                })
                          ]?.asset
                        }
                        alt="asset"
                      />
                      <p className="item-title">
                        {item.underlyingSymbol ? item.underlyingSymbol : item.cTokenSymbol.substring(1)}
                      </p>
                    </Col>
                    <Col lg={{ span: 2 }} className="type">
                      <p className="item-title">{transTypeApi.find((type) => type.value === event)?.label}</p>
                    </Col>
                    <Col lg={{ span: 4 }} className="hash">
                      <p className="item-title">{`${item.id.slice(0, 6)}...${item.id.slice(-6)}`}</p>
                    </Col>
                    <Col lg={{ span: 3 }} className="block">
                      <p className="item-title">{item.blockNumber}</p>
                    </Col>
                    <Col lg={{ span: 4 }} className="from">
                      <p
                        className="item-title"
                        onClick={() => {
                          window.open(`${EXPLORER_LINK[settings.networkName]}/address/${item.from}`, '_blank');
                        }}
                      >
                        <u> {item.from && `${item.from.slice(0, 6)}...${item.from.slice(-6)}`}</u>
                      </p>
                    </Col>
                    <Col lg={{ span: 3 }} className="to">
                      <p
                        className="item-title"
                        onClick={() => {
                          window.open(`${EXPLORER_LINK[settings.networkName]}/address/${item.to}`, '_blank');
                        }}
                      >
                        <u>{item.to && `${item.to.slice(0, 6)}...${item.to.slice(-6)}`}</u>
                      </p>
                    </Col>
                    <Col lg={{ span: 4 }} className="amount">
                      <p className="item-title">{formatNumber(item.amount, true)}</p>
                    </Col>
                    <Col lg={{ span: 2 }} className="date">
                      <p className="item-title">{moment(item.blockTime * 1000).fromNow()}</p>
                    </Col>
                  </Row>
                ))
              )}
            </div>
          </TableWrapper>
        </ScrollContent>
        <FooterWrapper>
          <Icon className="icons" type="left" onClick={onPrev} />
          <div className="footer">
            <Pagination size="small" current={offset + 1} total={total} onChange={handleChangePage} />
          </div>
          <Icon type="right" className="icons" onClick={onNext} />
        </FooterWrapper>
      </TransactionWrapper>
    </MainLayout>
  );
}

Transaction.propTypes = {
  settings: PropTypes.object,
};

Transaction.defaultProps = {
  settings: {},
};

const mapStateToProps = ({ account }) => ({
  settings: account.setting,
});

const mapDispatchToProps = (dispatch) => {
  const { getTransactionHistory } = accountActionCreators;

  return bindActionCreators(
    {
      getTransactionHistory,
    },
    dispatch
  );
};

export default compose(withRouter, connectAccount(mapStateToProps, mapDispatchToProps))(Transaction);
