import styled from 'styled-components';

const MarketTableWrapper = styled.div`
  border-radius: 6px;
  background-color: ${(props) => props.theme['--color-card-bg-new']};
  margin-right: 10px;
  @media only screen and (max-width: 768px) {
    margin: 0;
    padding: 0 10px;
  }
  .all-title {
    margin-top: 5px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    line-height: 20px;
    color: #008a83;
    padding-top: 15px;
    padding-left: 10px;
    border-bottom: 1px solid ${(props) => props.theme['--color-market-border-new']};
    padding-bottom: 20px;
    @media only screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 14px;
      font-weight: 600;
      padding: 0;
      margin: 20px 0 0 0;
      border-left: 3px solid #008a83;
      border-bottom: 0;
      padding-left: 5px;
    }
  }
  .all-title-line {
    border-bottom: 1px solid ${(props) => props.theme['--color-market-border-new']};
    @media only screen and (max-width: 768px) {
      border: none;
    }
  }

  img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    @media only screen and (max-width: 768px) {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

  .apy-content {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: #229558;
    .anticon-arrow-up {
      @media only screen and (max-width: 768px) {
        font-size: 12px;
      }
    }
    .apy-green-label {
      width: 80px;
      font-size: 14px;
      @media only screen and (max-width: 768px) {
        width: auto;
        font-size: 12px;
      }

      @media only screen and (max-width: 1130px) {
        font-size: 14px;
      }

      font-weight: 600;
    }
    .apy-red-label {
      width: 80px;
      font-size: 14px;
      @media only screen and (max-width: 768px) {
        width: auto;
        font-size: 12px;
      }

      @media only screen and (max-width: 1130px) {
        font-size: 14px;
      }

      font-weight: 600;
      color: #f9053e;
    }
  }
  .ant-table-body {
    background: ${(props) => props.theme['--color-bg-black']};
  }
  .ant-table-fixed-header {
    .ant-table-scroll {
      .ant-table-header {
        background-color: ${(props) => props.theme['--color-card-bg-new']};
        /* width */
        &::-webkit-scrollbar {
          display: none;
        }
      }
    }
  }

  .ant-table-thead {
    tr {
      th {
        color: ${(props) => props.theme['--color-text-secondary']};
        font-size: 16px;
        @media only screen and (max-width: 1130px) {
          font-size: 14px;
        }
        @media only screen and (max-width: 768px) {
          font-size: 10px;
          /* line-height: 13px; */
          border-bottom: 1px solid ${(props) => props.theme['--color-market-border-new']};
        }

        font-weight: normal;
        background-color: ${(props) => props.theme['--color-card-bg-new']};
        border-bottom: 0px;
        // border-bottom: 1px solid ${(props) => props.theme['--color-table-line']};
        -webkit-transition: background 0.3s ease;
        transition: background 0.3s ease;
        text-align: right;
        // width: 28%;

        &:first-child {
          width: 16%;
        }

        &:nth-child(1) {
          text-align: left;
        }

        @media only screen and (max-width: 768px) {
          padding: 5px;
          /* &:nth-child(2) {
            display: none;
          } */
        }
        @media only screen and (max-width: 1130px) {
          padding: 5px;
          /* &:nth-child(2) {
            display: none;
          } */
        }
      }
    }
  }

  .ant-table-tbody {
    tr {
      td {
        border-bottom: 0px;
        background-color: ${(props) => props.theme['--color-card-bg-new']};
        text-align: right;
        width: 28%;
        // border-bottom: 1px solid ${(props) => props.theme['--color-table-line']};

        &:first-child {
          width: 16%;
        }

        &:nth-child(1) {
          text-align: left;

          span:nth-child(2) {
            display: none;
          }
        }

        @media only screen and (max-width: 899px) {
          &:last-child {
            word-break: break-word;
          }
        }

        @media only screen and (max-width: 768px) {
          padding: 5px;
          height: 50px;
          min-height: 50px;
          &:nth-child(1) {
            text-align: left;
            span:nth-child(2) {
              display: block;
              color: #229558;
            }
          }
          &:nth-child(2) {
            /* display: none; */
          }
        }

        @media only screen and (max-width: 1130px) {
          padding: 5px;
          &:nth-child(1) {
            text-align: left;

            span:nth-child(2) {
              display: block;
              color: #229558;
            }
          }
          &:nth-child(2) {
            /* display: none; */
          }
        }
      }
    }
    tr:hover {
      cursor: pointer;
    }
    tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
      background: ${(props) => props.theme['--color-connect-btn-hover']};
      box-shadow: inset 1px 1px 0 ${(props) => props.theme['--color-connect-btn-hover-border']},
        inset -1px -1px 0 ${(props) => props.theme['--color-connect-btn-hover-border']};
    }
    tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
      background: unset;
    }
  }
  .ant-table-wrapper {
    border-radius: 6px;
  }
  .link {
    white-space: nowrap;
  }
`;

export { MarketTableWrapper };
