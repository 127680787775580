import React from 'react';
import PropTypes from 'prop-types';
import { Modal, Icon } from 'antd';
import { NOT_INSTALLED } from 'constants/hint';
import { onboard } from 'components/App';
import cloneDeep from 'lodash/cloneDeep';

import { ModalContent } from './style';

function NetworkModal({
  visible,
  web3,
  error,
  awaiting,
  walletType,
  onCancel,
  title,
  customNetworkList,
  networkList,
  networkInfo,
  tips,
  notips,
}) {
  const handleAddNetWork = async (info) => {
    onCancel();
    const params = cloneDeep(info);
    delete params.shortName;
    if (params.chainId === '0x1' || params.chainId === '0x4') {
      delete params.chainName;
      delete params.rpcUrls;
      delete params.blockExplorerUrls;
    }

    // type SetChainOptions = {
    //   chainId: string // hex encoded string
    //   chainNamespace?: 'evm' // defaults to 'evm' (currently the only valid value, but will add more in future updates)
    //   wallet?: string // the wallet.label of the wallet to set chain
    // }
    const success = await onboard.setChain({ chainId: params.chainId });
  };

  const MetaMaskStatus = () => {
    if (error && error.message === NOT_INSTALLED) {
      return (
        <p className="center">
          We could not locate a supported web3 browser extension. We recommend using MetaMask.
          <a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
            Download MetaMask here.
          </a>
        </p>
      );
    }
    if (error) {
      return <span>{error.message}</span>;
    }
    if (!web3 && awaiting) {
      return <span>MetaMask loading...</span>;
    }
    if (!web3) {
      return <span>Please open and allow MetaMask</span>;
    }
    return null;
  };

  return (
    <Modal
      className="connect-modal"
      width={636}
      visible={visible}
      onCancel={onCancel}
      footer={null}
      closable={false}
      maskClosable
      centered
    >
      <ModalContent className="flex flex-column align-center just-center">
        <Icon
          className="close-btn pointer"
          style={{ fontSize: 18, color: '#FFFFFF' }}
          type="close"
          onClick={onCancel}
        />
        <div className="flex flex-column align-center just-center header-content">
          <p className="title">{title}</p>
        </div>
        {/* <div className={notips ? 'tips-display-none' : 'tips'}>{tips}</div> */}
        <div className="connect-wallet-content flex flex-wrap">
          {customNetworkList.map((item, index) => {
            const privateNetwork = networkList.find((network) => network.value === item.value);
            return (
              <div
                key={index}
                className={`flex align-center just-between metamask-connect-btn ${
                  item.isDisabled ? 'btn-disabled' : 'metamask-connect-btn-active'
                }`}
                onClick={() => {
                  if (item.isDisabled) return;
                  handleAddNetWork(networkInfo[privateNetwork?.value]);
                }}
              >
                <div className="flex align-center">
                  <img src={privateNetwork?.icon} alt="metamask" />
                  <span>{privateNetwork?.name}</span>
                </div>
              </div>
            );
          })}
        </div>
      </ModalContent>
    </Modal>
  );
}

NetworkModal.propTypes = {
  visible: PropTypes.bool,
  web3: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  awaiting: PropTypes.bool,
  notips: PropTypes.bool,
  walletType: PropTypes.string,
  customNetworkList: PropTypes.array,
  networkList: PropTypes.array,
  networkInfo: PropTypes.object,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  tips: PropTypes.string,
};

NetworkModal.defaultProps = {
  visible: false,
  web3: {},
  error: '',
  awaiting: false,
  notips: false,
  walletType: '',
  customNetworkList: PropTypes.array, // [{value: 'bsc-test', isDisabled: true}]
  networkList: PropTypes.array,
  networkInfo: PropTypes.object,
  onCancel: () => {},
  title: PropTypes.string,
  tips: '',
};

export default NetworkModal;
