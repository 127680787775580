import styled from 'styled-components';

const BorrowWrap = styled.div`
  display: flex;
  flex-direction: column;
  color: #c7e9d6;
  width: 100%;
  height: 44px;
  margin-top: 12px;
  @media only screen and (max-width: 768px) {
    padding: 0 20px;
    justify-content: space-between;
  }
  .second {
    display: flex;
  }
  .left-borrow {
    width: 52px;
    margin-right: 11px;
  }
  .right-borrow {
    margin-left: auto;
    margin-right: 10px;
    width: 100%;
  }
  .top-borrow {
    display: flex;
  }
  .icon-wrap {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    background-color: ${(props) => props.theme['--color-bg-icon']};
    margin-right: 11px;
    position: relative;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 24px;
      height: 24px;
    }
  }
  .usd-price {
    font-size: 24px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    color: ${(props) => props.theme['--color-text-main']};
  }
  .credit-text {
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-family: 'Inter', sans-serif;
    font-weight: normal;
    opacity: 0.7;
    line-height: 17px;
    color: ${(props) => props.theme['--color-text-main']};
    margin-right: 10px;
    width: 120px;
  }
  .available {
    justify-content: center;
    margin-right: 10px;

    @media only screen and (max-width: 1024px) {
      font-size: 13px;
    }
    @media only screen and (max-width: 768px) {
      margin-right: 3px;
      font-size: 12px;
    }
  }
  .left-wrap {
    width: 66%;
  }
  .process-wrap {
    width: 100%;
    .ant-progress-inner {
      background: #272929;
      border-radius: 2px !important;
    }
    .ant-progress-bg {
      border-radius: 2px !important;
    }
    .ant-progress-outer {
      padding-right: 0;
    }
    @media only screen and (max-width: 768px) {
      flex-grow: 1;
      font-size: 12px;
      margin-top: -3px;
      padding-right: 3px;
      .ant-progress-outer {
        padding-right: 0;
        margin-top: -3px;
      }
      .ant-progress-bg {
        height: 12px !important;
      }
    }
  }
  .percent-font {
    // float: right;
    font-size: 24px;
    @media only screen and (max-width: 768px) {
      font-size: 12px;
    }
    font-family: 'Inter', sans-serif;
    font-weight: bold;
    color: #ffffff;
    line-height: 31px;
    margin-left: auto;
  }
  .ant-progress-text {
    position: absolute;
    left: ${(props) => `${props.left < 0 ? props.left - 15 : props.left === 100 ? props.left - 25 : props.left - 8}%`};
    font-size: 10px;
    top: 7px;
    color: #ffffff;
    .anticon {
      display: none;
    }
    @media only screen and (max-width: 1144px) {
      left: 82%;
    }

    @media only screen and (max-width: 992px) {
      left: 80%;
    }
  }
`;

export { BorrowWrap };
